import styled from 'styled-components';

export const PointPurchaseContainer = styled.div<any>`
  .package-purchase-container {
    .list-point-package {
      background: #f3f4f6;
      border: 1px solid #9ca3af;
      border-radius: 5px;
      overflow-y: auto;
      height: 190px;
      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
        width: 100%;
        height: 295px;
      }
      ul {
        margin: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;
        padding: 20px 10px 15px 10px;
        @media only screen and (max-width: ${props =>
            props.breakpoints.mobile}) {
          grid-template-columns: repeat(3, 1fr);
          padding-top: 10px;
        }
        li.gift-item {
          display: flex;
          justify-content: flex-start;
          flex: auto;
          cursor: pointer;
          .gift-item-box {
            width: 100%;
          }
          .gift-image-box {
            border: 1px solid #d1d5db;
            border-radius: 10px;
            padding: 10px;
            @media only screen and (max-width: ${props =>
                props.breakpoints.mobile}) {
              height: 102px;
            }

            img {
              width: 100%;
              height: 60px;
              object-fit: contain;
              margin-bottom: 11px;
              @media only screen and (max-width: ${props =>
                  props.breakpoints.mobile}) {
                height: 44px;
              }
            }
          }

          .gift-selected {
            background: white;
            border: 1px solid #eb7d17;
          }

          .gift-name {
            text-align: center;
            word-break: break-all;
            font-weight: bold;
            color: #1f2937;
            font-size: 12px;
            margin-top: 5px;
          }

          .gift-point-wrap {
            position: relative;
          }

          .gift-point {
            text-align: center;
            font-weight: bold;
            color: #eb7d17;
            font-size: 16px;
          }

          .gift-point-text {
            position: absolute;
            top: 16px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            color: #d1d5db;
            font-size: 12px;
          }
        }
      }
    }

    .point-package-price {
      margin: 20px 0px 10px 0px;

      .point-quantity,
      .point-price {
        display: grid;
        grid-template-columns: 80px 177px;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;
      }

      .point-input {
        padding: 10px 20px;
        border-radius: 8px;
        border: 1px solid #d1d5db;
        color: #9ca3af;
        outline: none;
      }

      .point-label {
        padding-top: 10px;
        color: #9ca3af;
        text-align: end;
        margin-right: 5px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .point-button {
      width: 100%;
      clear: both;
      text-align: center;
      border-top: 1px solid #d1d5db;
      /* margin-top: 20px; */
      padding-top: 10px;
      button {
        width: calc(50% - 10px);
        height: 52px;
        border-radius: 7px;
        border: none;
        font-weight: bold;
        cursor: pointer;
        color: #9ca3af;

        @media only screen and (max-width: ${props =>
            props.breakpoints.mobile}) {
          width: calc(50% - 5px);
        }
      }

      .purchase-button {
        background: #eb7d17;
        margin-left: 20px;
        color: #ffffff;

        @media only screen and (max-width: ${props =>
            props.breakpoints.mobile}) {
          margin-left: 10px;
        }

        &:disabled {
          background: rgba(235, 125, 23, 0.5);
          cursor: not-allowed;
        }
      }
    }
  }
`;
