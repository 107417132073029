import React, { memo, useState, useEffect, useRef } from 'react';
import { Image } from 'antd';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';

import configObsLive from '../hooks/configObsLive';
import useInit from '../hooks/useInit';
import { MetaLoading } from 'app/components/MetaLoading';
import ChatRTC from 'app/components/ChatRTC';
import ChatGroup from 'app/pages/StreamPage/components/ChatGroup';
import BigDonateNotification from 'app/pages/StreamPage/components/LiveScreen/components/BigDonateNotification';
import { useLiveScreen } from 'app/pages/StreamPage/components/LiveScreen/hooks';
import { StartIcon, StopIcon } from 'styles/image';
import { selectAuth } from '../../AuthPage/slice/selectors';
import { breakpoints } from 'utils/media-breakpoint';
import { HeartIcon, ViewIcon } from 'styles/image';
import { TypeObs } from 'utils/constants';
import { PATH } from 'app/constants/path';
import {
  Wrapper,
  LivestreamContainer,
  ButtonStart,
  SelectCustom,
} from './styles';

function formatTime(time: number) {
  const hours = Math.floor(time / 3600)
    .toString()
    .padStart(2, '0');

  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, '0');

  const seconds = (time % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

export const ObsLiveStreamPage = memo(() => {
  const [isCopyWhipServer, setIsCopyWhipServer] = useState(false);
  const [isCopyWhepUserSig, setIsCopyWhepUserSig] = useState(false);
  const [isReconnectStreaming, setIsReconnectStreaming] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [chatRTC, setChatRTC] = useState<any>(null);
  const [messageLists, setMessageLists] = useState<any>([]);
  const [inputMessage, setInputMessage] = useState<any>('');
  const [receiveStatus, setReceiveStatus] = useState(false);
  const [typeObs, setTypeObs] = useState('');
  const [time, setTime] = useState(0);

  const sendButton = useRef<any>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeObsParam = searchParams.get('type');
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectAuth);

  const { startPlay, handleLeaveObs } = configObsLive(setIsPlay);

  const {
    isLoading,
    whepServer,
    whipServer,
    whepUserSig,
    roomID,
    totalOfLike,
    totalOfViewer,
    isErrorRoom,
    isStartRoomSuccess,
    startRoom,
    stopRoom,
  } = useInit(typeObs);

  const {
    donateMessage,
    imageDonateNotificationRef,
    confettiEffectRef,
    imagePosition,
    audioRef,
    effectSoundUrl,
  } = useLiveScreen({
    localStreamConfig: null,
    roomID,
  });

  const handleCopyWhipServer = () => {
    setIsCopyWhipServer(true);
    navigator.clipboard.writeText(whipServer);
    setTimeout(() => {
      setIsCopyWhipServer(false);
    }, 2000);
  };

  const handleCopyWhepUserSig = () => {
    setIsCopyWhepUserSig(true);
    navigator.clipboard.writeText(whepUserSig);
    setTimeout(() => {
      setIsCopyWhepUserSig(false);
    }, 2000);
  };

  const handleReconnectStreaming = () => {
    startPlay(whepServer, whepUserSig);
    setIsReconnectStreaming(true);
    setTimeout(() => {
      setIsReconnectStreaming(false);
    }, 4000);
  };

  const handleChangeType = e => {
    navigate(`${PATH.OBS_STREAM}?type=${e}`);
    if (roomID) {
      handleLeaveObs();
      stopRoom(roomID);
    }
  };

  useEffect(() => {
    if (!isPlay) {
      setTime(0);
    } else {
      let intervalId: string | number | NodeJS.Timeout | undefined;

      intervalId = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPlay]);

  useEffect(() => {
    switch (typeObsParam) {
      case TypeObs.PUBLIC:
        setTypeObs(TypeObs.PUBLIC);
        break;
      case TypeObs.PRIVATE:
        setTypeObs(TypeObs.PRIVATE);
        break;
      default:
        setTypeObs(TypeObs.PUBLIC);
        break;
    }
  }, [typeObsParam]);

  return (
    <Wrapper>
      <MetaLoading loading={isLoading} />
      {!isErrorRoom && (
        <>
          <h2 className="obs-title">OBS WHIP</h2>
          <div className="obs-infor">
            <div className="copy-box bos-type-item">
              <p className="title">Server</p>

              <div className="copy-input-box">
                <div className="copy-link">
                  <span className="ellipsis-1">{String(whipServer)}</span>
                </div>

                {isCopyWhipServer ? (
                  <CheckOutlined className="copy-icon copy-icon-active" />
                ) : (
                  <CopyOutlined
                    className={`copy-icon ${!whipServer && 'is-disabled'}`}
                    onClick={() => {
                      whipServer && handleCopyWhipServer();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="copy-box bos-type-item">
              <p className="title">Bearer Token</p>

              <div className="copy-input-box">
                <div className="copy-link">
                  <span className="ellipsis-1">{String(whepUserSig)}</span>
                </div>

                {isCopyWhepUserSig ? (
                  <CheckOutlined className="copy-icon copy-icon-active" />
                ) : (
                  <CopyOutlined
                    className={`copy-icon ${!whepUserSig && 'is-disabled'}`}
                    onClick={() => {
                      whepUserSig && handleCopyWhepUserSig();
                    }}
                  />
                )}
              </div>
            </div>
            <div className="bos-type-item item-type-select">
              <p className="title">Type</p>
              <SelectCustom
                options={[
                  { value: TypeObs.PUBLIC, label: TypeObs.PUBLIC },
                  { value: TypeObs.PRIVATE, label: TypeObs.PRIVATE },
                ]}
                value={{ value: typeObs, label: typeObs }}
                onChange={handleChangeType}
              />
            </div>
          </div>
          <div className="btn-group">
            <ButtonStart
              icon={
                <Image
                  alt="icon"
                  preview={false}
                  src={!isPlay ? StartIcon : StopIcon}
                  width={11}
                  height={14}
                  style={{
                    borderRadius: '0px',
                  }}
                />
              }
              type={!isPlay ? 'primary' : 'normal'}
              onClick={() => {
                if (isPlay) {
                  handleLeaveObs();
                  stopRoom(roomID);
                } else {
                  startRoom(roomID);
                  startPlay(whepServer, whepUserSig);
                }
              }}
              text={!isPlay ? 'スタート' : '停止'}
              disabled={!(whepServer && whepUserSig)}
            />

            <ButtonStart
              type={isPlay ? 'primary' : 'normal'}
              onClick={() => handleReconnectStreaming()}
              text="Reconnect Streaming"
              disabled={!isPlay || isReconnectStreaming}
            />
          </div>
          {/* Components  */}
          <LivestreamContainer breakpoints={breakpoints}>
            <div className="obs-video">
              <div id="viewer">
                <video
                  id="whepPlayer"
                  controls={false}
                  autoPlay={true}
                  muted={true}
                  style={{ opacity: !isPlay ? 0 : 1 }}
                />
                {donateMessage}

                <BigDonateNotification
                  imgRef={imageDonateNotificationRef}
                  confettiEffectRef={confettiEffectRef}
                  imagePosition={imagePosition}
                  isTheater={false}
                  isMySelf={false}
                />
                {isPlay && (
                  <>
                    <div className="view-info-container">
                      <div className="view-info">
                        <p className="live-text">
                          <span />
                          LIVE
                        </p>

                        <p>{formatTime(time)}</p>
                      </div>

                      <div className="view-info self">
                        <p>
                          <Image
                            src={HeartIcon}
                            alt="heart-icon"
                            preview={false}
                            width={16}
                            height={13}
                          />

                          {totalOfLike}
                        </p>

                        <p>
                          <Image
                            src={ViewIcon}
                            alt="view-icon"
                            preview={false}
                            width={16}
                            height={11}
                          />

                          {totalOfViewer}
                        </p>
                      </div>
                    </div>
                    <div className="shadow-orange" />
                  </>
                )}
              </div>
            </div>
            <ChatGroup
              chatRTC={chatRTC}
              isTheater={false}
              userStream={currentUser}
              currentUser={currentUser}
              messageLists={messageLists}
              setMessageLists={setMessageLists}
              setInputMessage={setInputMessage}
              handleLocalChange={() => {}}
              sendButton={sendButton}
              inputMessage={inputMessage}
              localStreamConfig={{}}
              handleJoin={() => {}}
              handleLeave={() => {}}
              isMySelf={true}
              receiveStatus={receiveStatus}
              setReceiveStatus={setReceiveStatus}
              dispatch={dispatch}
              roomID={roomID}
              isDisabled={!roomID || !isPlay}
              isObsLive={true}
            />
          </LivestreamContainer>

          {currentUser && roomID && isStartRoomSuccess && (
            <ChatRTC
              onRef={ref => setChatRTC(ref)}
              currentUser={currentUser}
              groupID={roomID}
              messageLists={messageLists}
              setMessageLists={setMessageLists}
              inputMessage={inputMessage}
              sendButton={sendButton}
              setReceiveStatus={setReceiveStatus}
            />
          )}

          {effectSoundUrl && (
            <audio
              controls
              ref={audioRef}
              preload="none"
              style={{
                display: 'none',
              }}
            >
              <source src={effectSoundUrl} type="audio/mpeg" />
            </audio>
          )}
        </>
      )}
    </Wrapper>
  );
});
