import SocketAxios from 'app/apis/ConfigSocketAxios';
import Axios from 'app/apis';

const listPackage = () => {
  return Axios.get('/point-packages');
};

const purchasePoint = (data: any) => {
  return SocketAxios.post('/payment/create', data);
};

const paymentTransaction = (data: { outTradeNo: string; result: string }) => {
  return SocketAxios.post('/payment/transaction', data);
};

const historyPoint = () => {
  return SocketAxios.get('/payment/me');
};

export { listPackage, purchasePoint, paymentTransaction, historyPoint };
