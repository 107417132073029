import React, { RefObject } from 'react';
import { breakpoints } from 'utils/media-breakpoint';
import { ConfettiStyled, Container } from './styles';

export type ImagePosition =
  | 'TOP_CENTER'
  | 'TOP_RIGHT'
  | 'BOTTOM_CENTER'
  | 'BOTTOM_RIGHT'
  | 'CENTER'
  | 'BOTTOM_LEFT';

const BigDonateNotification = ({
  imgRef,
  confettiEffectRef,
  imagePosition = 'CENTER',
  isTheater,
  isMySelf,
}: {
  imgRef: RefObject<HTMLImageElement>;
  confettiEffectRef: RefObject<HTMLCanvasElement>;
  imagePosition: ImagePosition | null;
  isTheater: boolean;
  isMySelf: boolean;
}) => {
  return (
    <Container
      id="big-donate-notification-container"
      imagePosition={imagePosition as ImagePosition}
      breakpoints={breakpoints}
    >
      <ConfettiStyled
        isTheater={isTheater}
        isMySelf={isMySelf}
        breakpoints={breakpoints}
        ref={confettiEffectRef}
      />

      <img alt="" ref={imgRef} className="image-donate" />
    </Container>

    // <video
    //   width="400"
    //   autoPlay
    //   muted
    //   loop
    //   playsInline
    //   style={{
    //     position: 'absolute',
    //     zIndex: 100,
    //   }}
    // >
    //   {/* <source
    //     src="https://rotato.netlify.app/alpha-demo/movie-hevc.mov"
    //     type='video/mp4; codecs="hvc1"'
    //   />
    //   <source
    //     src="https://rotato.netlify.app/alpha-demo/movie-webm.webm"
    //     type="video/webm"
    //   /> */}
    //   {/* <source
    //     src="/assets/media/file_example.mov"
    //     type='video/mp4; codecs="hvc1"'
    //   />
    //   <source src="/assets/media/file_example.webm" type="video/webm" /> */}
    //   {/* <source
    //     src="/assets/media/sample_effect.mov"
    //     type='video/mp4; codecs="hvc1"'
    //   />
    //   <source src="/assets/media/sample_effect.webm" type="video/webm" /> */}
    // </video>
  );
};

export default BigDonateNotification;
