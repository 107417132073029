import React from 'react';
import TIM from 'tim-js-sdk';
import { decodeText } from 'utils/decodeText';
import { SDKAPPID } from 'configs';
import { personRequest } from 'app/apis/profile';

export default class ChatComponent extends React.Component<any> {
  [x: string]: any;
  props: any;

  constructor(props: any) {
    super(props);
    this.tim = null;
    this.isSdkReady = false;
    this.messageList = [];
    this.setMessageLists = props.setMessageLists;
    this.setReceiveStatus = props.setReceiveStatus;
    this.memberList = [];
    this.muteUserIdList = [];
    this.inputMessage = props.inputMessage || '';
    this.groupID = props.groupID;
    this.roomName = 'vqtrung';
    this.userInfo = props.currentUser;
    this.muteUserIdKey = `TUIPusher_${SDKAPPID}_${this.groupID}_${this.userInfo.id}_muteUserId`;
    this.sendMessage = this.sendMessage.bind(this);
    this.onReadyStateUpdate = this.onReadyStateUpdate.bind(this);
    this.onNotReadyStateUpdate = this.onNotReadyStateUpdate.bind(this);
    this.onKickOut = this.onKickOut.bind(this);
    this.onError = this.onError.bind(this);
    this.onTextMessageReceived = this.onTextMessageReceived.bind(this);
  }

  async componentDidMount() {
    this.props.onRef(this);
    await this.initTim();
  }

  async componentWillUnmount() {
    this.logout();
  }

  async initTim() {
    const tim = TIM.create({
      SDKAppID: SDKAPPID,
    });
    tim.setLogLevel(0);

    this.handleTimEvents(tim);
    this.loginTim(tim);
    this.tim = tim;
  }

  async loginTim(tim: any) {
    try {
      await tim.login({
        userID: `${this.userInfo.id}`,
        userSig: this.userInfo.user_sign,
      });
    } catch (imError) {
      const errorInfo = ``;
      // Handle error appropriately
      console.error('im | login | failed', imError);
    }
  }

  async logout() {
    try {
      await this.tim.logout();
    } catch (imError) {
      console.error('logout error:', imError);
    }
  }

  async joinGroup() {
    try {
      await this.tim.joinGroup({
        groupID: `${this.props.groupID}`,
        type: TIM.TYPES.GRP_AVCHATROOM,
      });
      this.getGroupMemberList();
    } catch (error) {
      console.error('joinGroup error:', error);
    }
  }

  async createGroup() {
    try {
      await this.tim.createGroup({
        type: TIM.TYPES.GRP_AVCHATROOM,
        name: this.roomName,
        groupID: `${this.props.groupID}`,
      });
    } catch (imError) {
      console.error('createGroup error:', imError);
      // this.$message.error(`创建群组失败的相关信息`);
    }
  }

  async dismissGroup(groupID) {
    try {
      await this.tim.dismissGroup(groupID);
    } catch (imError) {
      // this.$message.error(`tim.Failed to dismiss streaming group'`);
      console.error('dismissGroup error:', imError);
    }
  }

  async searchGroup() {
    try {
      await this.tim.searchGroupByID(`${this.props.groupID}`);
      await this.tim.updateGroupProfile({
        groupID: `${this.props.groupID}`,
        name: this.roomName,
      });

      this.joinGroup();

      const muteUserIdStorage = JSON.parse(
        localStorage.getItem(`this.muteUserIdKey`) || '',
      );

      if (muteUserIdStorage) {
        const { time, value } = muteUserIdStorage;
        if (Date.now() - time > 7 * 24 * 60 * 60 * 1000) {
          localStorage.removeItem(this.muteUserIdKey);
        } else {
          this.muteUserIdList = value;
        }
      }
    } catch (imError) {
      console.warn('searchGroupByID error:', imError);
      await this.createGroup();
      await this.joinGroup();
    }
  }

  async getGroupMemberList() {
    try {
      const {
        data: { memberList },
      } = await this.tim.getGroupMemberList({
        groupID: `${this.props.groupID}`,
        count: 30,
        offset: 0,
      });
      memberList.forEach(member => {
        if (member.userID === this.userInfo.userId) {
          this.memberList.unshift({
            ...member,
            isMuted: false,
          });
        } else if (member.muteUntil * 1000 > Date.parse(`${new Date()}`)) {
          // 仍被禁言
          this.memberList.push({
            ...member,
            isMuted: true,
          });
        } else {
          this.memberList.push({
            ...member,
            isMuted: false,
          });
        }
      });
    } catch (imError) {
      console.error('getGroupMemberProfile error:', imError);
    }
  }

  handleTimEvents(tim: any) {
    tim.on(TIM.EVENT.SDK_READY, this.onReadyStateUpdate);
    tim.on(TIM.EVENT.SDK_NOT_READY, this.onNotReadyStateUpdate);
    tim.on(TIM.EVENT.KICKED_OUT, this.onKickOut);
    tim.on(TIM.EVENT.ERROR, this.onError);
    tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onTextMessageReceived);
  }

  onReadyStateUpdate({ name }) {
    const isSDKReady = name === TIM.EVENT.SDK_READY;

    if (isSDKReady) {
      this.isSdkReady = true;
      this.tim.getMyProfile();
      try {
        this.tim.updateMyProfile({
          nick: this.userInfo.username,
          avatar: this.userInfo.avatar_url || '',
        });
        this.searchGroup();
      } catch (imError) {
        console.error('updateMyProfile error:', imError);
      }
    }
  }

  onNotReadyStateUpdate({ name }) {
    //console.log(`onReadyStateUpdate ${name}`);
  }

  onTextMessageReceived(event) {
    const messageList = event.data;

    messageList.forEach(message => {
      if (
        message.type === TIM.TYPES.MSG_GRP_TIP &&
        message.payload.operationType === TIM.TYPES.GRP_TIP_MBR_JOIN
      ) {
        this.handleJoinGroupTip(message);
      }
      if (
        message.type === TIM.TYPES.MSG_GRP_TIP &&
        message.payload.operationType === TIM.TYPES.GRP_TIP_MBR_QUIT
      ) {
        this.handleQuitGroupTip(message);
      }
      if (message.type === TIM.TYPES.MSG_TEXT) {
        this.handleMessageTip(message);
        this.setReceiveStatus(true);
      }
      if (
        message.type === TIM.TYPES.MSG_GRP_SYS_NOTICE &&
        message.payload.userDefinedField
      ) {
        this.messageList.push({
          type: 'notice',
          content: message.payload.userDefinedField,
          time: message.time,
        });

        this.setReceiveStatus(true);
        this.props.setMessageLists([...this.messageList]);
      }
    });
  }

  async handleMessageTip(message) {
    await personRequest({ id: message.from }).then(res => {
      this.messageList.push({
        nick: message.nick || message.from,
        content: message.payload.text,
        renderContent: decodeText(message.payload.text),
        userID: message.from,
        avatar: res?.data?.avatar_url,
        time: message.time,
      });
    });

    this.props.setMessageLists([...this.messageList]);
  }

  async handleJoinGroupTip(message) {
    if (message.payload.operatorID === this.userInfo.userId) {
      return;
    }
    const {
      avatar,
      nick,
      payload: { operatorID },
    } = message;
    this.memberList = this.memberList.filter(
      memberInfo => memberInfo.userID !== operatorID,
    );
    const newUserInfo = {
      avatar,
      userID: operatorID,
      nick,
      isMuted: this.muteUserIdList.indexOf(operatorID) >= 0,
    };
    this.memberList.push(newUserInfo);
  }

  async handleQuitGroupTip(message) {
    this.memberList = this.memberList.filter(
      member => member.userID !== message.payload.operatorID,
    );
  }

  async sendMessage(msgText) {
    if (!this.isSdkReady) {
      //  console.log('sdk not ready');
      return;
    }

    const message = await this.tim.createTextMessage({
      to: `${this.props.groupID}`,
      conversationType: TIM.TYPES.CONV_GROUP,
      payload: {
        text: msgText,
      },
    });
    try {
      const imResponse = await this.tim.sendMessage(message);
      // console.log(imResponse, '成功');
    } catch (imError) {
      console.error('sendMessage error:', imError);
    }

    this.messageList.push({
      nick: message.nick || '',
      content: message.payload.text,
      renderContent: decodeText(message.payload.text),
      userID: message.from,
      time: message?.time,
    });
    this.props.setMessageLists([...this.messageList]);
  }

  async setGroupMemberMuteTime({ userID, muteTime }) {
    try {
      await this.tim.setGroupMemberMuteTime({
        groupID: `${this.props.groupID}`,
        userID,
        muteTime,
      });
      const member = this.memberList.find(item => item.userID === userID);
      member.isMuted = muteTime !== 0;
      if (muteTime > 0 && this.muteUserIdList.indexOf(userID) < 0) {
        this.muteUserIdList.push(userID);
      } else {
        this.muteUserIdList = this.muteUserIdList.filter(
          muteUserID => muteUserID !== userID,
        );
      }
      localStorage.setItem(
        this.muteUserIdKey,
        JSON.stringify({
          time: Date.now(),
          value: this.muteUserIdList,
        }),
      );
    } catch (imError) {
      console.error('setGroupMemberMuteTime error:', imError);
    }
  }

  onError() {}

  onKickOut() {}

  render() {
    return (
      <div
        style={{
          display: 'none',
        }}
      >
        <input
          value="Click me"
          ref={this.props.sendButton}
          onClick={() => this.sendMessage(this.props.inputMessage)}
          type="button"
        ></input>
      </div>
    );
  }
}
