/**
 *
 * MetaLink
 *
 */
import React, { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

interface Props {
  text: string;
  href?: string;
  target?: string;
  icon?: any;
  type?: string;
  color?: string;
}

export const MetaLink = memo((props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const { text, href, target, icon, type, color } = props;

  if (type === 'history') {
    return (
      <LinkHistory color={color} onClick={() => navigate(href || '#')}>
        {icon}
        <span>{text}</span>
      </LinkHistory>
    );
  }

  return (
    <LinkCustom to={href || '#'} target={target || ''}>
      {text}
    </LinkCustom>
  );
});

const LinkCustom = styled(Link)`
  font-size: 14px;
  text-decoration-line: underline !important;
  color: ${props => props.theme.color_primary} !important;
`;

const LinkHistory = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.color};
  span {
    margin-left: 7px;
  }
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;
