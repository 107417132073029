import React from 'react';
import TIM from 'tim-js-sdk';
import { decodeText } from 'utils/decodeText';
import { SDKAPPID } from 'configs';
import { toast } from 'react-toastify';
import { personRequest } from 'app/apis/profile';

export default class ChatComponent extends React.Component<any> {
  [x: string]: any;
  props: any;

  constructor(props: any) {
    super(props);
    this.tim = null;
    this.isSdkReady = false;
    this.messageList = [];
    this.setMessageLists = props.setMessageLists;
    this.setReceiveStatus = props.setReceiveStatus;
    this.setIsClientBannedError = props.setIsClientBannedError;
    this.memberList = [];
    this.muteUserIdList = [];
    this.inputMessage = props.inputMessage || '';
    this.groupID = props.groupID;
    this.userInfo = props.currentUser;
    this.anchorUserId = props.currentUser?.id;
    this.muteUserIdKey = `TUIViewer_${SDKAPPID}_${this.groupID}_${this.userInfo.id}_muteUserId`;
    this.sendMessage = this.sendMessage.bind(this);
    this.onReadyStateUpdate = this.onReadyStateUpdate.bind(this);
    this.onNotReadyStateUpdate = this.onNotReadyStateUpdate.bind(this);
    this.onKickOut = this.onKickOut.bind(this);
    this.onError = this.onError.bind(this);
    this.onTextMessageReceived = this.onTextMessageReceived.bind(this);
  }

  async componentDidMount() {
    // this.props.onRef(this);

    await this.initTim();
  }

  async componentWillUnmount() {
    this.logout();
  }

  async initTim() {
    const tim = TIM.create({
      SDKAppID: SDKAPPID,
    });
    // tim.setLogLevel(0);

    this.handleTimEvents(tim);
    this.loginTim(tim);
    this.tim = tim;
  }

  async loginTim(tim: any) {
    try {
      await tim.login({
        userID: `${this.userInfo.id}`,
        userSig: this.userInfo.user_sign,
      });
    } catch (imError) {
      const errorInfo = ``;
      // Handle error appropriately
      console.error('im | login | failed', imError);
    }
  }

  async logout() {
    try {
      await this.tim.logout();
    } catch (imError) {
      console.error('logout error:', imError);
    }
  }

  async joinGroup() {
    try {
      await this.tim.joinGroup({
        groupID: `${this.props.groupID}`,
        type: TIM.TYPES.GRP_AVCHATROOM,
      });

      // this.getGroupMemberList();
    } catch (error) {
      console.error('joinGroup error:', error);
    }
  }

  async searchGroup() {
    try {
      await this.tim.searchGroupByID(`${this.props.groupID}`);
      // await this.tim.updateGroupProfile({
      //   groupID: `${this.props.groupID}`,
      //   name: this.roomName,
      // });

      this.joinGroup();

      const muteUserIdStorage = JSON.parse(
        localStorage.getItem(`this.muteUserIdKey`) || '',
      );

      if (muteUserIdStorage) {
        const { time, value } = muteUserIdStorage;
        if (Date.now() - time > 7 * 24 * 60 * 60 * 1000) {
          localStorage.removeItem(this.muteUserIdKey);
        } else {
          this.muteUserIdList = value;
        }
      }
    } catch (imError) {
      console.warn('searchGroupByID error:', imError);
      // await this.createGroup();
      // await this.joinGroup();
    }
  }

  async getGroupMemberList() {
    try {
      const {
        data: { memberList },
      } = await this.tim.getGroupMemberList({
        groupID: `${this.props.groupID}`,
        count: 30,
        offset: 0,
      });
      memberList.forEach(member => {
        if (member.userID === this.userInfo.userId) {
          this.memberList.unshift({
            ...member,
            isMuted: false,
          });
        } else if (member.muteUntil * 1000 > Date.parse(`${new Date()}`)) {
          // 仍被禁言
          this.memberList.push({
            ...member,
            isMuted: true,
          });
        } else {
          this.memberList.push({
            ...member,
            isMuted: false,
          });
        }
      });
    } catch (imError) {
      console.error('getGroupMemberProfile error:', imError);
    }
  }

  handleTimEvents(tim: any) {
    tim.on(TIM.EVENT.SDK_READY, this.onReadyStateUpdate);
    tim.on(TIM.EVENT.SDK_NOT_READY, this.onNotReadyStateUpdate);
    tim.on(TIM.EVENT.KICKED_OUT, this.onKickOut);
    tim.on(TIM.EVENT.ERROR, this.onError);
    tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onTextMessageReceived);
  }

  onReadyStateUpdate({ name }) {
    try {
      this.tim.updateMyProfile({
        nick: this.userInfo.username,
        avatar: this.userInfo.avatar_url || '',
      });
      this.searchGroup();
    } catch (imError) {
      console.error('updateMyProfile error:', imError);
    }
  }

  onNotReadyStateUpdate({ name }) {
    // console.log(`onReadyStateUpdate ${name}`);
  }

  async onTextMessageReceived(event) {
    const messageList = event.data;

    const promises = messageList.map(async message => {
      if (message.type === TIM.TYPES.MSG_TEXT) {
        const res = await personRequest({ id: message.from });
        this.messageList.push({
          nick: message.nick || message.from,
          content: message.payload.text,
          renderContent: decodeText(message.payload.text),
          userID: message.from,
          avatar: res?.data?.avatar_url,
          time: message.time,
        });
      } else if (
        message.type === TIM.TYPES.MSG_GRP_SYS_NOTICE &&
        message.payload.userDefinedField
      ) {
        this.messageList.push({
          type: 'notice',
          content: message.payload.userDefinedField,
          time: message.time,
        });
      }
    });

    await Promise.all(promises);

    this.updateMessageLists();
  }

  updateMessageLists() {
    this.setReceiveStatus(true);
    this.props.setMessageLists([...this.messageList]);
  }

  async sendMessage(msgText) {
    const messageItem: any = {};

    try {
      const message = await this.tim.createTextMessage({
        to: `${this.props.groupID}`,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          text: msgText,
        },
      });

      try {
        await this.tim.sendMessage(message);
        messageItem.state = 'success';
      } catch (imError) {
        messageItem.state = 'fail';
      }

      this.messageList.push({
        nick: message.nick || '',
        content: message.payload.text,
        renderContent: decodeText(message.payload.text),
        userID: message.from,
        time: message?.time,
      });

      this.props.setMessageLists([...this.messageList]);
    } catch (error) {
      messageItem.state = 'fail';
    }
  }

  onError() {}

  onKickOut(event) {
    if (event.name === TIM.EVENT.KICKED_OUT) {
      this.setIsClientBannedError(true);
    }
  }

  render() {
    return (
      <div
        style={{
          display: 'none',
        }}
      >
        <input
          value="Click me"
          ref={this.props.sendButton}
          onClick={() => this.sendMessage(this.props.inputMessage)}
          type="button"
        ></input>
      </div>
    );
  }
}
