import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import moment from 'moment';
import { allGifts, gifts } from 'app/apis/gift';

const ChatTab = ({ messageLists, isMySelf, currentUser }) => {
  const [giftList, setGiftList] = useState<any>([]);
  const [messageId, setMessageId] = useState<any>([]);
  const [messageListsMobile, setMessageListsMobile] = useState<any>([]);

  const onToggleSeeAll = index => {
    setMessageId([...messageId, index]);
  };

  useEffect(() => {
    // setMessageListsMobile(takeRight(messageLists, MESSAGE_LIMIT));
    setMessageListsMobile(messageLists);
  }, [messageLists?.[messageLists?.length - 1]]);

  // get all gifts of categories
  useEffect(() => {
    allGifts()
      .then((res: any) => {
        const { data, statusCode } = res;
        if (statusCode === 200) {
          data
            ?.map(d => {
              gifts({ category_id: d?.id }).then((gRes: any) => {
                const { data, statusCode } = gRes;
                if (statusCode === 200) {
                  setGiftList(data?.rows);
                }
              });
            })
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const element: any = document.getElementById('chat');
      if (element) {
        element.scrollTo(0, element.scrollHeight);
      }
    }, 2000);
  }, []);

  return (
    <div className="chat-container-mobile-box">
      <div
        id="chat"
        className="chat-container-mobile"
        style={{
          gridTemplateRows: isMySelf && '1fr',
        }}
      >
        {messageListsMobile &&
          messageListsMobile?.length > 0 &&
          messageListsMobile
            ?.sort((a, b) => a.time - b.time)
            ?.map((list, i) => {
              /* them  */
              if (+list?.userID !== currentUser?.id) {
                if (list?.type !== 'notice') {
                  return (
                    <div className="box-content" key={i}>
                      <div className="box-content-name">
                        <Typography.Title
                          level={5}
                          style={{ color: '#C82A15', fontSize: '14px' }}
                        >
                          {list?.nick}
                        </Typography.Title>
                        <Typography.Text className="time-text">
                          {moment.unix(list?.time).format('HH:mm')}
                        </Typography.Text>
                      </div>
                      <div className="message-detail">
                        <Typography.Paragraph
                          ellipsis={
                            !messageId?.includes(i)
                              ? {
                                  rows: 7,
                                  expandable: !messageId?.includes(i),
                                }
                              : false
                          }
                        >
                          {list?.content}
                        </Typography.Paragraph>
                        {!messageId?.includes(i) &&
                          list?.content?.length > 300 && (
                            <div
                              className="btn-see-all"
                              onClick={() => onToggleSeeAll(i)}
                            >
                              See All <RightOutlined />
                            </div>
                          )}
                      </div>
                    </div>
                  );
                } else {
                  if (
                    list?.content &&
                    list?.content?.indexOf('has send a') > -1
                  ) {
                    const index = list?.content?.indexOf(' has send a ');
                    const index2 = list?.content?.lastIndexOf(' to ');
                    const donateSender = list?.content?.slice(0, index);
                    const donateReceiver = list?.content?.slice(index2 + 4);
                    const giftInfo = giftList?.find(
                      g => g?.name === list?.content?.slice(index + 12, index2),
                    );

                    return (
                      <div className="box-content" key={list?.type + i}>
                        <div className="box-content-description box-content-notification">
                          <div className="box-content-name">
                            <Typography.Title
                              level={5}
                              style={{ color: '#C82A15', fontSize: '14px' }}
                            >
                              お知らせ
                            </Typography.Title>
                            <Typography.Text className="time-text">
                              {moment.unix(list?.time).format('HH:mm')}
                            </Typography.Text>
                          </div>

                          <Typography.Paragraph
                            className="message-detail message-notification-detail"
                            style={{
                              marginBottom: '0px',
                            }}
                          >
                            <p
                              style={{
                                marginBottom: '0px',
                                color: '#fff',
                                fontSize: '12px',
                              }}
                            >
                              {`${donateSender} さんが  ${list?.content?.slice(
                                index + 12,
                                index2,
                              )} (${giftInfo?.price}p) を贈りました`}{' '}
                              <img
                                src={giftInfo?.image_url}
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  objectFit: 'contain',
                                }}
                              />
                            </p>
                          </Typography.Paragraph>
                        </div>
                      </div>
                    );
                  }
                }
              } else {
                /* our  */
                return (
                  <div className="box-content my-message" key={i}>
                    <div className="box-content-name">
                      <Typography.Title
                        level={5}
                        style={{ color: '#fff', fontSize: '14px' }}
                      >
                        You
                      </Typography.Title>
                      <Typography.Text className="time-text">
                        {moment.unix(list?.time).format('HH:mm')}
                      </Typography.Text>
                    </div>
                    <div className="message-detail">
                      <Typography.Paragraph
                        ellipsis={
                          !messageId?.includes(i)
                            ? {
                                rows: 7,
                                expandable: !messageId?.includes(i),
                              }
                            : false
                        }
                      >
                        {list?.content}
                      </Typography.Paragraph>
                      {!messageId?.includes(i) && list?.content?.length > 300 && (
                        <div
                          className="btn-see-all"
                          onClick={() => onToggleSeeAll(i)}
                        >
                          See All <RightOutlined />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
      </div>
    </div>
  );
};

export default ChatTab;
