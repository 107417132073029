import Axios from 'app/apis';

const gifts = (data: any) => {
  return Axios.get('/gifts', data);
};

const allGifts = () => {
  return Axios.get('/gifts/category');
};

const sendDonate = (data: any) => {
  return Axios.post('/donate/create', data);
};

const roomDonateHistory = (id: number) => {
  return Axios.get('/donate/room/' + id);
};

const myDonate = () => {
  return Axios.get('/donate/me/');
};

const myDonateHistory = () => {
  return Axios.get('/donate/history');
};

const allDonate = () => {
  return Axios.get('/donate/all');
};

const pointActivityRequest = ({ page, limit }) => {
  return Axios.get(`/point/activity?page=${page}&limit=${limit}`);
};

const updateRoomConnectedRequest = ({ roomID }) => {
  return Axios.put(`/rooms/update-connected/${roomID}`);
};

export {
  gifts,
  allGifts,
  sendDonate,
  roomDonateHistory,
  myDonate,
  myDonateHistory,
  allDonate,
  pointActivityRequest,
  updateRoomConnectedRequest,
};
