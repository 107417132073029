import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import TRTC from 'trtc-js-sdk';

export default function DeviceData({
  deviceType,
  updateDeviceList,
  updateActiveDeviceId,
}) {
  const getDeviceList = async (deviceType: any) => {
    let deviceList: any = [];

    if (deviceType) {
      switch (deviceType) {
        case 'camera':
          deviceList = await TRTC.getCameras();
          break;
        case 'microphone':
          deviceList = await TRTC.getMicrophones();
          break;
        case 'speaker':
          deviceList = await TRTC.getSpeakers();
          break;
        default:
          break;
      }
      return deviceList;
    }
    return [];
  };

  const handleData = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: deviceType === 'microphone',
        video: deviceType === 'camera',
      });
      mediaStream.getTracks()[0].stop();
    } catch (error: any) {
      toast.error(error);
    }

    const list = await getDeviceList(deviceType);
    updateDeviceList && updateDeviceList(list);
    const activeDeviceId = (list[0] && list[0].deviceId) || '';
    updateActiveDeviceId && updateActiveDeviceId(activeDeviceId);
  };

  useEffect(() => {
    handleData();
  }, []);

  navigator?.mediaDevices?.addEventListener('devicechange', async () => {
    const newList = await getDeviceList(deviceType);
    updateDeviceList && updateDeviceList(newList);
  });

  return <div></div>;
}
