import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ChatIcon, GiftActive, TopDonation, PersonIcon } from 'styles/image';
import { breakpoints } from 'utils/media-breakpoint';
import { useDonation } from './useDonation';
import { allGifts, gifts } from 'app/apis/gift';

export const useChatGroup = () => {
  const [tabKey, setTabKey] = useState('1');
  const [isOpenViewerModal, setIsOpenViewerModal] = useState(false);

  const [messageId, setMessageId] = useState<any>([]);

  const isTabletOrDesktop = useMediaQuery({
    minWidth: breakpoints.mobile,
  });

  const { giftTypeData, giftData } = useDonation();

  const [categoriesFiltered, setCategoriesFiltered] = useState<any[]>([]);
  const [giftList, setGiftList] = useState<any>([]);

  // get all gifts of categories
  useEffect(() => {
    allGifts()
      .then((res: any) => {
        const { data, statusCode } = res;
        if (statusCode === 200) {
          data
            ?.map(d => {
              gifts({ category_id: d?.id }).then((gRes: any) => {
                const { data, statusCode } = gRes;
                if (statusCode === 200) {
                  setGiftList(data?.rows);
                }
              });
            })
            .catch(err => console.log(err));
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (giftTypeData && giftTypeData.length > 0) {
      const filtered = giftTypeData.filter(
        (item: any) => item.isPublic === true,
      );

      setCategoriesFiltered(filtered);
    }
  }, [giftTypeData]);

  const chatKey = '1';

  const giftKey = '2';

  const donationHistoryKey = '3';

  const userJoinKey = '4';

  const tabs: any = [
    {
      key: chatKey,
      name: 'チャット',
      icon: ChatIcon,
    },
    {
      key: giftKey,
      name: 'ギフト',
      icon: GiftActive,
    },
    {
      key: donationHistoryKey,
      name: 'ギフト履歴',
      icon: TopDonation,
    },

    {
      key: userJoinKey,
      name: '閲覧者リスト',
      icon: PersonIcon,
    },
  ];

  const handleChangeTab = key => {
    setTabKey(key);
  };

  const onToggleSeeAll = index => {
    setMessageId([...messageId, index]);
  };

  return {
    tabs,
    tabKey,
    handleChangeTab,
    isTabletOrDesktop,
    giftKey,
    donationHistoryKey,
    categoriesFiltered,
    messageId,
    onToggleSeeAll,
    giftData,
    isOpenViewerModal,
    setIsOpenViewerModal,
    giftList,
    setGiftList,
  };
};
