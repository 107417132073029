import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToastState } from './types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { toastSaga } from './saga';
import history from 'utils/history';

export const initialState: ToastState = {
  isOpen: false,
  message: undefined,
  type: 'success',
  titleButton: undefined,
  urlRedirect: undefined,
  handleFunc: null,
  isErrorToken: false,
  imageElement: null,
  buttonElement: null,
  titleCustom: '',
  children: null,
};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast(
      state,
      action: PayloadAction<{
        message: string;
        type:
          | 'success'
          | 'verified'
          | 'error'
          | 'confirm'
          | 'sorry'
          | 'custom'
          | 'customChild';
        titleButton?: string;
        urlRedirect?: string;
        handleFunc?: any;
        isErrorToken?: boolean;
        imageElement?: any;
        buttonElement?: any;
        children?: any;
        titleCustom?: string;
      }>,
    ) {
      state.isOpen = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.titleButton = action.payload.titleButton;
      state.urlRedirect = action.payload.urlRedirect;
      state.handleFunc = action.payload.handleFunc;
      state.isErrorToken = action.payload.isErrorToken;
      state.titleCustom = action.payload.titleCustom;
      state.imageElement = action.payload.imageElement;
      state.children = action.payload.children;
      state.buttonElement = action.payload.buttonElement;
    },
    closeToast(state, action: PayloadAction<any>) {
      state.isOpen = false;
      state.message = undefined;
      state.type = 'success';
      state.titleButton = undefined;
      if (state.urlRedirect) {
        history.push(state.urlRedirect);
      }
    },
  },
});

export const { actions: actionsToast } = slice;

export const useToastSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: toastSaga });
  return { actions: slice.actions };
};
