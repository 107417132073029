import styled from 'styled-components';
import { Select } from 'antd';

import { MetaButton } from 'app/components/Forms';
import { breakpoints } from 'utils/media-breakpoint';

// css
export const Wrapper = styled.div<any>`
  padding: 10px 100px;

  .obs-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    color: #1f2937;
    margin: 10px 0 40px;
  }

  .obs-infor {
    display: flex;
    align-items: center;
    margin: auto;
    width: 980px;

    .bos-type-item {
      width: calc(100% / 3);
    }

    .item-type-select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      min-height: 44px;
      height: 44px;
      width: 50%;
      margin-left: 20px;

      .title {
        font-size: 14px;
        font-weight: 400;
        color: #9ca3af;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .copy-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    min-height: 44px;
    height: 44px;
    width: 50%;

    &:first-child {
      margin-right: 20px;
    }

    .title {
      font-size: 14px;
      font-weight: 400;
      color: #9ca3af;
    }

    .copy-input-box {
      display: flex;
    }

    .copy-link {
      border-radius: 10px;
      min-height: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      height: max-content;
      box-shadow: 0px 1px 2px 0px #1018280d;
      color: #000;
      font-size: 14px;
      padding: 10px 22px;
      outline: none;
      margin: 0px;
      margin-right: 10px;
      border: 1px solid #d1d5db;
      background: #f3f4f6;
      text-underline-offset: 3px;
      width: 100%;
      white-space: nowrap;
      overflow: auto;

      span {
        display: block !important;
      }
    }
    .copy-icon {
      background: #f3f4f6;
      padding: 10px;
      border-radius: 7px;
      color: #9ca3af;
      width: 44px;
      height: 100%;
      transition: 0.3s;
      justify-content: center;
      font-size: 18px;
      &:hover {
        background: #eb7d17;
        color: #fff;
        transition: 0.3s;
      }
    }
    .copy-icon.is-disabled {
      cursor: no-drop;

      &:hover {
        background: #f3f4f6;
        color: #9ca3af;
      }
    }
    .copy-icon-active {
      background: #fff;
      color: green;
      transition: 0.3s;
      border: 1px solid #d1d5db;
      &:hover {
        background: #fff;
        color: green;
        transition: 0.3s;
        border: 1px solid #d1d5db;
      }
    }
  }

  .btn-group {
    display: flex;
    justify-content: center;
    margin: 15px;

    button {
      margin: 0 10px !important;
    }
  }

  .obs-video {
    width: 980px;
    height: 560px;
    margin: 20px auto 0;
    border-radius: 30px;
    border: 1px solid #eb7d17;
    box-shadow: 0px 0px 20px 0px #eb311752;
    padding: 10px;

    video {
      width: 960px;
      height: 540px;
      border-radius: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 24px 0px #00000026;
    }
  }

  #viewer {
    position: relative;
  }

  .ant-message {
    position: absolute;
    left: 0 !important;
    transform: translateX(0) !important;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: start;
    z-index: 1;

    .ant-message-notice,
    .ant-message-notice-wrapper {
      text-align: left !important;

      transition: all 0.3s;
    }

    .ant-message-notice {
      transition: all 0.3s;
    }

    .ant-message-notice:last-child,
    .ant-message-notice-wrapper:last-child {
      .ant-message-notice-content {
        background-color: #4f4f4f73;
      }

      .donate-message-heading {
        color: #eb7d17;
      }

      .donate-message-content {
        color: var(--gray-colors-white, #fff);
      }
    }

    .anticon.anticon-check-circle {
      display: none !important;
    }

    .ant-message-notice-content {
      padding: 10px 20px;
      border-radius: 0px 16px 16px 16px;
      background-color: #4f4f4f73;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      transition: all 0.2s;
    }

    .donate-message-box {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .donate-message-gif-image {
      width: 35px;
      height: 35px;
    }

    .donate-message {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .donate-message-heading {
      color: #eb7d17;
      font-family: Helvetica Neue;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 160% */

      margin-top: 0;
      margin-bottom: 0;

      transition: all 0.2s;
    }

    .donate-message-content {
      color: #fff;
      font-family: Helvetica Neue;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 85.714% */

      transition: all 0.2s;
    }
  }

  .view-info-container {
    width: 100%;
    position: absolute;
    bottom: 13px;
    left: 0;
    padding-inline: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
  }

  .view-info {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .live-text {
      align-items: center;
      padding: 2px 10px;
      background: #eb7d17;
      color: #fff;
      border-radius: 16px;
      font-size: 12px;
      font-weight: 600;

      span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #fff;
      }
    }
  }

  .view-info.self {
    gap: 21px;

    .ant-image {
      display: flex;
    }
  }

  .shadow-orange {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 46px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: linear-gradient(180deg, rgba(235, 125, 23, 0) 0%, #924f12 100%);

    @media only screen and (max-width: 1729px) {
      bottom: 0px;
    }
    @media only screen and (max-width: 703px) {
      border-radius: ${props =>
        props?.isMySelf || props.isFull ? '0px' : '16px'};
    }
  }
`;

export const ButtonStart = styled(MetaButton)<any>`
  margin: auto;
  margin-top: 10px;
  width: 328px;
  height: 52px !important;
  color: #9ca3af;
`;

export const LivestreamContainer = styled.div<{
  breakpoints: typeof breakpoints;
}>`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;

  @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
    grid-template-columns: calc(100% - 360px - 10px) 360px;
    gap: 20px;
  }

  @media only screen and (min-width: ${props =>
      props.breakpoints.largeScreen}) {
    grid-template-columns: calc(100% - 747px - -21px) 705px;
  }

  .chat-container {
    height: calc(100% - 40px);
  }
`;

export const SelectCustom = styled(Select)`
  width: 100%;

  &.ant-select-status-error {
    .ant-select-selector {
      border: 1px solid #e10000 !important;
    }
  }

  .ant-select-selection-item {
    font-size: 14px;
    font-weight: 400;
    color: #1f2937;
  }

  .ant-select-arrow {
    color: #1f2937;
  }

  input {
    /* padding: 6px 20px 6px 10px !important; */
    border-radius: 10px;
    border-color: #d1d5db;
    height: 44px;
  }

  .ant-select-selection-placeholder {
    padding: 6px 20px 6px 10px !important;
    color: #d1d5db !important;
  }

  .ant-select-selector {
    padding: 6px 20px 6px 22px !important;
    border-radius: 10px !important;
    border-color: #d1d5db !important;
    min-height: 44px !important;
    height: max-content !important;

    &:hover {
      border-color: #eb7d17 !important;
    }

    &:focus {
      border-color: #eb7d17 !important;
      box-shadow: 0px 0px 14px 0px #ff00001a;
    }

    &::placeholder {
      color: #d1d5d8;
      font-weight: 400;
      font-size: 14px;
    }

    &.inlines {
      border: 1px solid #eb7d17 !important;
      border-radius: 8px;
      background: #fff;
    }
  }
  .ant-select-selection-search {
    margin-inline-start: 0;
    z-index: 2;
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }

  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column;
    .ant-select-selector {
      min-height: 32px !important;
      height: max-content !important;
    }
  }
`;
