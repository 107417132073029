import React, { useEffect, useState } from 'react';
import { Avatar, Modal, Space } from 'antd';
import styled from 'styled-components';

import { MetaButton } from 'app/components/Forms';
import { getFirstLetter } from 'utils/helpers';

export function ViewerModal({ isOpen, setIsOpen, data, currentUser }) {
  const [members, setMembers] = useState<
    { avatar: string; userID: string; nick: string }[]
  >([]);

  useEffect(() => {
    let members: { avatar: string; userID: string; nick: string }[] = [];
    // for (let i = 0; i <= 20; i++) {
    //   const randomName = Math.random().toString(36).substring(2, 7);
    //   members.push({
    //     avatar: '',
    //     userID: `${i + 1}`,
    //     nick: randomName,
    //   });
    // }

    if (data?.length >= 0) {
      setMembers([
        ...members,
        ...data?.filter(item => String(currentUser?.id) !== item?.userID),
      ]);
    }
  }, [data]);

  return (
    <ModalCustom
      centered
      title="Viewer List"
      open={isOpen}
      width={400}
      footer={
        <MetaButton
          type="primary"
          text="OK"
          onClick={() => setIsOpen(false)}
          style={{ marginLeft: 'auto' }}
        />
      }
      onCancel={() => setIsOpen(false)}
      bodyStyle={{ overflowY: 'auto', height: '200px' }}
    >
      <Space size={[12, 12]} direction="vertical" style={{ width: '100%' }}>
        {members?.map((t, i) => (
          <div className="d-box" key={i}>
            <Avatar
              shape="square"
              style={{
                minWidth: '48px',
                minHeight: '48px',
                background: t?.avatar ? 'transparent' : '',
                display: 'flex',
                alignItems: 'center',
                fontSize: '18px',
              }}
              src={t?.avatar ? t?.avatar : ''}
            >
              {getFirstLetter(t?.nick || '')}
            </Avatar>
            <p className="ellipsis-1">{t?.nick}</p>
          </div>
        ))}
      </Space>
    </ModalCustom>
  );
}

export const ModalCustom = styled(Modal)`
  .ant-modal-header {
    margin-bottom: 20px;
  }

  .ant-avatar {
    background: #eb7d17;
  }

  .d-box {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
  }

  .ant-modal-body {
    ::-webkit-scrollbar-track {
      background-color: #f3f4f6;
    }
    ::-webkit-scrollbar {
      width: 2px;
      background-color: #d9d9d9;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border: 1px solid #d9d9d9;
    }
  }
`;
