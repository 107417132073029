import { Carousel, Form, Image } from 'antd';
import React, { memo, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

import { MetaTitle } from 'app/components';
import { MetaButton, MetaInput } from 'app/components/Forms';
import {
  LoginBanner1,
  LoginBanner2,
  LoginBanner3,
  LoginBannerMobile1,
  LoginBannerMobile2,
  LoginBannerMobile3,
} from 'styles/image';
import { regexIcon, regexPassword } from 'utils/helpers';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { useAuthSlice } from '../slice';
import { selectAuth } from '../slice/selectors';
import { PATH } from 'app/constants/path';
import { resetPasswordRequest } from 'app/apis/auth';
import { actionsToast } from 'app/pages/ToastPage/slice';

export const ResetPassword = memo(() => {
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { actions } = useAuthSlice();
  const { isLoading } = useSelector(selectAuth);

  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const onFinish = async values => {
    try {
      const { newPassword, otp } = values;
      let formSubmit = {
        email: email,
        newPassword: newPassword,
        otp: otp,
      };

      const res: any = await resetPasswordRequest(formSubmit);
      if (res?.statusCode === 201) {
        dispatch(actions.resetPasswordSuccess(res));
        toast.success('Your password has been changed successfully');
        navigate(PATH.LOGIN);
      }
    } catch (err: any) {
      const { code, message } = err.response.data;
      if (code === 6) {
        dispatch(
          actionsToast.openToast({
            message: 'Code is expired. Resend code?',
            type: 'confirm',
            handleFunc: handleResendCode,
          }),
        );
      } else {
        dispatch(
          actionsToast.openToast({
            message: message,
            type: 'error',
          }),
        );
      }
    }
  };

  const handleResendCode = () => {
    dispatch(actions.forgotPassword({ email }));
  };

  useEffect(() => {
    if (!email) {
      navigate(PATH.FORGOT_PASSWORD);
    }
  }, [location]);

  return (
    <Wrapper>
      <MetaHelmet title="JYANNA WORLD" />
      <Box className="form-content">
        <MetaTitle text="Reset your password!" />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="form-login"
        >
          <MetaInput
            form={form}
            type="number"
            label="Code"
            subLabel={
              <span style={{ color: '#D1D5DB', fontSize: '12px' }}>
                Enter code you had received in your email!
              </span>
            }
            name="otp"
            hideArrow
            placeholder="Code"
            maxLength={6}
            minLength={6}
            isRequired
            rules={[
              { required: true, message: 'Please enter Code' },
              {
                pattern: /^[\d]{6}$/,
                message: 'The code must have 6 characters',
              },
              {
                pattern: regexIcon,
                message: 'Invalid character',
              },
            ]}
          />

          <MetaInput
            form={form}
            label="New Password"
            type="password"
            name="newPassword"
            placeholder="New Password"
            className="input-password"
            isRequired
            rules={[
              { required: true, message: 'Please enter New Password' },
              {
                pattern: regexPassword,
                message:
                  'Password must contain 8 to 32 characters, including upper and lower case letters, numbers and symbols.',
              },
              {
                pattern: regexIcon,
                message: 'Invalid character',
              },
            ]}
          />

          <MetaInput
            form={form}
            label="Confirm New password"
            type="password"
            name="confirmPassword"
            placeholder="Confirm password"
            className="input-password"
            isRequired
            rules={[
              { required: true, message: 'Please enter Confirm Password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password does not match'));
                },
              }),
            ]}
          />
          <Form.Item>
            <MetaButton
              type="primary"
              htmlType="submit"
              text="Reset password"
              is_loading={isLoading}
              style={{ marginTop: 10 }}
            />
          </Form.Item>
        </Form>
      </Box>
      <CarouseBox>
        <Carousel autoplay effect="fade" speed={2000} infinite>
          <Image
            src={isMobile ? LoginBannerMobile1 : LoginBanner1}
            alt="login_banner_1"
            preview={false}
          />
          <Image
            src={isMobile ? LoginBannerMobile2 : LoginBanner2}
            alt="login_banner_2"
            preview={false}
          />
          <Image
            src={isMobile ? LoginBannerMobile3 : LoginBanner3}
            alt="login_banner_3"
            preview={false}
          />
        </Carousel>
      </CarouseBox>
    </Wrapper>
  );
});

// css
const Wrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  display: grid;
  grid-template-columns: 37% calc(100% - 37%);
  justify-content: space-between;
  @media only screen and (max-width: 1440px) {
    grid-template-columns: 450px calc(100% - 450px);
  }
  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    overflow-x: hidden;
  }
  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .form-content {
    max-width: 400px;
    width: 100%;
    margin: auto;
    justify-content: flex-start;
    margin-top: 200px;
    height: max-content;
    @media only screen and (max-width: 1440px) {
      margin: auto;
    }
    @media only screen and (max-width: 703px) {
      max-width: 500px;
      background: #fff;
      padding: 31px 9px;
      border-radius: 20px;
      margin-top: 0px;
      h1 {
        line-height: 1.2;
        margin-bottom: 10px;
      }
      h4 {
        line-height: 1.4;
      }
    }
  }

  .form-login {
    margin-top: 40px;
    width: 100%;
    max-width: 328px;
    @media only screen and (max-width: 703px) {
      margin-top: 8px;
    }
  }

  .forgot-pass {
    text-align: center;
    margin-top: -10px;
    a {
      color: #eb7d17;
      font-size: 14px;
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .ant-btn-primary {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
  .ant-form-item-explain-error {
    width: fit-content;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CarouseBox = styled.div`
  @media only screen and (max-width: 1024px) {
    .ant-image {
      img {
        object-fit: cover;
      }
    }
  }
  @media only screen and (max-width: 703px) {
    height: 280px;
    width: 100%;
  }

  div {
    height: 100% !important;
  }
  .ant-carousel {
    height: 100dvh !important;

    @media only screen and (max-width: 703px) {
      height: 100% !important;
    }
  }
  .ant-carousel:focus-visible {
    outline: none;
  }
  .ant-carousel,
  .ant-carousel .slick-slider,
  .slick-list,
  .slick-track {
    height: 100dvh;
  }
  .slick-dots {
    align-items: center;
    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-inline: 10px;
      button {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    li.slick-active {
      width: 18px;
      height: 18px;
    }
  }
`;
