import { PATH } from 'app/constants/path';
import React, { FC } from 'react';

import { Button, Container } from './styles';

const ReloadPage: FC = () => {
  window.onload = (function(){
    console.log("page is fully loaded");
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('from')){
      let notifiText = document.getElementById('notificationtext');
      if(notifiText)
      {
        notifiText.innerText = "Jyanna広場 is already open, do you want to open a new one?";
      }
    }
  });
  function onRedirectUnity() {
    window.open(`${PATH.UNITY}`);
    window.close();
  }

  return (
    <Container>
      <p id='notificationtext' style={{textAlign: "center"}}>新しいタブが開かれたため、こちらのタブが無効になります</p>
      <br></br>
      <Button onClick={onRedirectUnity}>Open Jyanna広場</Button>
    </Container>
  );
};

export default ReloadPage;
