/* eslint-disable no-unused-expressions */
/* eslint-disable-line */
import React from 'react';
import TRTC from 'trtc-js-sdk';
import isMobile from 'is-mobile';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { SDKAPPID } from 'configs';
import RTCBeautyPlugin from 'rtc-beauty-plugin';
import { IS_ANDROID } from 'utils/environment';
import {
  isUndefined,
  joinRoomSuccessUpload,
  joinRoomFailedUpload,
  publishSuccessUpload,
  publishFailedUpload,
  initLocalStreamSuccessUpload,
  initLocalStreamFailedUpload,
} from 'utils/utils';
import { leaveRoomRequest } from 'app/apis/profile';
import { _log } from 'utils/_log';

const withRouter = WrappedComponent => props => {
  const navigate = useNavigate();
  // other hooks

  return <WrappedComponent {...props} {...{ navigate }} />;
};

class RTC extends React.Component<any> {
  [x: string]: any;

  constructor(props: any) {
    super(props);
    this.userStream = props.userStream;
    this.userID = props.userID;
    this.userSig = props.userSig;
    this.userName = props.userStream?.username;
    this.roomID = props.roomID;
    this.useStringRoomID = props.useStringRoomID;
    this.cameraID = props.cameraID;
    this.microphoneID = props.microphoneID;
    this.setState = props.setState;
    this.addUser = props.addUser;
    this.removeUser = props.removeUser;
    this.addStream = props.addStream;
    this.updateStream = props.updateStream;
    this.updateStreamConfig = props.updateStreamConfig;
    this.removeStream = props.removeStream;
    this.defaultMedia = props.defaultMedia;
    this.mode = props.mode;
    this.role = props.role;
    this.audio = props.audio;
    this.video = props.video;
    this.startRoom = props.startRoom;
    this.localStream = null;
    this.remoteStreamList = [];
    this.client = null;
    this.shareClient = null;
    this.isJoining = false;
    this.isJoined = props.isJoined || false;
    this.isPublished = props.isPublished || false;
    this.isPublishing = false;
    this.isUnPublishing = false;
    this.isLeaving = false;
    this.privateMapKey = 255;
    this.mirror = false;
    this.dom = null;
    this.isMySelf = props.isMySelf;
    this.stopRoom = props.stopRoom;
    this.setIsClientBannedError = props.setIsClientBannedError;
    this.setIsStreamEnded = props.setIsStreamEnded;

    // global.$TRTC = TRTC;
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(props) {
    if (this.userID !== props.userID) {
      this.userID = props.userID;
    }
    this.roomID = props.roomID;
    this.useStringRoomID = props.useStringRoomID;
    this.cameraID = props.cameraID;
    this.microphoneID = props.microphoneID;
    this.setState = props.setState;
    this.addStream = props.addStream;
    this.removeStream = props.removeStream;
    this.updateStream = props.updateStream;
    this.updateStreamConfig = props.updateStreamConfig;
    this.mode = props.mode;
    this.audio = props.audio;
    this.video = props.video;
  }

  async componentDidMount() {
    this.props.onRef(this);

    const checkResult = await TRTC.checkSystemRequirements();

    if (!checkResult.result) {
      alert(
        'The current browser does not support WebRTC SDK, please change to another browser',
      );
    }

    const that = this;

    window.addEventListener('beforeunload', event => {
      if (that.isJoined) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to close';
      }
    });
  }

  async componentWillUnmount() {
    this.handleLeave();
    if (this.roomID) {
      try {
        await leaveRoomRequest({ id: this.roomID });
      } catch (error) {
        _log('BaseRTC componentWillUnmount error: ', error, 'error');
      }
    }
  }

  async getUserSig() {
    this.privateMapKey = 255;
  }

  async hasFrontBack() {
    try {
      let result: any = { hasBack: [], hasFront: [], videoDevices: [] };

      const devices = await TRTC.getDevices();

      const videoDevices = devices.filter(device => {
        if (device.kind === 'videoinput') {
          if (device.label && device.label.length > 0) {
            if (device.label.toLowerCase().indexOf('back') >= 0) {
              result.hasBack.push(device.deviceId);
            } else if (device.label.toLowerCase().indexOf('front') >= 0) {
              result.hasFront.push(device.deviceId);
            } else {
              /* some other device label ... desktop browser? */
            }
          }

          return true;
        }

        return false;
      });

      result.videoDevices = videoDevices;

      /* drop stream */
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: false,
        })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop());
        })
        .catch(err => {
          toast.error(
            'カメラまたはマイクが使用中です。デバイスを確認してください',
          );
        });

      return result;
    } catch (error) {
      /* log and swallow exception, this is a probe only */
      throw error;
    }
  }

  async initClient() {
    this.client = TRTC.createClient({
      mode: this.mode,
      sdkAppId: SDKAPPID,
      userId: this.userID,
      userSig: this.userSig,
      // useStringRoomId: this.useStringRoomID,
      // userDefineRecordId: `${SDKAPPID}_${this.userID}`,
      // autoSubscribe: false,
      enableAutoPlayDialog: false,
    });

    this.handleClientEvents();
    return this.client;
  }

  async initLocalStream(facingMode) {
    try {
      // Check mobile live
      // if (isMobile()) {
      //   await this.localStream.setVideoProfile({ width: 640, height: 320 });
      // } else {
      //   await this.localStream.setVideoProfile({ width: 320, height: 640 });
      // }

      this.localStream = TRTC.createStream({
        audio: this.audio,
        video: this.video,
        userId: this.userID,
        cameraId: this.cameraID,
        microphoneId: this.microphoneID,
        facingMode: facingMode || 'user',
      });

      const listCamera: any = await this.hasFrontBack();

      let cameraId = this.cameraID;

      // Load camera android
      if (IS_ANDROID && listCamera.hasFront.length) {
        cameraId = listCamera.hasFront[0];
      }

      await this.localStream.initialize();

      this.addStream && this.addStream(this.localStream);

      initLocalStreamSuccessUpload(SDKAPPID);

      return this.localStream;
    } catch (error: any) {
      this.localStream = null;

      _log('initLocalStream error', error, 'error');

      toast.error('カメラやマイクがありません。デバイスを確認してください');

      initLocalStreamFailedUpload(SDKAPPID, `${JSON.stringify(error.message)}`);
    }
  }

  destroyLocalStream() {
    this.removeStream && this.removeStream(this.localStream);
    this.localStream && this.localStream.stop();
    this.localStream && this.localStream.close();
    // this.stopRoom(this.roomID);
    this.localStream = null;
    this.dom = null;
  }

  playStream(stream, dom) {
    if (
      stream.getType() === 'main' &&
      stream.getUserId().indexOf('share') >= 0
    ) {
      stream
        .play(dom, {
          objectFit: 'contain',
          mirror: false,
        })
        .catch(err => {
          _log('BaseRTC playStream error: ', err, 'error');
        });
    } else {
      stream
        ?.play(dom, {
          mirror: false,
        })
        .catch(err => {
          _log('BaseRTC playStream error 2: ', err, 'error');
        });
      if (stream === this.localStream) {
        this.dom = dom;
      }
    }
  }

  resumeStream(stream) {
    stream.resume();
  }

  async handleJoin() {
    if (this.isJoining || this.isJoined) {
      return;
    }
    this.isJoining = true;
    await this.initClient();

    try {
      await this.client.join({ roomId: +this.roomID, role: this.role });
      joinRoomSuccessUpload(SDKAPPID);
      this.isJoining = false;
      this.isJoined = true;
      this.setState && this.setState('join', this.isJoined);
      this.addUser && this.addUser(this.userID, 'local');
      this.startGetAudioLevel();
    } catch (error: any) {
      this.isJoining = false;
      toast.error('配信が失敗しました');
      _log('アクセスできません', error, 'error');
      joinRoomFailedUpload(SDKAPPID, `${JSON.stringify(error.message)}`);
      // this.stopRoom(this.roomID);
    }
  }

  async handlePublish(facingMode) {
    if (!this.isJoined || this.isPublishing || this.isPublished) {
      return;
    }

    try {
      this.isPublishing = true;

      !this.localStream && (await this.initLocalStream(facingMode));

      // this.beautyPlugin = new RTCBeautyPlugin();
      // this.beautyPlugin.setBeautyParam({
      //   beauty: 0,
      //   brightness: 0,
      //   ruddy: 0,
      // });
      // const stream = this.beautyPlugin.generateBeautyStream(this.localStream);
      await this.client.publish(this.localStream);

      publishSuccessUpload(SDKAPPID);

      this.isPublishing = false;
      this.isPublished = true;
      this.setState && this.setState('publish', this.isPublished);

      await this.startRoom(this.roomID);
    } catch (error: any) {
      this.isPublishing = false;
      _log('publish localStream failed', error, 'error');
      publishFailedUpload(SDKAPPID, `${JSON.stringify(error.message)}`);
      // this.stopRoom(this.roomID);
    }
  }

  async handleUnPublish() {
    if (!this.isPublished || this.isUnPublishing) {
      return;
    }
    this.isUnPublishing = true;
    try {
      await this.client.unpublish(this.localStream);

      this.isUnPublishing = false;
      this.isPublished = false;
      this.setState && this.setState('publish', this.isPublished);
    } catch (error: any) {
      this.isUnPublishing = false;
      _log('unpublish localStream failed', error, 'error');
    }
    this.localStream && (await this.destroyLocalStream());
  }

  async handleSubscribe(remoteStream, config = { audio: false, video: true }) {
    try {
      await this.client.subscribe(remoteStream, {
        audio: isUndefined(config.audio) ? true : config.audio,
        video: isUndefined(config.video) ? true : config.video,
      });
    } catch (error) {
      _log(
        `subscribe ${remoteStream.getUserId()} with audio: ${
          config.audio
        } video: ${config.video} error`,
        error,
        'error',
      );
      toast.error(`subscribe ${remoteStream.getUserId()} failed!`);
    }
  }

  async handleUnSubscribe(remoteStream) {
    try {
      await this.client.unsubscribe(remoteStream);
    } catch (error) {
      _log(`unsubscribe ${remoteStream.getUserId()} error`, error, 'error');
      toast.error(`unsubscribe ${remoteStream.getUserId()} failed!`);
    }
  }

  async handleLeave() {
    if (!this.isJoined || this.isLeaving) {
      return;
    }
    this.isLeaving = true;
    this.stopGetAudioLevel();

    if (this.isPublished) {
      await this.handleUnPublish();
    }

    try {
      await this.client.leave();
      this.role === 'anchor' && toast.info('配信が終了しました');

      this.removeUser && this.removeUser(this.userID, 'local');

      this.isMySelf && this.stopRoom(this.roomID);
      this.isLeaving = false;
      this.isJoined = false;
      this.setState && this.setState('join', this.isJoined);
    } catch (error) {
      this.isLeaving = false;
      _log('leave room error', error, 'error');
      // toast.error('leave room error');
    }
  }

  handleStartPublishCDNStream() {
    this.client.startPublishCDNStream();
  }

  handleSopPublishCDNStream() {
    this.client.handleSopPublishCDNStream();
  }

  handleStartMixTranscode(otherRoomID, otherRoomUserID) {
    const mixTranscodeConfig = {
      videoWidth: 1280,
      videoHeight: 480,
      videoBitrate: 1500,
      videoFramerate: 15,
      mixUsers: [
        {
          userId: this.userID,
          roomId: this.roomID, // roomId 字段自 v4.11.5 版本开始支持，支持跨房间混流
          pureAudio: false,
          width: 640,
          height: 480,
          locationX: 0,
          locationY: 0,
          streamType: 'main', // 指明该配置为远端主流
          zOrder: 1,
        },
        {
          userId: otherRoomUserID,
          roomId: otherRoomID, // roomId 字段自 v4.11.5 版本开始支持，支持跨房间混流
          pureAudio: false,
          width: 640,
          height: 480,
          locationX: 640,
          locationY: 0,
          streamType: 'main', // 指明该配置为远端辅流
          zOrder: 1,
        },
      ],
    };
    this.client.startMixTranscode(mixTranscodeConfig);
  }

  handleStopMixTranscode() {
    this.client.stopMixTranscode();
  }

  muteVideo() {
    this.localStream && this.localStream.muteVideo();
  }

  muteAudio() {
    this.localStream && this.localStream.muteAudio();
  }

  unmuteVideo() {
    this.localStream && this.localStream.unmuteVideo();
  }

  unmuteAudio() {
    this.localStream && this.localStream.unmuteAudio();
  }

  startGetAudioLevel() {
    // 文档：https://web.sdk.qcloud.com/trtc/webrtc/doc/zh-cn/module-ClientEvent.html#.AUDIO_VOLUME
    this.client.on('audio-volume', event => {
      event.result.forEach(({ userId, audioVolume }) => {
        if (audioVolume > 2) {
          // console.log(
          //   `user: ${userId} is speaking, audioVolume: ${audioVolume}`,
          // );
          this.updateStreamConfig &&
            this.updateStreamConfig(userId, 'audio-volume', audioVolume);
        } else {
          this.updateStreamConfig &&
            this.updateStreamConfig(userId, 'audio-volume', 0);
        }
      });
    });
    this.client.enableAudioVolumeEvaluation(200);
  }

  stopGetAudioLevel() {
    this.client && this.client.enableAudioVolumeEvaluation(-1);
  }

  handleStreamEvents(stream) {
    stream.on('error', error => {
      const errorCode = error.getCode();
      if (errorCode === 0x4043) {
        // PLAY_NOT_ALLOWED,引导用户手势操作并调用 stream.resume 恢复音视频播放
        this.updateStreamConfig &&
          this.updateStreamConfig(stream.getUserId(), 'resume-stream');
      }
    });
  }

  handleClientEvents() {
    this.client.on('error', error => {
      _log('errorrrrrrrrrr', error, 'error');
      // alert(`error${error}`);
    });

    this.client.on('client-banned', async event => {
      this.setIsClientBannedError(true);
      this.isPublished = false;
      this.localStream = null;
      this.setState && this.setState('publish', this.isPublished);
      await this.handleLeave();
    });
    // fired when a remote peer is joining the room
    this.client.on('peer-join', event => {
      const { userId } = event;
      // console.log(`peer-joinssssssss ${userId}`, event);
      this.addUser && this.addUser(userId);
    });
    // fired when a remote peer is leaving the room
    this.client.on('peer-leave', async event => {
      const { userId } = event;

      // console.log(`peer-leave ${userId}`);

      if (this.roomID) {
        try {
          await leaveRoomRequest({ id: this.roomID });
        } catch (error) {
          console.log(error);
        }
      }

      this.removeUser && this.removeUser(userId);
    });

    // fired when a remote stream is added
    this.client.on('stream-added', event => {
      const { stream: remoteStream } = event;
      const remoteUserID = remoteStream.getUserId();
      if (remoteUserID === `share_${this.userID}`) {
        // don't need screen shared by us
        this.handleUnSubscribe(remoteStream);
      } else {
        // console.log(
        //   `remote stream added: [${remoteUserID}] type: ${remoteStream.getType()}`,
        // );
        // subscribe to this remote stream
        this.handleSubscribe(remoteStream);
        this.addStream && this.addStream(remoteStream);
      }
    });
    // fired when a remote stream has been subscribed
    this.client.on('stream-subscribed', event => {
      const { stream: remoteStream } = event;
      // console.log('stream-subscribed userId: ', remoteStream.getUserId());
    });
    // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
    this.client.on('stream-removed', event => {
      const { stream: remoteStream } = event;
      remoteStream.stop();
      this.removeStream && this.removeStream(remoteStream);
      // toast.info('This live stream has end');
      this.setIsStreamEnded(true);
      // console.log(
      //   `stream-removed userId: ${remoteStream.getUserId()} type: ${remoteStream.getType()}`,
      // );
    });

    this.client.on('stream-updated', event => {
      const { stream: remoteStream } = event;
      this.updateStream && this.updateStream(remoteStream);

      // console.log(
      //   `type: ${remoteStream.getType()} stream-updated hasAudio: ${remoteStream.hasAudio()} hasVideo: ${remoteStream.hasVideo()}`,
      // );
    });

    this.client.on('mute-audio', event => {
      const { userId } = event;
      // console.log(`${userId} mute audio`);
      this.updateStreamConfig && this.updateStreamConfig(userId, 'mute-audio');
    });
    this.client.on('unmute-audio', event => {
      const { userId } = event;
      // console.log(`${userId} unmute audio`);
      this.updateStreamConfig &&
        this.updateStreamConfig(userId, 'unmute-audio');
    });
    this.client.on('mute-video', event => {
      const { userId } = event;
      // console.log(`${userId} mute video`);
      this.updateStreamConfig && this.updateStreamConfig(userId, 'mute-video');
    });
    this.client.on('unmute-video', event => {
      const { userId } = event;
      // console.log(`${userId} unmute video`);
      this.updateStreamConfig &&
        this.updateStreamConfig(userId, 'unmute-video');
    });

    this.client.on('connection-state-changed', event => {
      // console.log(
      //   `RtcClient state changed to ${event.state} from ${event.prevState}`,
      // );
    });

    this.client.on('network-quality', event => {
      const { uplinkNetworkQuality, downlinkNetworkQuality } = event;
      // console.log(
      //   `network-quality uplinkNetworkQuality: ${uplinkNetworkQuality}, downlinkNetworkQuality: ${downlinkNetworkQuality}`,
      //   event,
      // );
      this.updateStreamConfig &&
        this.updateStreamConfig(
          this.userID,
          'uplink-network-quality',
          uplinkNetworkQuality,
        );
      this.updateStreamConfig &&
        this.updateStreamConfig(
          this.userID,
          'downlink-network-quality',
          downlinkNetworkQuality,
        );
    });
  }

  render() {
    return <div style={{ width: 0, height: 0 }}></div>;
  }
}

export default withRouter(RTC);
