import { Modal } from 'antd';
import { MetaButton } from 'app/components/Forms';
import styled from 'styled-components';

// css
export const Wrapper = styled.div<any>`
  padding: ${props => (props?.isMySelf ? '40px 20px' : '40px 113px')};
  overflow-x: hidden;
  display: grid;
  grid-template-columns: ${props =>
    props?.isTheater ? `1fr` : props.isMySelf ? '1354px 1fr' : `1fr 565px`};
  justify-content: space-between;
  gap: 30px;
  @media only screen and (max-width: 1729px) {
    padding: 20px;
    grid-template-columns: ${props =>
      props?.isTheater ? `1fr` : props.isMySelf ? '1354px 1fr' : `66% 1fr`};
    justify-content: center;
  }
  @media only screen and (max-width: 1024px) {
    padding: 10px;
    gap: 10px;
  }
  @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
    position: absolute;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    padding: 5px;
    /* padding-bottom: 23px; */
  }
  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;

export const ModalContainer = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  &.ant-modal {
    width: 580px !important;
  }
  .modal-title {
    color: #1f2937;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
  }
  .ant-modal-content {
    padding: 60px 120px;
    width: 100%;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto !important;
    border-radius: 20px;
    @media only screen and (max-width: 703px) {
      padding: 60px 31px;
    }
  }
  .ant-modal-header {
    margin: 0;
  }
  .ant-modal-footer {
    width: 100%;
    margin-top: 0px;
  }
  .ant-modal-body {
    text-align: center;
    width: 100%;
  }
`;

export const ModalAlarmLineContainer = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  &.ant-modal {
    width: 403px !important;
  }
  .modal-title {
    color: #1f2937;
    text-align: center;
    font-size: 25px;
    font-weight: 700;

    @media only screen and (max-width: 703px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 420px) {
      font-size: 20px;
    }
  }
  .ant-modal-content {
    padding: 40px 25px;
    width: 100%;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto !important;
    border-radius: 20px;
    @media only screen and (max-width: 703px) {
      padding: 60px 25px;
    }
  }
  .ant-modal-header {
    margin: 0;
  }
  .popup-header {
    margin-bottom: 20px;
  }
  .ant-modal-footer {
    width: 100%;
    margin-top: 0px;
  }
  .ant-modal-body {
    text-align: center;
    width: 100%;
  }
  .modal-content {
    font-size: 14px;
    color: #9ca3af;

    span {
      margin: 0 5px;
      font-size: 18px;
      font-weight: 700;
      color: #eb7d17;
    }
  }
`;

export const FooterCustom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const CustomMetaButton = styled(MetaButton)<any>`
  height: 52px !important;
  width: 100% !important;
  font-size: 18px;
  &.ant-btn-primary {
    background-color: #eb7d17;
    &:hover {
      opacity: 0.8;
      background-color: #eb7d17;
    }
  }

  &.btn-cancel {
    border-color: transparent;
    color: #9ca3af;
    background-color: #f3f4f6;
    &:hover {
      border-color: transparent !important;
      color: #9ca3af !important;
      opacity: 0.8;
      background-color: #f3f4f6 !important;
    }
  }
`;

export const ErrorIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const ErrorClientBannedModalContainer = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  &.ant-modal {
    width: 580px !important;
  }
  .modal-title {
    color: #1f2937;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
  }
  .ant-modal-content {
    padding: 0 20px;
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto !important;
    border-radius: 20px;
    @media only screen and (max-width: 703px) {
      padding: 60px 31px;
    }
  }
  .ant-modal-header {
    margin: 0;
  }
  .ant-modal-footer {
    width: 100%;
    margin-top: 0px;
  }
  .ant-modal-body {
    text-align: center;
    width: 100%;
  }
`;
