export const PATH = {
  HOME: '/',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  REGISTER_VERIFICATION: '/auth/regis-verification',
  LIST_STREAM: '/list-stream',
  STREAM: '/stream',
  STREAM_DASH: '/stream/',
  STREAM_VIEWER: '/stream/:id',
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/edit',
  PROFILE_PERSON: '/profile/:id',
  UNITY: '/unity',
  OBS_STREAM: '/obs-stream',
  OBS_VIEWER: '/obs-viewer',

  ERROR_404: '/error-404',
  ERROR_500: '/error-500',
  ERROR_503: '/error-503',
  PAGE_NOT_FOUND: '*',

  RELOAD: '/reload',
  PAYMENT_SUCCESSFUL: '/payment-success',
};
