import React, { useState } from 'react';
import { Col, Image, Pagination, Row } from 'antd';
import { MetaLoading } from 'app/components';
import moment from 'moment';
import styled from 'styled-components';

import { NoData, StarIcon } from 'styles/image';
import { MetaButton } from 'app/components/Forms';
import GetPoint from 'app/pages/Point/GetPoint';
import { DISPLAY_DATETIME_FORMAT } from 'app/constants';

export const PAGE_SIZE = 15;

const BalanceLists = ({
  currentUser,
  activities,
  isLoading,
  setIsLoading,
  fetchPointActivity,
  currentPage,
  setCurrentPage,
  sumOfActivity,
}) => {
  const [showPointPopup, setShowPointPopup] = useState<boolean>(false);

  const onChangePage = (page, _pageSize) => {
    setIsLoading(true);
    setCurrentPage(page);
    fetchPointActivity({ page: page });
  };

  return (
    <Wrapper>
      <MetaLoading loading={isLoading} />

      <div className="balance-box">
        <div className="balance-text">
          <span>Point</span>
          <div>
            <span className="ellipsis-1">
              {new Intl.NumberFormat().format(currentUser?.point)}
            </span>
          </div>
        </div>

        <div className="balance-btn-Purchase">
          <MetaButton
            type="primary"
            // text="Purchase"
            text="ポイント購入"
            className="btn-edit"
            icon={<img src={StarIcon} alt="star-icon" />}
            onClick={() => setShowPointPopup(true)}
          />
          <p>ポイント決済の名義はグループ会社のZept合同会社となります</p>
          <p
            onClick={() => window.open('http://jyanna.info/legalnoticel/')}
            style={{
              color: '#eb7d17',
              textDecoration: 'underline',
              cursor: 'pointer',
              textUnderlineOffset: '4px',
            }}
          >
            特定商取引法に基づく表記
          </p>
        </div>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <div className="table-container">
          <Row className="row-header">
            <Col xs={4} sm={4}>
              {/* Event */}
              項目
            </Col>
            <Col xs={6} sm={6}>
              {/* Point */}
              ポイント
            </Col>
            <Col xs={10} sm={10}>
              {/* Product */}
              製品
            </Col>
            <Col xs={4} sm={4}>
              {/* Date */}
              日付
            </Col>
          </Row>

          <div className="row-body">
            {activities && activities?.length > 0 ? (
              activities?.map((activity, idx) => (
                <Row className="row-body-content" key={idx}>
                  <Col xs={4} sm={4}>
                    {activity?.event}
                  </Col>

                  <Col xs={6} sm={6}>
                    <strong style={{ color: '#EB7D17' }}>
                      {new Intl.NumberFormat().format(activity?.point)}
                    </strong>
                  </Col>

                  <Col xs={10} sm={10}>
                    <span style={{ color: '#1F2937' }}>{activity?.name}</span>
                  </Col>

                  <Col xs={4} sm={4}>
                    <span style={{ color: '#9CA3AF' }}>
                      {moment(activity?.created_at).format(
                        DISPLAY_DATETIME_FORMAT,
                      )}
                    </span>
                  </Col>
                </Row>
              ))
            ) : (
              <div className="nodata">
                <Image
                  width={335}
                  height={247}
                  preview={false}
                  src={NoData}
                  style={{
                    objectFit: 'cover',
                  }}
                  alt=""
                />

                <p
                  style={{
                    color: '#D1D5DB',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginTop: '15px',
                  }}
                >
                  Don’t have any record now!
                </p>
              </div>
            )}
          </div>

          {activities && activities?.length > 0 && (
            <Pagination
              defaultCurrent={1}
              showSizeChanger={false}
              current={currentPage}
              total={sumOfActivity}
              pageSize={PAGE_SIZE}
              onChange={onChangePage}
            />
          )}
        </div>
      </div>

      {showPointPopup && <GetPoint setShowPointPopup={setShowPointPopup} />}
    </Wrapper>
  );
};

export default BalanceLists;

export const Wrapper = styled.div`
  .search-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #d1d5db;
    padding: 10px 0px;
    .ant-space {
      width: 100%;
      justify-content: flex-end;
    }
    .search-content {
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      display: grid;
      grid-template-columns: 63px 1fr;
    }
    .ant-input-affix-wrapper {
      height: 44px;
      border-radius: 10px;
      padding-left: 20px;
      max-width: 520px;
      &:focus,
      &:hover {
        border-color: #eb7d17;
        box-shadow: none;
      }
    }

    .ant-input-suffix {
      span {
        color: #000;
        font-size: 20px;
      }
    }
  }

  .table-container {
    border: 1px solid #9ca3af;
    border-radius: 10px;
    background: #f3f4f6;
    padding: 17px 20px 39px 17px;
    @media only screen and (max-width: 703px) {
      padding: 7px 20px 20px 17px;
      width: 703px;
    }
    .row-header {
      border-bottom: 1px solid #d1d5db;
      padding: 12px 0px;
      padding-top: 0px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
      color: #9ca3af;
      text-align: center;
    }
    .row-body {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .row-body-content {
        color: #9ca3af;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        background: #fff;
        border-radius: 8px;
        padding: 10px 0px;
      }
    }
  }

  .ant-pagination .ant-pagination-item a {
    font-weight: 400;
    font-size: 16px;
  }
  .ant-pagination .ant-pagination-item-active {
    border-color: #eb7d17;
  }
  .ant-pagination .ant-pagination-item-active a {
    color: #fff;
    background: #eb7d17;
  }
`;
