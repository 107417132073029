import { sendDonate } from 'app/apis/gift';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';

export const useDonationTab = ({
  currentUser,
  giftData,
  userStream,
  roomID,
  onCloseModal,
  giftTypeData,
}) => {
  const isResponsive = useMediaQuery({ maxWidth: '1400px' });
  const isMobile = useMediaQuery({ maxWidth: '640px' });

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    giftTypeData[0]?.id,
  );

  const [giftCategoryId, setGiftCategoryId] = useState();

  const [listGif, setListGif] = useState<any>([]);

  const [giftSelectedData, setGiftSelectedData] = useState<any>({});

  const [isPopupGiftConfirmShow, setIsPopupGiftConfirmShow] = useState(false);

  const [isPopupBuyPointsShow, setIsPopupBuyPointsShow] = useState(false);

  const [showPointPopup, setShowPointPopup] = useState(false);

  const chunkSize = isResponsive ? 2 : 4;

  const [currentTab, setCurrentTab] = useState(0);

  const [currentElements, setCurrentElements] = useState<any>([]);

  const handleChangeGiftType = key => {
    setSelectedCategoryId(key);
    setGiftCategoryId(key);
    setGiftSelectedData(0);
    const data = filter(giftData, item => item?.category_id === key);
    setListGif(data);
  };

  const handleSelectGift = data => {
    setGiftSelectedData(data);
    data.id && setIsPopupGiftConfirmShow(true);
  };

  const closePopupConfirmSendGift = () => {
    setIsPopupGiftConfirmShow(false);
  };

  const openPopupConfirmSendGift = () => {
    giftSelectedData.id && setIsPopupGiftConfirmShow(true);
  };

  const onClosePopupConfirmBuyPoints = () => {
    setIsPopupBuyPointsShow(false);
  };

  const onConfirmBuyPoints = () => {
    setIsPopupBuyPointsShow(false);
    setIsPopupGiftConfirmShow(false);
    setTimeout(() => {
      setShowPointPopup(true);
    }, 500);
  };

  const handleSendGift = async () => {
    try {
      const res: any = await sendDonate({
        receiver_id: userStream.id,
        room_id: parseInt(roomID),
        gift_id: giftSelectedData.id,
        quantity: 1,
      });

      if (res && res.statusCode !== 201) {
        toast.error('Send gift fail');
      }

      isMobile && setIsPopupGiftConfirmShow(false);

      setTimeout(() => {
        const element: any = document.getElementById('chat');
        element?.scrollTo(0, element.scrollHeight + 1000);
      }, 700);

      onCloseModal && onCloseModal();
    } catch (error) {
      setIsPopupGiftConfirmShow(true);

      setIsPopupBuyPointsShow(true);
    }
  };

  const getNextTab = () => {
    const nextTab = currentTab + 1;
    setCurrentTab(nextTab);
  };

  const getPreviousTab = () => {
    const previousTab = currentTab - 1;
    setCurrentTab(previousTab);
  };

  useEffect(() => {
    setSelectedCategoryId(giftTypeData[0]?.id);
  }, [giftTypeData]);

  useEffect(() => {
    const startIndex = currentTab * chunkSize;

    const endIndex = startIndex + chunkSize;

    const elements = giftTypeData?.slice(startIndex, endIndex);

    setCurrentElements(elements);
  }, [giftTypeData, currentTab, chunkSize]);

  useEffect(() => {
    const data = filter(
      giftData,
      item => item?.category_id === selectedCategoryId,
    );

    setGiftCategoryId(selectedCategoryId);

    setListGif(data);
  }, [giftData]);

  return {
    currentElements,
    giftCategoryId,
    handleChangeGiftType,
    getPreviousTab,
    getNextTab,
    currentTab,
    chunkSize,
    listGif,
    handleSelectGift,
    giftSelectedData,
    openPopupConfirmSendGift,
    isPopupGiftConfirmShow,
    isPopupBuyPointsShow,
    showPointPopup,
    closePopupConfirmSendGift,
    handleSendGift,
    onClosePopupConfirmBuyPoints,
    onConfirmBuyPoints,
    setShowPointPopup,
  };
};
