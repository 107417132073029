import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const MetaLoading = ({ loading }) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 70,
        color: '#EB7D17',
      }}
      spin
    />
  );

  return (
    <>
      {loading && (
        <div className="loading-data-wrapper">
          <Spin indicator={antIcon} />
        </div>
      )}
    </>
  );
};
