import { MetaButton } from 'app/components/Forms';
import styled from 'styled-components';
import { Modal } from 'antd';
import { breakpoints } from 'utils/media-breakpoint';

export const InformationBox = styled.div<any>`
  width: 100%;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: ${props =>
    props?.isMySelf ? 'unset' : 'min-content 72px'};
  justify-content: ${props =>
    props?.isMySelf ? 'space-between' : 'flex-start'};
  column-gap: 12px;
  row-gap: 12px;

  @media only screen and (min-width: ${props => props.breakpoints.desktop}) {
    grid-template-columns: 180px 1fr;
    column-gap: 27px;
    row-gap: 0;
    grid-template-rows: unset;
  }

  .ant-image-img {
    border-radius: 20px;
  }

  h5 {
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #d1d5db;
    margin-top: 26px;
    margin-bottom: 5px;
  }
  .env-box {
    text-align: right;
    .ant-tag {
      font-size: 12px;
      font-weight: 400;
      padding: 2px 18px;
      border-radius: 16px;
    }
  }
  .description-content {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 50% 1fr;
    gap: 10px;
    align-items: start;
    margin-bottom: 5px;
    /* @media only screen and (max-width: 1729px) {
      grid-template-columns: 1fr;
    } */
  }
  .description-box {
    h2 {
      font-size: 28px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #9ca3af;
    }
    .button-box {
      display: flex;
      gap: 20px;
      margin: 10px auto;
    }
  }

  .de-info {
    background-color: #f3f4f6;
    padding: 10px;
    border-radius: 7px;
    height: 76px;

    .ant-typography {
      max-height: 56px;
      overflow-y: scroll;
      margin-bottom: 0px;
      color: #9ca3af;
      word-break: break-word;

      ::-webkit-scrollbar-track {
        background-color: #f3f4f6;
      }

      ::-webkit-scrollbar {
        width: 2px;
        background-color: #d9d9d9;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border: 1px solid #d9d9d9;
      }
    }
  }

  .suggestion-contain {
    h5 {
      margin: 0px;
    }
    .suggestion-box {
      margin-top: 20px;
      h5 {
        margin: 4px 0px 0px;
        border: none;
      }
      span {
        color: #9ca3af;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .ant-avatar {
    background: #eb7d17;
    font-size: 60px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 20px;
    margin: auto;
  }

  .custom-avatar {
    font-size: 60px !important;
    width: 120px;
    height: 120px;

    @media only screen and (min-width: ${props => props.breakpoints.desktop}) {
      width: 180px;
      height: 180px;
    }
  }

  .ant-avatar-image {
    background: transparent;
  }

  .host-info {
    display: flex;
    width: 100%;
  }
`;

export const IconMySelf = styled.div<{
  breakpoints: typeof breakpoints;
  isLocalStream: boolean;
  isMuted: boolean;
}>`
  background-color: ${props =>
    props?.isLocalStream
      ? props?.isMuted
        ? '#F3F4F6'
        : 'rgb(235, 125, 23)'
      : 'rgba(235, 125, 23, 0.6)'};
  padding: 17px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 44px;
  height: 44px;
  justify-content: center;
  cursor: ${props => (props?.isLocalStream ? 'pointer' : 'not-allowed')};

  @media only screen and (min-width: ${props =>
      props.breakpoints.largeScreen}) {
    width: 56px;
    height: 56px;
  }

  &:hover {
    opacity: ${props => (props?.isLocalStream ? '0.8' : '1')};
  }
`;

export const IsViewListIcon = styled.div<{
  breakpoints: typeof breakpoints;
  isLocalStream: boolean;
}>`
  background-color: rgb(235, 125, 23);
  padding: 17px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 44px;
  height: 44px;
  justify-content: center;
  cursor: pointer;

  @media only screen and (min-width: ${props =>
      props.breakpoints.largeScreen}) {
    width: 56px;
    height: 56px;
  }

  &:hover {
    opacity: ${props => (props?.isLocalStream ? '0.8' : '1')};
  }
`;

export const ButtonStart = styled(MetaButton)<{
  breakpoints: typeof breakpoints;
}>`
  width: fit-content;
  height: 44px !important;
  color: #9ca3af;

  @media only screen and (min-width: ${props => props.breakpoints.mobile}) {
    width: 240px;
  }

  @media only screen and (min-width: ${props =>
      props.breakpoints.largeScreen}) {
    width: 328px;
    height: 56px !important;
  }
`;

export const FooterCustom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const CustomMetaButton = styled(MetaButton)<any>`
  height: 52px !important;
  width: 100% !important;
  font-size: 18px;
  &.ant-btn-primary {
    background-color: #eb7d17;
    &:hover {
      opacity: 0.8;
      background-color: #eb7d17;
    }
  }

  &.btn-cancel {
    border-color: transparent;
    color: #9ca3af;
    background-color: #f3f4f6;
    &:hover {
      border-color: transparent !important;
      color: #9ca3af !important;
      opacity: 0.8;
      background-color: #f3f4f6 !important;
    }
  }
`;

export const ModalContainer = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  &.ant-modal {
    width: 580px !important;
  }
  .modal-title {
    color: #1f2937;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
  }
  .ant-modal-content {
    padding: 60px 80px;
    width: 100%;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto !important;
    border-radius: 20px;
    @media only screen and (max-width: 703px) {
      padding: 60px 31px;
    }
  }
  .ant-modal-header {
    margin: 0;
  }
  .ant-modal-footer {
    width: 100%;
    margin-top: 0px;
  }
  .ant-modal-body {
    text-align: center;
    width: 100%;

    span {
      font-size: 18px;
      font-weight: 700;
      color: #eb7d17;
    }
  }
`;
