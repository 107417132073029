import React, { memo, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Tooltip, Typography } from 'antd';
import { ExpandOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import {
  AudioOffIcon,
  AudioOnIcon,
  HeartIcon,
  ViewIcon,
  TheateredIcon,
  TheaterIcon,
  GiftBox,
  ErrorIcon,
} from 'styles/image';
import { MetaLoading } from 'app/components/MetaLoading';
import { IS_IPAD, IS_IPHONE, IS_IOS } from 'utils/constants';
import { setFullscreen, exitFullscreen } from 'utils/utils';
import useInitViewer from '../hooks/useInitViewer';
import { configObsViewer, eventVideoPlayState } from '../hooks/configObsViewer';
import ChatGroup from 'app/pages/StreamPage/components/ChatGroup';
import BigDonateNotification from 'app/pages/StreamPage/components/LiveScreen/components/BigDonateNotification';
import { useLiveScreen } from 'app/pages/StreamPage/components/LiveScreen/hooks';
import { selectAuth } from '../../AuthPage/slice/selectors';
import { selectProfile } from 'app/pages/Profile/slice/selectors';
import ChatRTCViewer from 'app/components/ChatRTCViewer';
import InformationDetail from 'app/pages/StreamPage/components/InformationDetail';
import ChatInput from 'app/pages/StreamPage/components/ChatGroup/ChatInput';
import DonationModal from 'app/pages/StreamPage/components/ChatGroup/DonationModal';
import ChatTabMobile from 'app/pages/StreamPage/components/ChatGroup/ChatTabMobile';
import { MetaButton } from 'app/components/Forms';
import { useInformationDetail } from 'app/pages/StreamPage/components/InformationDetail/hooks';
import {
  Wrapper,
  ErrorClientBannedModalContainer,
  ErrorIconContainer,
} from './viewer-styles';

interface IMyProps {
  isLandScaped: boolean;
}

export const ObsLiveStreamViewerPage = memo(({ isLandScaped }: IMyProps) => {
  const [messageLists, setMessageLists] = useState<any>([]);
  const [inputMessage, setInputMessage] = useState<any>('');
  const [receiveStatus, setReceiveStatus] = useState(false);
  const [chatRTC, setChatRTC] = useState<any>(null);
  const [isOpenViewerModal, setIsOpenViewerModal] = useState(false);
  const [isMuteAudio, setIsMuteAudio] = useState(false);
  const [isTheater, setIsTheater] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const [openModalDonation, setOpenModalDonation] = useState(false);
  const [isClientBannedError, setIsClientBannedError] = useState(false);
  const [isVerticalScreen, setIsVerticalScreen] = useState(false);
  const [eventPlayObs, setEventPlayObs] = useState('');
  const [isEleVideo, setIsEleVideo] = useState(false);
  const sendButton = useRef<any>(null);
  const childRef = useRef<any>(null);
  const refItem = useRef<any>();
  const timeoutRef = useRef<any>(null);

  const isMobile = useMediaQuery({ maxWidth: '703px' });

  const dispatch = useDispatch();
  const { currentPerson } = useSelector(selectProfile);

  const {
    roomID,
    liked,
    totalOfLike,
    totalOfViewer,
    isClosedRoom,
    isLoading,
    currentUser,
    handleLike,
  } = useInitViewer();

  const {
    donateMessage,
    imageDonateNotificationRef,
    confettiEffectRef,
    imagePosition,
    audioRef,
    effectSoundUrl,
  } = useLiveScreen({
    localStreamConfig: null,
    roomID,
  });

  const { isFollow, handleFollowed, currentTotalFollowers } =
    useInformationDetail({
      userId: currentPerson?.id,
      userStreamTotalFollowers: currentPerson?.total_followers,
    });

  const { joinRoom, handleChangeMuteRemoteAudio, LeaveRoom } =
    configObsViewer();

  const handleChangeAudio = () => {
    setIsMuteAudio(!isMuteAudio);
    handleChangeMuteRemoteAudio(!isMuteAudio, roomID);
  };

  function handleToggleIpadFullScreen() {
    const elem = document.querySelector('#viewer');

    if (!elem) {
      return;
    }

    if (!document.fullscreenElement) {
      elem
        .requestFullscreen()
        .then(() => {
          //    console.log('request fullscreen success');
        })
        .catch((err: any) => {
          console.error(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
    } else {
      document.exitFullscreen();
    }
  }

  const handleChange = () => {
    if (!isFull) {
      setFullscreen(refItem.current);
    } else {
      exitFullscreen();
    }
    setIsFull(!isFull);
  };

  const handleContainerVideo = () => {
    if (IS_IPHONE || IS_IPAD || IS_IOS) {
      const videoOrigin: HTMLCanvasElement | null =
        childRef?.current?.getElementsByTagName(
          'canvas',
        )[0] as HTMLCanvasElement;
      if (videoOrigin) {
        setIsEleVideo(true);
        const width = videoOrigin.width;
        const height = videoOrigin.height;
        setIsVerticalScreen(height > width);
      }
    } else {
      const videoOrigin: HTMLVideoElement | null =
        childRef?.current?.getElementsByTagName('video')[0] as HTMLVideoElement;

      if (videoOrigin) {
        setIsEleVideo(true);
        const width = videoOrigin.videoWidth;
        const height = videoOrigin.videoHeight;
        setIsVerticalScreen(height > width);
      }
    }
  };

  useEffect(() => {
    if (isClosedRoom) {
      LeaveRoom();
    }
  }, [isClosedRoom]);

  useEffect(() => {
    if (eventPlayObs === 'PLAYING') {
      timeoutRef.current = setTimeout(() => {
        handleContainerVideo();
      }, 1000);
    }
    if (eventPlayObs === 'STOPPED') {
      setIsEleVideo(false);
    }
  }, [eventPlayObs]);

  useEffect(() => {
    if (roomID && currentUser) {
      joinRoom(roomID, currentUser);
    }
  }, [roomID, currentUser]);

  useEffect(() => {
    eventVideoPlayState(setEventPlayObs);
    return () => {
      handleChangeMuteRemoteAudio(false, roomID);
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <MetaLoading loading={isLoading} />

      <Wrapper
        isTheater={isTheater}
        isFull={isFull}
        isVerticalScreen={isVerticalScreen}
        isLandScaped={isLandScaped}
      >
        <div className="block-left">
          <div className="obs-video">
            <div
              id="viewer"
              ref={refItem}
              style={
                isVerticalScreen
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      background: '#000',
                      borderRadius: '20px',
                    }
                  : {}
              }
            >
              {!isClosedRoom && (
                <>
                  <div
                    id="trtcPlayer"
                    className="video-container"
                    ref={childRef}
                    style={
                      isVerticalScreen
                        ? {
                            width: 'auto',
                            height: 'calc(100% - 1px)',
                            display: isEleVideo ? 'block' : 'none',
                          }
                        : { display: isEleVideo ? 'block' : 'none' }
                    }
                  />
                  {donateMessage}

                  <BigDonateNotification
                    imgRef={imageDonateNotificationRef}
                    confettiEffectRef={confettiEffectRef}
                    imagePosition={imagePosition}
                    isTheater={isTheater}
                    isMySelf={false}
                  />

                  <div
                    className="drop-heart"
                    onClick={handleLike}
                    style={{
                      background: !liked ? '#fff' : '#eb7d17',
                    }}
                  >
                    <Image
                      src={HeartIcon}
                      preview={false}
                      width={22}
                      height={18}
                      style={{
                        filter: !liked
                          ? 'invert(49%) sepia(100%) saturate(460%) hue-rotate(349deg) brightness(96%) contrast(92%)'
                          : '',
                      }}
                    />
                  </div>

                  <div className="view-info-container">
                    <div className="view-info">
                      <p className="live-text">
                        <span />
                        LIVE
                      </p>
                      <p>
                        <Image
                          src={HeartIcon}
                          alt="heart-icon"
                          preview={false}
                          width={16}
                          height={13}
                        />

                        {totalOfLike}
                      </p>

                      <p>
                        <Image
                          src={ViewIcon}
                          alt="view-icon"
                          preview={false}
                          width={16}
                          height={11}
                        />

                        {totalOfViewer}
                      </p>
                    </div>

                    <div className="settings-container">
                      <Tooltip
                        title={isMuteAudio ? 'Unmute Audio' : 'Mute Audio'}
                        arrow
                      >
                        <Image
                          src={isMuteAudio ? AudioOnIcon : AudioOffIcon}
                          alt="eye-icon"
                          preview={false}
                          width={24}
                          height={24}
                          onClick={handleChangeAudio}
                        />
                      </Tooltip>
                      {!isLandScaped && !isMobile && (
                        <>
                          <Image
                            src={isTheater ? TheaterIcon : TheateredIcon}
                            alt="eye-icon"
                            preview={false}
                            width={20}
                            height={18}
                            onClick={e => setIsTheater(!isTheater)}
                          />
                          <ExpandOutlined
                            onClick={e => {
                              if (!IS_IPAD) {
                                handleChange();
                              } else {
                                handleToggleIpadFullScreen();
                              }
                            }}
                          />
                        </>
                      )}
                      {IS_IPHONE ? (
                        <></>
                      ) : (
                        (isMobile || isLandScaped) && (
                          <ExpandOutlined
                            onClick={e => {
                              if (!IS_IPAD) {
                                handleChange();
                              } else {
                                handleToggleIpadFullScreen();
                              }
                            }}
                          />
                        )
                      )}
                    </div>
                  </div>

                  {isMobile && <div className="shadow-orange-top" />}

                  {isMobile && (
                    <div className="user-infor">
                      <Typography.Title level={2} className="ellipsis-1">
                        {currentPerson?.username}
                      </Typography.Title>

                      <div>
                        <MetaButton
                          type={isFollow ? 'primary' : 'normal'}
                          // text={isFollow ? 'Followed' : 'Follow'}
                          text={isFollow ? 'フォロー済み' : 'フォローする'}
                          onClick={() => handleFollowed(isFollow)}
                          style={{
                            width: '80px',
                            height: '20px',
                            paddingTop: !isFollow ? '2px' : '5px',
                            fontSize: '12px',
                          }}
                        />
                        <p style={{ textAlign: 'center', fontSize: '12px' }}>
                          {currentTotalFollowers}
                          <span style={{ marginLeft: 3 }}>
                            {/* {currentTotalFollowers && currentTotalFollowers > 1
                            ? 'followers'
                            : 'follower'} */}
                            {currentTotalFollowers && currentTotalFollowers > 1
                              ? 'フォロワー'
                              : 'フォロワー'}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  {isMobile && (
                    <>
                      <>
                        <div
                          className="drop-heart drop-gift"
                          style={{
                            background: '#eb7d17',
                          }}
                          onClick={() => {
                            setOpenModalDonation(true);
                          }}
                        >
                          <Image src={GiftBox} preview={false} />
                        </div>

                        <DonationModal
                          isModalOpen={openModalDonation}
                          roomID={roomID}
                          currentUser={currentUser}
                          userStream={currentPerson}
                          onCloseModal={() => {
                            setOpenModalDonation(false);
                          }}
                        />
                      </>
                      <ChatTabMobile
                        isMySelf={false}
                        messageLists={messageLists}
                        currentUser={currentUser}
                      />
                    </>
                  )}
                  <div className="shadow-orange" />
                </>
              )}
            </div>
          </div>
          {!isLandScaped && !isTheater && !isMobile && (
            <InformationDetail
              localStreamConfig={{}}
              isTheater={isTheater}
              userStream={currentPerson}
              onChange={() => {}}
              handleJoin={() => {}}
              handleLeave={() => {}}
              isMySelf={false}
              setIsOpenViewerModal={setIsOpenViewerModal}
              setMessageLists={setMessageLists}
              roomID={roomID}
            />
          )}
        </div>

        {!isLandScaped && (
          <ChatGroup
            isTheater={isTheater}
            chatRTC={chatRTC}
            userStream={currentPerson}
            currentUser={currentUser}
            messageLists={messageLists}
            setMessageLists={setMessageLists}
            setInputMessage={setInputMessage}
            handleLocalChange={() => {}}
            sendButton={sendButton}
            inputMessage={inputMessage}
            localStreamConfig={{}}
            handleJoin={() => {}}
            handleLeave={() => {}}
            isMySelf={false}
            receiveStatus={receiveStatus}
            setReceiveStatus={setReceiveStatus}
            dispatch={dispatch}
            roomID={roomID}
            isDisabled={!roomID}
          />
        )}
        {currentUser && !isClosedRoom && (
          <ChatRTCViewer
            currentUser={currentUser}
            groupID={roomID}
            setMessageLists={setMessageLists}
            inputMessage={inputMessage}
            sendButton={sendButton}
            setReceiveStatus={setReceiveStatus}
            setIsClientBannedError={setIsClientBannedError}
          />
        )}

        {isMobile && (
          <div className="bottom">
            <ChatInput
              dispatch={dispatch}
              sendButton={sendButton}
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              isDisabled={!roomID}
              scrollBottom={() => {
                setTimeout(() => {
                  const element: any = document.getElementById('chat');
                  element?.scrollTo(0, element.scrollHeight + 1000);
                }, 700);
              }}
            />
          </div>
        )}

        {/* Modal when join one room in multiple tabs  */}
        <ErrorClientBannedModalContainer
          open={isClientBannedError}
          title=""
          centered
          wrapClassName="modal-rotate-screen-wrapper"
          footer={null}
        >
          <ErrorIconContainer>
            <Image
              width={36}
              height={36}
              src={ErrorIcon}
              alt="error-icon"
              preview={false}
            />
          </ErrorIconContainer>

          <p className="modal-title">View livestream</p>

          <Typography.Title
            level={5}
            style={{ textAlign: 'center', marginTop: '8px' }}
          >
            You can not use in multiple window. Please close this window.
          </Typography.Title>
        </ErrorClientBannedModalContainer>

        {effectSoundUrl && (
          <audio
            controls
            ref={audioRef}
            preload="none"
            style={{
              display: 'none',
            }}
          >
            <source src={effectSoundUrl} type="audio/mpeg" />
          </audio>
        )}
      </Wrapper>
    </>
  );
});
