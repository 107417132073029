import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { getAllUserStreamingRequest } from 'app/apis/profile';
import { PATH } from 'app/constants/path';
import { useMqtt } from 'app/hooks';

export const SORT = {
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc',
  },
  BY: {
    FOLLOWER: 'total_followers',
    TIME: 'start_time',
    VIEWER: 'current_viewer',
    LIKE: 'current_like',
  },
};

const useInit = ({
  addEventListener,
  removeEventListener,
  sendMessage,
}: {
  addEventListener?: any;
  removeEventListener?: any;
  sendMessage?: any;
}) => {
  const PAGE_SIZE = 15;

  let isStarted = 0; //0-false , 1-true

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [streamLists, setStreamLists] = useState<any>([]);

  const [streamTotal, setStreamTotal] = useState<any>(0);

  const [currentPage, setCurrentPage] = useState(1);

  const isMobile = useMediaQuery({ maxWidth: '640px' });
  const navigate = useNavigate();

  const [values, setValues] = useState({
    page: currentPage,
    limit: PAGE_SIZE,
    search: '',
    order_by: '',
    order_direction: SORT.DIRECTION.DESC,
    onlyFollower: false,
  });

  useMqtt({
    topicName: `room/info/#`,
    dependencies: [values],
    callback: message => onReceiveStreamList(message),
  });

  useMqtt({
    topicName: `room/status/#`,
    dependencies: [values],
    callback: message => onReceiveStreamList(message),
  });

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    sendMessage('Manager', 'HideReactDialog', 'popup_name');

    setIsModalOpen(false);
  };

  const onChange: any = e => {
    const { value } = e.target;
    setValues({ ...values, search: value });
  };

  const onPressEnter = e => {
    fetchStreamList(values);
  };

  const fetchStreamList = values => {
    setIsLoading(true);
    getAllUserStreamingRequest(values)
      .then(res => {
        if (res) {
          const { data } = res;

          setStreamLists(data?.rows);
          setStreamTotal(data?.count);

          setIsLoading(false);
        }
      })
      .catch(err => {
        setStreamLists([]);
        setStreamTotal(0);
        setIsLoading(false);
        console.log(err);
      });
  };

  const onChangePage = (page, _pageSize) => {
    setIsLoading(true);
    setCurrentPage(page);
    fetchStreamList({ ...values, page: page });
  };

  function onReceiveStreamList(message: Buffer) {
    try {
      fetchStreamList(values);
    } catch (error) {
      toast.error('配信のリストが取得できません');
    }
  }

  const handleShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSendStream = useCallback(isStart => {
    isStarted = isStart;
  }, []);

  const onStreamRedirect = item => {
    // if (isMobile) {
    //   navigate(`${PATH.STREAM}/${item.owner.username}`);
    //   navigate(0);
    // } else {
    //   window.open(`${PATH.STREAM}/${item.owner.username}`);
    // }
    if (item.type === '1') {
      window.open(`${PATH.OBS_VIEWER}?username=${item.owner.username}`);
      return;
    }
    window.open(`${PATH.STREAM}/${item.owner.username}`);
  };

  const onRedirectPageLive = () => {
    // if (isMobile) {
    //   navigate(PATH.STREAM);
    //   navigate(0);
    // } else {
    //   window.open(PATH.STREAM);
    // }
    window.open(PATH.STREAM);
  };

  useEffect(() => {
    addEventListener &&
      addEventListener('DisplayListUserAvatar', handleShowModal);
    return () => {
      removeEventListener &&
        removeEventListener('DisplayListUserAvatar', handleShowModal);
    };
  }, [addEventListener, removeEventListener, handleShowModal]);

  useEffect(() => {
    addEventListener &&
      addEventListener('StartListenListLivestream', handleSendStream);
    return () => {
      removeEventListener &&
        removeEventListener('StartListenListLivestream', handleSendStream);
    };
  }, [addEventListener, removeEventListener, handleSendStream]);

  useEffect(() => {
    if (isModalOpen) {
      fetchStreamList(values);
    }
  }, [isModalOpen, values]);

  return {
    setIsLoading,
    setValues,
    fetchStreamList,
    values,
    isLoading,
    isModalOpen,
    handleCancel,
    handleOk,
    onChange,
    onPressEnter,
    streamLists,
    onStreamRedirect,
    onRedirectPageLive,
    streamTotal,
    PAGE_SIZE,
    currentPage,
    onChangePage,
    setIsModalOpen,
  };
};

export default useInit;
