import Axios from 'app/apis';

const personRequest = ({ id }) => {
  return Axios.get(`/users/user?id=${id}`);
};

const updateUserRequest = (data: any) => {
  return Axios.put(`/users/me`, data);
};

const followRequest = (data: any) => {
  return Axios.post('/follower/follow', data);
};

const unFollowRequest = (data: any) => {
  return Axios.post('/follower/unfollow', data);
};

const followerListRequest = ({ id }) => {
  return Axios.get(`/follower/followers/${id}`);
};

const getLabelRequest = () => {
  return Axios.get(`/labels`);
};

const followingListRequest = ({ id }) => {
  return Axios.get(`/follower/followings/${id}`);
};

const avatarsListRequest = () => {
  return Axios.get('/users/get-avatars');
};

const changeAvatarRequest = data => {
  return Axios.put('/users/change-avatar', data);
};

const getAllUserStreamingRequest = ({
  page,
  limit,
  search,
  order_by,
  order_direction,
  onlyFollower,
}) => {
  return Axios.get(
    `/rooms/list?page=${page}&limit=${limit}&search=${search}&order_by=${order_by}&order_direction=${order_direction}&onlyFollower=${onlyFollower}`,
  );
};

const getUserSigByID = () => {
  return Axios.get(`/users/userSig`);
};

const getRoomsRequest = () => {
  return Axios.get(`/rooms`);
};

const getMyRoomsRequest = () => {
  return Axios.get(`/rooms/me`);
};

const postRoomInitialRequest = (typeObs = true) => {
  return Axios.post('/rooms/initial', { public: typeObs });
};

const initialObsRequest = id => {
  return Axios.get(`/rooms/initial-obs/${id}`);
};

const postRoomStartRequest = data => {
  return Axios.post(`/rooms/start`, data);
};

const joinRoomRequest = data => {
  return Axios.post(`/rooms/join?id=${data?.id}`);
};

const settingViewLivePointRequest = (data: any) => {
  return Axios.put(`rooms/set-point`, data);
};

const submitPointJoinRoomRequest = data => {
  return Axios.post('/point/decrease', data);
};

const leaveRoomRequest = data => {
  return Axios.post(`/rooms/leave?id=${data?.id}`);
};

const postRoomPauseRequest = data => {
  return Axios.post(`/rooms/pause`, data);
};

const postRoomCloseRequest = data => {
  return Axios.post(`/rooms/close`, data);
};

const putIdVideoTagRequest = data => {
  return Axios.put(
    `/rooms/update?room_id=${data?.room_id}&div_id=${data?.divId}&audio_id=${data?.audioId}&video_id=${data?.videoId}`,
  );
};

const checkLikeRequest = data => {
  return Axios.get(`/reaction/isLike?room_id=${data?.id}`);
};

const checkUserViewLiveRequest = id => {
  return Axios.get(`/rooms/allowance/${id}`);
};

const getRoomLink = data => {
  return Axios.post(`/rooms/link`, data);
};

const getDetailRoomRequest = data => {
  return Axios.get(`/rooms/room?id=${data?.id}`);
};

const getTotalDonationRequest = data => {
  return Axios.get(`/rooms/total-donation/${data?.id}`);
};

const likeRequest = (data: any) => {
  return Axios.put(`/reaction/like?room_id=${data?.id}`);
};

const unLikeRequest = (data: any) => {
  return Axios.put(`/reaction/unlike?room_id=${data?.id}`);
};

const avatarItemRequest = (data: any) => {
  return Axios.patch(`/avatar-items`, data);
};

const getRoomIDUnity = (data: any) => {
  return Axios.post(`/rooms/unity`, data);
};

const searchUserRequest = ({
  page,
  limit,
  username,
}: {
  page: number;
  limit: number;
  username: string;
}) => {
  return Axios.get(
    `/users/search?page=${page}&limit=${limit}&username=${username}`,
  );
};

export {
  personRequest,
  followRequest,
  unFollowRequest,
  followerListRequest,
  followingListRequest,
  updateUserRequest,
  getLabelRequest,
  getRoomsRequest,
  getMyRoomsRequest,
  postRoomInitialRequest,
  initialObsRequest,
  putIdVideoTagRequest,
  postRoomStartRequest,
  joinRoomRequest,
  submitPointJoinRoomRequest,
  leaveRoomRequest,
  postRoomPauseRequest,
  postRoomCloseRequest,
  checkLikeRequest,
  getDetailRoomRequest,
  likeRequest,
  getAllUserStreamingRequest,
  unLikeRequest,
  getUserSigByID,
  avatarItemRequest,
  getRoomLink,
  getRoomIDUnity,
  searchUserRequest,
  settingViewLivePointRequest,
  checkUserViewLiveRequest,
  getTotalDonationRequest,
  avatarsListRequest,
  changeAvatarRequest,
};
