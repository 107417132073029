import styled from 'styled-components';
import { breakpoints } from 'utils/media-breakpoint';

export const InformationBox = styled.div<{
  isTheater: boolean;
  isMySelf: boolean;
  isFull: boolean;
  isMobileLive: boolean;
  breakpoints: typeof breakpoints;
}>`
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: ${props => (props?.isMySelf ? '9px' : '30px')};
  height: ${props =>
    props?.isTheater
      ? 'max-content'
      : 'calc(100vh - 88.83px - 80px)'}; // fallback for browsers not supporting dvh units
  height: ${props =>
    props?.isTheater
      ? 'max-content'
      : 'calc(100dvh - 88.83px - 80px)'}; // 60: header, 80: padding top + bottom

  @media screen and (max-width: 1729px) {
    height: ${props =>
      props?.isTheater
        ? 'max-content'
        : 'calc(100vh - 88.83px - 40px)'}; // fallback for browsers not supporting dvh units
    height: ${props =>
      props?.isTheater
        ? 'max-content'
        : 'calc(100dvh - 88.83px - 40px)'}; // 60: header, 40: padding top + bottom
  }

  @media only screen and (min-width: ${props =>
      props.breakpoints.largeScreen}) {
    gap: ${props => (props?.isMySelf ? '21px' : '30px')};
  }

  @media only screen and (max-width: 1024px) {
    height: 100%;
    gap: 10px;
  }

  .live-contain {
    box-shadow: 0px 0px 20px 0px #eb7d1757;
    border: 1px solid #eb7d17;
    width: 100%;
    position: relative;
    border-radius: 30px;
    height: ${props => (props?.isTheater ? 'unset' : '100%')};
    padding: ${props =>
      props?.isTheater ? '0' : props?.isMySelf ? '0px 39px' : '5px'};
    background-color: #fff;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      inset: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 1;
      border-width: ${props =>
        props?.isTheater ? '0' : props?.isMySelf ? '0' : '5px'};
      border-style: solid;
      border-color: #ffffff;
      border-radius: inherit;
    }

    @media only screen and (min-width: ${props => props.breakpoints.desktop}) {
      padding: ${props =>
        props?.isTheater ? '0' : props?.isMySelf ? '0px 39px' : '10px'};

      &::before {
        border-width: ${props =>
          props?.isTheater ? '0' : props?.isMySelf ? '0px 39px' : '10px'};
      }
    }

    @media only screen and (max-width: 1024px) {
      height: ${props =>
        props.isTheater
          ? props?.isMySelf
            ? '100%'
            : '560px'
          : props?.isMySelf
          ? '100%'
          : '389px'};
    }
    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      height: 100%;
      border-radius: 25px;
      padding: ${props => (props?.isMySelf ? '0' : '3px')};
    }

    @media only screen and (max-width: ${props => props.breakpoints.tablet}) {
      height: 100%;
      border-radius: 20px;
      padding: ${props => (props?.isMySelf ? '0' : '6px')};
    }

    .live-contain-screen {
      height: 100%;
      width: 100%;
      margin: auto;
      overflow: hidden;
      border-radius: ${props =>
        props.isTheater ? '0px' : props?.isMySelf ? '0px' : '30px'};
      position: relative;
      max-width: ${props =>
        props?.isMySelf ? '100%' : props.isTheater ? '70%' : '100%'};

      @media only screen and (max-width: 1729px) {
        border-radius: 0px;
      }
    }
  }

  .ant-message {
    position: absolute;
    left: 0 !important;
    transform: translateX(0) !important;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: start;
    z-index: 1;

    .ant-message-notice,
    .ant-message-notice-wrapper {
      text-align: left !important;

      transition: all 0.3s;
    }

    .ant-message-notice {
      transition: all 0.3s;
    }

    .ant-message-notice:last-child,
    .ant-message-notice-wrapper:last-child {
      .ant-message-notice-content {
        background-color: #4f4f4f73;
      }

      .donate-message-heading {
        color: #eb7d17;
      }

      .donate-message-content {
        color: var(--gray-colors-white, #fff);
      }
    }

    .anticon.anticon-check-circle {
      display: none !important;
    }

    .ant-message-notice-content {
      padding: 10px 20px;
      border-radius: 0px 16px 16px 16px;
      background-color: #4f4f4f73;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      transition: all 0.2s;
    }

    .donate-message-box {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .donate-message-gif-image {
      width: 35px;
      height: 35px;
    }

    .donate-message {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .donate-message-heading {
      color: #eb7d17;
      font-family: Helvetica Neue;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 160% */

      margin-top: 0;
      margin-bottom: 0;

      transition: all 0.2s;
    }

    .donate-message-content {
      color: #fff;
      font-family: Helvetica Neue;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 85.714% */

      transition: all 0.2s;
    }
  }

  .bottom {
    position: relative;

    .ant-input,
    textarea {
      border-color: #d1d5db;
      height: 44px !important;

      &:hover {
        border-color: #d1d5db;
      }

      &:focus {
        border-color: #d1d5db;
        box-shadow: none;
      }
    }

    textarea {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 25px;

      ::-webkit-scrollbar {
        width: 0px;
      }
    }

    .ant-input-group-addon {
      cursor: pointer;
      background-color: #fff;

      &:hover {
        opacity: 0.7;
      }
    }

    .ant-input-group-addon:first-child {
      border-radius: 10px 0px 0px 10px;
      width: 41px;
    }

    .ant-input-group-addon:last-child {
      border-radius: 0px 10px 10px 0px;
      border-left: none;
    }

    & > span {
      bottom: 50% !important;
      transform: translateY(50%) !important;
      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
        right: 10px !important;
      }
    }
  }

  .chat-container-mobile-box {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin-bottom: 60px;
    margin-left: 15px;
    z-index: 999;
    width: 74vw;
    .chat-container-mobile {
      max-height: 200px;
      height: 100%;
      padding-right: 5px;
      scroll-behavior: smooth;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      -webkit-transform: translateZ(0);
      -webkit-overflow-scrolling: touch;
      ::-webkit-scrollbar {
        width: 2px;
      }
      .top {
        /* max-height: 200px;
        padding-right: 5px; */
        /* -webkit-overflow-scrolling: touch; */
        /* -webkit-transform: translateZ(0); */
      }
      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      }

      @keyframes fadeOut {
        0% {
          opacity: 1;
        } /* Thời điểm bắt đầu: độ mờ là 1 */
        100% {
          opacity: 0;
        } /* Thời điểm kết thúc: độ mờ là 0 */
      }

      .is-show {
        animation: fadeOut 1s ease-in-out forwards;
      }

      .box-content {
        margin-bottom: 4px;
        background-color: #4f4f4f2e;
        border-radius: 16px 16px 16px 0px;
        padding: 5px 10px;

        .box-content-name {
          display: flex;
          justify-content: space-between;
          color: #fff;

          .time-text {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            color: #fff;
          }

          h5,
          span {
            margin-bottom: 0;
          }
        }

        .message-detail {
          div {
            font-size: 12px;
            color: #fff;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }

      /* our  */
      .box-content.my-message {
        background-color: #4f4f4f2e;
        border-radius: 16px 16px 16px 0px;
        padding: 5px 10px;

        .box-content-name {
          display: flex;
          justify-content: space-between;
          color: #fff;

          .time-text {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            color: #fff;
          }
        }

        .message-detail {
          div {
            font-size: 12px;
            color: #fff;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
    }
  }
`;

export const DonateHistoryButton = styled.div`
  background-color: rgb(235, 125, 23);
  padding: 17px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  width: 44px;
  height: 44px;
  justify-content: center;
  cursor: pointer;

  position: absolute;
  bottom: 64px;
  right: 15px;
  z-index: 1;

  &:hover {
    opacity: 0.8;
  }
`;
