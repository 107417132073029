import { useState, useEffect } from 'react';

import { gifts } from 'app/apis/gift';
import { useMqtt } from 'app/hooks';

export const useDonation = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [giftTypeData, setGiftTypeData] = useState([]);

  const [giftData, setGiftData] = useState([]);

  const [giftTypeDataMqtt, setGiftTypeDataMqtt] = useState([]);

  const [giftDataMqtt, setGiftDataMqtt] = useState([]);

  useMqtt({
    topicName: 'gift/list',
    callback: onReceiveCategoriesList,
  });

  function onReceiveCategoriesList(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const data = JSON.parse(msg?.data);

      setGiftTypeDataMqtt(data?.category);

      setGiftDataMqtt(data?.rows);
    } catch (error) {}
  }

  const fetchDataGifts = async () => {
    setIsLoading(true);
    try {
      const resGifts: any = await gifts([]);

      if (resGifts) {
        setGiftTypeData(resGifts?.data?.category);
        setGiftData(resGifts?.data?.rows);
      }

      setIsLoading(false);
    } catch (err: any) {
      setGiftTypeData([]);
      setGiftData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataGifts();
  }, []);

  useEffect(() => {
    if (giftTypeDataMqtt) {
      setGiftTypeData(giftTypeDataMqtt);
    }

    if (giftDataMqtt) {
      setGiftData(giftDataMqtt);
    }
  }, [giftTypeDataMqtt, giftDataMqtt]);

  return {
    isLoading,
    giftTypeData,
    giftData,
  };
};
