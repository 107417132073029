import Axios from 'app/apis';

const uploadImageRequest = (file: any, type: string) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', type);

  return Axios.post('/common/upload-image', formData, {
    headers: {
      'Content-Type':
        'multipart/form-data; boundary=----WebKitFormBoundaryxxxxxxxx',
    },
  });
};

export { uploadImageRequest };
