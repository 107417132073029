import { toast } from 'react-toastify';

import { SDKAPPID } from 'configs';

const trtc = window.TRTC.create();

trtc.on(window.TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, ({ userId, streamType }) => {
  trtc.startRemoteVideo({ userId, streamType, view: 'trtcPlayer' });
});

export function eventVideoPlayState(setStatusObs) {
  trtc.on(window.TRTC.EVENT.VIDEO_PLAY_STATE_CHANGED, event => {
    setStatusObs(event.state);
    if (event.state === 'PAUSED') {
      toast.info('This live stream has end');
    }
  });
}

export function configObsViewer() {
  const joinRoom = async function (roomId, currentUser) {
    await trtc.exitRoom();
    await trtc.enterRoom({
      strRoomId: roomId.toString(),
      sdkAppId: SDKAPPID,
      userId: currentUser.id.toString(),
      userSig: currentUser.user_sign,
      autoReceiveAudio: false,
    });
  };

  const handleChangeMuteRemoteAudio = async (isMuteAudio, roomId) => {
    await trtc.muteRemoteAudio('*', !isMuteAudio);
  };

  const LeaveRoom = async function () {
    await trtc.exitRoom();
  };

  return {
    joinRoom,
    handleChangeMuteRemoteAudio,
    LeaveRoom,
  };
}
