import Confetti from 'react-confetti';
import styled from 'styled-components';
import { breakpoints } from 'utils/media-breakpoint';
import { ImagePosition } from '.';

export const Container = styled('div')<{
  imagePosition: ImagePosition;
  breakpoints: typeof breakpoints;
}>`
  position: absolute;
  inset: 0;

  & > .image-donate {
    z-index: 9;
    object-fit: cover;
    position: absolute;
    width: ${props => (props.imagePosition ? `auto` : 0)};
    height: ${props => (props.imagePosition ? `auto` : 0)};
    max-width: 100%;
    /* max-height: 100%; */
    border-radius: 8px;

    ${props =>
      getPositionImageMobile({ position: props.imagePosition, isShow: false })}

    transition: all 0.2s ease;

    @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
      ${props =>
        getPositionImage({ position: props.imagePosition, isShow: false })}
    }

    &.show {
      ${props =>
        getPositionImageMobile({ position: props.imagePosition, isShow: true })}

      @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
        ${props =>
          getPositionImage({ position: props.imagePosition, isShow: true })}
      }
    }

    &.hide {
      ${props =>
        getPositionImageMobile({
          position: props.imagePosition,
          isShow: false,
        })}

      @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
        ${props =>
          getPositionImage({ position: props.imagePosition, isShow: false })}
      }
    }
  }
`;

export const ConfettiStyled = styled(Confetti)<{
  isTheater: boolean;
  isMySelf: boolean;
  breakpoints: typeof breakpoints;
}>`
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;

  padding: ${props =>
    props?.isTheater ? '0' : props?.isMySelf ? '0px 39px' : '5px'};

  transition: all 0.3s;

  @media only screen and (min-width: ${props => props.breakpoints.desktop}) {
    padding: ${props =>
      props?.isTheater ? '0' : props?.isMySelf ? '0px 39px' : '10px'};
  }

  @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
    padding: ${props => (props?.isMySelf ? '0' : '3px')};
  }

  @media only screen and (max-width: ${props => props.breakpoints.tablet}) {
    padding: ${props => (props?.isMySelf ? '0' : '6px')};
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.hide {
    opacity: 0;
    visibility: hidden;
  }
`;

function getPositionImage({
  position,
  isShow,
}: {
  position: ImagePosition;
  isShow: boolean;
}) {
  const positionMap = new Map([
    [
      'TOP_CENTER',
      'top: 1%; left: 50%; transform: translate(-50%, 0) scale(0);',
    ],
    [
      'BOTTOM_CENTER',
      'bottom: 6%; left: 50%; transform: translate(-50%, 0) scale(0);',
    ],
    [
      'CENTER',
      'top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0);',
    ],
    ['TOP_RIGHT', 'top: 1%; right: 3%; transform: translate(0, 0) scale(0);'],
    [
      'BOTTOM_RIGHT',
      'bottom: 18%; right: 3%; transform: translate(0, 0) scale(0);',
    ],
    [
      'BOTTOM_LEFT',
      'bottom: 18%; left: 3%; transform: translate(0, 0) scale(0);',
    ],
  ]);

  const positionMapIsShow = new Map([
    [
      'TOP_CENTER',
      'top: 1%; left: 50%; transform: translate(-50%, 0) scale(1);',
    ],
    [
      'BOTTOM_CENTER',
      'bottom: 6%; left: 50%; transform: translate(-50%, 0) scale(1);',
    ],
    [
      'CENTER',
      'top: 50%; left: 50%; transform: translate(-50%, -50%) scale(1);',
    ],
    ['TOP_RIGHT', 'top: 1%; right: 3%; transform: translate(0, 0) scale(1);'],
    [
      'BOTTOM_RIGHT',
      'bottom: 18%; right: 3%; transform: translate(0, 0) scale(1);',
    ],
    [
      'BOTTOM_LEFT',
      'bottom: 18%; left: 3%; transform: translate(0, 0) scale(1);',
    ],
  ]);

  if (isShow) {
    return positionMapIsShow.get(position);
  }

  return positionMap.get(position);
}

function getPositionImageMobile({
  position,
  isShow,
}: {
  position: ImagePosition;
  isShow: boolean;
}) {
  const positionMap = new Map([
    [
      'TOP_CENTER',
      'top: 1%; left: 50%; transform: translate(-50%, 0) scale(0);',
    ],
    [
      'BOTTOM_CENTER',
      'bottom: 6%; left: 50%; transform: translate(-50%, 0) scale(0);',
    ],
    [
      'CENTER',
      'top: 50%; left: 50%; transform: translate(-50%, -50%) scale(0);',
    ],
    ['TOP_RIGHT', 'top: 1%; right: 3%; transform: translate(0, 0) scale(0);'],
    [
      'BOTTOM_RIGHT',
      'bottom: 22%; right: 3%; transform: translate(0, 0) scale(0);',
    ],
    [
      'BOTTOM_LEFT',
      'bottom: 22%; left: 3%; transform: translate(0, 0) scale(0);',
    ],
  ]);

  const positionMapIsShow = new Map([
    [
      'TOP_CENTER',
      'top: 1%; left: 50%; transform: translate(-50%, 0) scale(1);',
    ],
    [
      'BOTTOM_CENTER',
      'bottom: 6%; left: 50%; transform: translate(-50%, 0) scale(1);',
    ],
    [
      'CENTER',
      'top: 50%; left: 50%; transform: translate(-50%, -50%) scale(1);',
    ],
    ['TOP_RIGHT', 'top: 1%; right: 3%; transform: translate(0, 0) scale(1);'],
    [
      'BOTTOM_RIGHT',
      'bottom: 22%; right: 3%; transform: translate(0, 0) scale(1);',
    ],
    [
      'BOTTOM_LEFT',
      'bottom: 22%; left: 3%; transform: translate(0, 0) scale(1);',
    ],
  ]);

  if (isShow) {
    return positionMapIsShow.get(position);
  }

  return positionMap.get(position);
}
