import { Carousel, Form, Image } from 'antd';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';

import { MetaTitle } from 'app/components';
import { MetaButton, MetaInput } from 'app/components/Forms';
import {
  LoginBanner1,
  LoginBanner2,
  LoginBanner3,
  LoginBannerMobile1,
  LoginBannerMobile2,
  LoginBannerMobile3,
} from 'styles/image';
import { Link } from 'react-router-dom';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { useAuthSlice } from '../slice';
import { selectAuth } from '../slice/selectors';
import { regexEmail, regexIcon } from 'utils/helpers';

export const ForgotPassword = memo(() => {
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { actions } = useAuthSlice();
  const { isLoading } = useSelector(selectAuth);

  const onFinish = values => {
    dispatch(actions.forgotPassword(values));
  };

  return (
    <Wrapper>
      <MetaHelmet title="JYANNA WORLD" />
      <Box className="form-content">
        <MetaTitle
          text={`Forgot ${isMobile ? '\n' : ''}your password?`}
          subText={`Don’t worry! - We’ll email you instruction to reset ${
            isMobile ? '\n' : ''
          }your password.`}
        />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="form-login"
        >
          <MetaInput
            isNormalize
            name="email"
            label="Email address"
            placeholder="Enter Email address"
            isRequired
            rules={[
              { required: true, message: 'Please enter Email address' },
              {
                pattern: regexEmail,
                message: 'Invalid Email address',
              },
              {
                pattern: regexIcon,
                message: 'Invalid character',
              },
            ]}
            form={form}
          />
          <Form.Item>
            <MetaButton
              type="primary"
              htmlType="submit"
              text="Send email"
              is_loading={isLoading}
              style={{ marginTop: 10 }}
            />
          </Form.Item>

          <div className="forgot-pass">
            <Link to="/auth/login">Back to Sign in</Link>
          </div>
        </Form>
      </Box>
      <CarouseBox>
        <Carousel autoplay effect="fade" speed={2000} infinite>
          <Image
            src={isMobile ? LoginBannerMobile1 : LoginBanner1}
            alt="login_banner_1"
            preview={false}
          />
          <Image
            src={isMobile ? LoginBannerMobile2 : LoginBanner2}
            alt="login_banner_2"
            preview={false}
          />
          <Image
            src={isMobile ? LoginBannerMobile3 : LoginBanner3}
            alt="login_banner_3"
            preview={false}
          />
        </Carousel>
      </CarouseBox>
    </Wrapper>
  );
});

// css
const Wrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  display: grid;
  grid-template-columns: 37% calc(100% - 37%);
  justify-content: space-between;
  @media only screen and (max-width: 1440px) {
    grid-template-columns: 450px calc(100% - 450px);
  }
  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    overflow-x: hidden;
  }
  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .form-content {
    max-width: 400px;
    width: 100%;
    margin: auto;
    justify-content: flex-start;
    margin-top: 200px;
    height: max-content;
    @media only screen and (max-width: 1440px) {
      margin: auto;
    }
    @media only screen and (max-width: 703px) {
      max-width: 500px;
      background: #fff;
      padding: 31px;
      border-radius: 20px;
      margin-top: 0px;
      h1 {
        line-height: 1.2;
        margin-bottom: 10px;
      }
      h4 {
        line-height: 1.4;
      }
    }
  }

  .form-login {
    margin-top: 40px;
    width: 100%;
    max-width: 328px;
    @media only screen and (max-width: 703px) {
      margin-top: 8px;
    }
  }

  .forgot-pass {
    text-align: center;
    margin-top: -10px;
    a {
      color: #eb7d17;
      font-size: 14px;
      text-decoration: underline;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .ant-btn-primary {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
  .ant-form-item-explain-error {
    width: fit-content;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CarouseBox = styled.div`
  @media only screen and (max-width: 1024px) {
    .ant-image {
      img {
        object-fit: cover;
      }
    }
  }
  @media only screen and (max-width: 703px) {
    height: 280px;
    width: 100%;
  }

  div {
    height: 100% !important;
  }

  .ant-carousel {
    height: 100dvh !important;

    @media only screen and (max-width: 703px) {
      height: 100% !important;
    }
  }

  .ant-carousel:focus-visible {
    outline: none;
  }

  .ant-carousel .slick-slider,
  .slick-list,
  .slick-track {
    height: 100dvh;
  }
  .slick-dots {
    align-items: center;
    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-inline: 10px;
      button {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    li.slick-active {
      width: 18px;
      height: 18px;
    }
  }
`;
