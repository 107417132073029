import { roomDonateHistory } from 'app/apis/gift';
import { useMqtt } from 'app/hooks';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

type Props = {
  roomID: string;
};

export const useHistoryDonation = ({ roomID }: Props) => {
  const [oldDonateHistoryData, setOldDonateHistoryData] = useState<
    {
      sender: {
        avatar_url: string | null;
        username: string;
        id: number;
      };
      sender_id: number;
      total: number;
    }[]
  >([]);

  const [newDonateHistoryData, setNewDonateHistoryData] = useState<
    {
      sender: {
        avatar_url: string | null;
        username: string;
        id: number;
      };
      sender_id: number;
      total: number;
    }[]
  >([]);

  const [currentDonateHistoryData, setCurrentDonateHistoryData] = useState<
    {
      sender: {
        avatar_url: string | null;
        username: string;
        id: number;
      };
      sender_id: number;
      total: number;
    }[]
  >([]);

  useEffect(() => {
    if (roomID) {
      const res: any = roomDonateHistory(Number(roomID) || 0);
      res.then(res => {
        if (res?.statusCode === 200) {
          setCurrentDonateHistoryData(res.data ?? []);
        }
      });
    } else {
      // setOldDonateHistoryData([]);
      setCurrentDonateHistoryData([]);
      // setCurrentDonateHistoryData([]);
    }

    // return () => {
    //   setOldDonateHistoryData([]);
    //   setNewDonateHistoryData([]);
    //   setCurrentDonateHistoryData([]);
    // };
  }, [roomID]);

  useMqtt({
    topicName: `room/donate/${roomID}`,
    callback: onReceiveDonate,
  });

  function onReceiveDonate(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      const topDonate = dataParse.top_donate;

      setCurrentDonateHistoryData(topDonate);
    } catch (error) {
      toast.error('Get donate history fail');
    }
  }

  // useEffect(() => {
  //   if (oldDonateHistoryData && oldDonateHistoryData.length > 0) {
  //     let shadowCopyOldDonateHistory = JSON.parse(
  //       JSON.stringify(oldDonateHistoryData),
  //     );

  //     for (let i = 0; i < shadowCopyOldDonateHistory.length; i++) {
  //       const donate = shadowCopyOldDonateHistory[i];

  //       const index = newDonateHistoryData.findIndex(
  //         (item: any) => item.sender_id === donate.sender_id,
  //       );

  //       if (index !== -1) {
  //         shadowCopyOldDonateHistory.splice(index, 1);
  //       }
  //     }
  //     setCurrentDonateHistoryData(
  //       newDonateHistoryData.concat(shadowCopyOldDonateHistory),
  //     );
  //   } else {
  //     setCurrentDonateHistoryData(newDonateHistoryData);
  //   }
  // }, [oldDonateHistoryData, newDonateHistoryData]);

  return { donateHistoryData: currentDonateHistoryData };
};
