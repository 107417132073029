import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  getRoomLink,
  getDetailRoomRequest,
  checkLikeRequest,
  likeRequest,
  unLikeRequest,
} from 'app/apis/profile';
import { getUserRoom, userRequest } from 'app/apis/auth';
import { selectStream } from 'app/pages/StreamPage/slice/selectors';
import { useProfileSlice } from 'app/pages/Profile/slice';
import { useStreamSlice } from 'app/pages/StreamPage/slice';
import { _log } from 'utils/_log';
import { useMqtt } from 'app/hooks';
import { actionsToast } from 'app/pages/ToastPage/slice';

const useInit = () => {
  const [roomID, setRoomID] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const [totalOfLike, setTotalOfLike] = useState(0);
  const [totalOfViewer, setTotalOfViewer] = useState(0);
  const [isMobileLive, setIsMobileLive] = useState(false);
  const [isClosedRoom, setIsClosedRoom] = useState<boolean>(false);
  const [liked, setLiked] = useState(false);
  const [roomIDCurrent, setRoomIDCurrent] = useState<any>(null);
  const [roomNameCurrent, setRoomNameCurrent] = useState<any>('');
  const [currentUser, setCurrentUser] = useState(null);

  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');

  const dispatch = useDispatch();
  const actionProfile = useProfileSlice();
  const { actions } = useStreamSlice();
  const { roomLists } = useSelector(selectStream);

  useMqtt({
    topicName: `room/info/${roomID}`,
    callback: onReceiveStreamInformation,
  });

  useMqtt({
    topicName: `room/status/${roomID}`,
    callback: onReceiveStreamStatus,
  });

  function onReceiveStreamStatus(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);
      if (dataParse.status === '3') {
        setIsClosedRoom(true);
        setRoomID('');
      }
    } catch (error) {
      toast.error('配信情報が取得できません');
    }
  }

  const fetchRoomLink = () => {
    setIsLoading(true);
    getRoomLink({ username })
      .then((res: any) => {
        setIsLoading(false);
        const { data } = res;
        if (data) {
          setRoomID(String(data));
        } else {
          setRoomID('');
          setIsClosedRoom(true);
          dispatch(
            actionsToast.openToast({
              message:
                'ただいま配信準備中です。配信までもうしばらくお待ち下さい。',
              type: 'error',
            }),
          );
        }
      })
      .catch(err => {
        setIsLoading(false);
        dispatch(
          actionsToast.openToast({
            message:
              'ただいま配信準備中です。配信までもうしばらくお待ち下さい。',
            type: 'error',
          }),
        );
      });
  };

  function onReceiveStreamInformation(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      setTotalOfLike(dataParse?.current_like);
      setTotalOfViewer(dataParse?.current_viewer);
      // setTotalOfViewer(20 + Number(dataParse?.current_viewer));
    } catch (error) {
      toast.error('配信のリストが取得できません');
    }
  }

  const handleCheckLike = async () => {
    const res = await checkLikeRequest({ id: roomID });
    if (res) {
      return res?.data;
    }
    return false;
  };

  const handleLike = async () => {
    if (!liked) {
      try {
        setLiked(true);
        setTotalOfLike(totalOfLike + 1);
        await likeRequest({ id: roomID });
      } catch (e) {
        setLiked(false);
        setTotalOfLike(totalOfLike);
        toast.error('いいねの処理に失敗しました。');
      }
    } else {
      try {
        setLiked(false);
        totalOfLike > 0 && setTotalOfLike(totalOfLike - 1);
        await unLikeRequest({ id: roomID });
      } catch (e) {
        setLiked(true);
        setTotalOfLike(totalOfLike);
        toast.error('いいねの処理に失敗しました。');
      }
    }
  };

  const getUserRoomAPI = async () => {
    try {
      const res: any = await getUserRoom();

      if (res?.data?.room_anchor) {
        dispatch(
          actionsToast.openToast({
            message:
              'You are live streaming so you cannot watch the live stream in another room.',
            type: 'error',
          }),
        );
        setIsClosedRoom(true);
        return;
      }

      setRoomIDCurrent(res?.data?.room_id);

      setRoomNameCurrent(res?.data?.room_name || '');
    } catch (error) {
      setRoomIDCurrent(null);
      setRoomNameCurrent('');
    }
  };

  const getCurrentUser = async () => {
    try {
      const res: any = await userRequest();
      if (res?.statusCode === 200 && res?.data) {
        setCurrentUser(res?.data);
      }
    } catch (err) {
      _log('get user infor: ', err, 'error');
    }
  };

  useEffect(() => {
    if (username) {
      fetchRoomLink();
    }
  }, [username]);

  useEffect(() => {
    const fetchPerson = async id => {
      if (id && roomLists?.rows?.length > 0) {
        const personID = roomLists?.rows?.find(ele => ele?.id == id)?.owner_id;

        if (personID) {
          dispatch(actionProfile.actions.getPerson({ id: personID }));
        }
      }
    };

    fetchPerson(roomID);
  }, [roomLists, roomID]);

  // real-time detail live stream
  useEffect(() => {
    if (roomID && roomID !== '') {
      try {
        getDetailRoomRequest({ id: roomID }).then(res => {
          const { data } = res;
          setTotalOfLike(data?.current_like);
          setTotalOfViewer(data?.current_viewer);
          // setTotalOfViewer(20 + Number(data?.current_viewer));

          if (data) {
            const { width, height } = data;

            if (width === 'mobileWidth' && height === 'mobileHeight') {
              setIsMobileLive(true);
            } else {
              setIsMobileLive(false);
            }
          }
        });
      } catch (error) {
        _log('useInit error 2: ', error, 'error');
      }

      try {
        const firstCheckLike = async () => {
          const check = await handleCheckLike();

          setLiked(check);
        };

        firstCheckLike();
      } catch (error) {}
    }
  }, [roomID]);

  useEffect(() => {
    getUserRoomAPI();
    getCurrentUser();
    dispatch(actions.getRoomList({}));
  }, []);

  return {
    roomID,
    liked,
    totalOfLike,
    totalOfViewer,
    isClosedRoom,
    isLoading,
    currentUser,
    handleLike,
  };
};

export default useInit;
