import styled from 'styled-components';

export const SteamListUser = styled.div<any>`
  .list-stream-title {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    @media only screen and (max-width: 1024px) {
      margin: 20px 0;
    }

    .text {
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      width: 100%;

      @media only screen and (max-width: 1024px) {
        font-size: 24px;
      }

      @media only screen and (max-width: 640.9998px) {
        width: 70%;
        letter-spacing: 1px;
        line-height: 25px;
        font-size: 20px;
      }
    }
  }

  .search-container {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 10px;
    padding: 10px 0px;
    margin: 0px 130px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1024px) {
      margin: 0 20px;
      padding: 20px 0px;
    }
    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      margin: 0 10px;
      padding-top: 0px;
      display: flex;
      flex-direction: column-reverse;
    }

    .search-content {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      font-size: 14px;
      margin-left: 10px;
    }

    .ant-input-affix-wrapper {
      height: 44px;
      border-radius: 10px;
      padding-left: 20px;
      &:focus,
      &:hover {
        border-color: #eb7d17;
        box-shadow: none;
      }
    }

    .ant-input-suffix {
      span {
        color: #000;
        font-size: 20px;
      }
    }
  }

  .user-stream-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 115px;
    padding-bottom: ${({ isShowPagination }: any) =>
      isShowPagination ? '79px' : '0'};
    @media only screen and (max-width: 1024px) {
      margin: 0 35px;
    }
    @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
      margin: 0 5px;
      padding-bottom: ${({ isShowPagination }: any) =>
        isShowPagination ? '72px' : '0'};
    }

    .item-user {
      width: calc(100% / 7 - 20px);
      cursor: pointer;
      margin: 0 10px;
      margin-bottom: 20px;
      @media only screen and (max-width: 1024px) {
        width: calc((100% / 3) - 10px);
        margin: 0 5px;
        margin-bottom: 5px;
      }
      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
        width: calc(100% / 2 - 10px);
      }
    }

    .ant-avatar {
      background: #eb7d17;
      font-size: 60px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      margin: auto;
    }
    .pagination {
      position: fixed;
      bottom: 0;
      left: 0;
      background: #fff;
      width: 100%;
      padding-top: 27px;
      padding-bottom: 20px;
      @media only screen and (max-width: 1024px) {
        padding-top: 20px;
        padding-bottom: 27px;
      }
      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
        padding-bottom: 20px;
      }
    }
    .ant-pagination .ant-pagination-item a {
      font-weight: 400;
      font-size: 16px;
    }
    .ant-pagination .ant-pagination-item-active {
      border-color: #eb7d17;
    }
    .ant-pagination .ant-pagination-item-active a {
      color: #fff;
      background: #eb7d17;
    }
  }

  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
  }

  .live-text {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eb7d17;
    color: #fff;
    border-radius: 16px;
    width: 81px;
    height: 28px;
    @media only screen and (max-width: 1024px) {
      width: 66px;
      height: 26px;
    }

    .live-icon {
      .ant-image {
        font-family: unset;
      }
    }

    .dot {
      display: block;
      width: 8px;
      height: 8px;
      margin-right: 5px;
      border-radius: 100%;
      background: #fff;
    }
  }
  .view-info {
    position: absolute;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    left: 10px;
    bottom: 10px;
    gap: 12px;
    z-index: 2;
    p {
      color: #fff;
      font-size: 14px;
      display: flex;
      gap: 6px;
    }
  }

  .user-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.14px;
    margin-bottom: 0;
    margin-top: 5px;
    @media only screen and (max-width: 1024px) {
      margin-top: 0;
    }
  }

  .follower {
    font-size: 14px;
    margin-bottom: 0;
    color: #9ca3af;
  }
`;
