import { ExpandOutlined } from '@ant-design/icons';
import { Image, Space, Tooltip, Typography } from 'antd';
import DonationModal from 'app/pages/StreamPage/components/ChatGroup/DonationModal';
import HistoryDonationModal from 'app/pages/StreamPage/components/ChatGroup/HistoryDonationModal';
import BigDonateNotification from 'app/pages/StreamPage/components/LiveScreen/components/BigDonateNotification';
import { useLiveScreen } from 'app/pages/StreamPage/components/LiveScreen/hooks';
import { DonateHistoryButton } from 'app/pages/StreamPage/components/LiveScreen/styles';
import React from 'react';
import {
  AudioOffIcon,
  AudioOnIcon,
  GiftBox,
  HeartIcon,
  TheaterIcon,
  TheateredIcon,
  TopDonation,
  ViewIcon,
} from 'styles/image';
import { IS_IPAD } from 'utils/constants';
import { breakpoints } from 'utils/media-breakpoint';
import ChatTabMobile from '../../pages/StreamPage/components/ChatGroup/ChatTabMobile';
import { useStreamBar } from './hooks';
import { useInformationDetail } from 'app/pages/StreamPage/components/InformationDetail/hooks';
import { MetaButton } from 'app/components/Forms';
import { Wrapper } from './styles';

function StreamBar(props) {
  const {
    isLocalStream,
    isRemoteMainStream,
    setting,
    config,
    handleChange,
    isMobile,
    handleToggleIpadFullScreen,
  } = useStreamBar(props);

  const {
    donateMessage,
    imageDonateNotificationRef,
    confettiEffectRef,
    imagePosition,
    openModal,
    setOpenModal,
  } = useLiveScreen({
    localStreamConfig: props.localStreamConfig,
    roomID: props.roomID,
  });

  const { isFollow, handleFollowed, currentTotalFollowers } =
    useInformationDetail({
      userId: props.userStream?.id,
      userStreamTotalFollowers: props.userStream?.total_followers,
    });

  return (
    <Wrapper
      isTheater={props.isTheater}
      isMySelf={props.isMySelf}
      isFull={props.full}
      breakpoints={breakpoints}
    >
      {(isLocalStream || isRemoteMainStream) && (
        <>
          <div>
            {/* {!isMobile && setting.shareDesk && (
              <Tooltip
                arrow
                title={config.shareDesk ? 'Stop Share Screen' : 'Share Screen'}
              >
                {config.shareDesk ? (
                  <DesktopOutlined
                    onClick={e => handleChange('shareDesk', e)}
                  />
                ) : (
                  <DesktopOutlined
                    onClick={e => handleChange('shareDesk', e)}
                  />
                )}
              </Tooltip>
            )} */}
            {/* {setting.hasVideo && (
              <Tooltip
                title={config.mutedVideo ? 'Unmute Video' : 'Mute Video'}
                arrow
              >
                {config.hasVideo && !config.mutedVideo ? (
                  <VideoCameraOutlined
                    onClick={e =>
                      handleChange(
                        props.isMySelf ? 'video' : 'subscribedVideo',
                        e,
                      )
                    }
                  />
                ) : (
                  <VideoCameraOutlined
                    onClick={e =>
                      handleChange(
                        props.isMySelf ? 'video' : 'subscribedVideo',
                        e,
                      )
                    }
                  />
                )}
              </Tooltip>
            )} */}
            {(props.localStreamConfig?.stream ||
              props.remoteStreamConfigList?.length > 0) && (
              <>
                {props.isClientBannedError ||
                props.isMultipleRoomsError ? null : (
                  <>
                    {donateMessage}

                    <BigDonateNotification
                      imgRef={imageDonateNotificationRef}
                      confettiEffectRef={confettiEffectRef}
                      imagePosition={imagePosition}
                      isTheater={props.isTheater}
                      isMySelf={props.isMySelf}
                    />
                  </>
                )}

                {isMobile && !props.isMySelf && (
                  <div className="shadow-orange-top" />
                )}

                {isMobile && !props.isMySelf && (
                  <div className="user-infor">
                    <Typography.Title level={2} className="ellipsis-1">
                      {props.userStream?.username}
                    </Typography.Title>

                    <div>
                      <MetaButton
                        type={isFollow ? 'primary' : 'normal'}
                        // text={isFollow ? 'Followed' : 'Follow'}
                        text={isFollow ? 'フォロー済み' : 'フォローする'}
                        onClick={() => handleFollowed(isFollow)}
                        style={{
                          width: '80px',
                          height: '20px',
                          paddingTop: !isFollow ? '2px' : '5px',
                          fontSize: '12px',
                        }}
                      />
                      <p style={{ textAlign: 'center', fontSize: '12px' }}>
                        {currentTotalFollowers}
                        <span style={{ marginLeft: 3 }}>
                          {/* {currentTotalFollowers && currentTotalFollowers > 1
                            ? 'followers'
                            : 'follower'} */}
                          {currentTotalFollowers && currentTotalFollowers > 1
                            ? 'フォロワー'
                            : 'フォロワー'}
                        </span>
                      </p>
                    </div>
                  </div>
                )}

                <div className="shadow-orange" />

                {!props.isMySelf && (
                  <div
                    className="drop-heart"
                    onClick={props.handleLike}
                    style={{
                      background: !props.liked ? '#fff' : '#eb7d17',
                    }}
                  >
                    <Image
                      src={HeartIcon}
                      preview={false}
                      width={22}
                      height={18}
                      style={{
                        filter: !props.liked
                          ? 'invert(49%) sepia(100%) saturate(460%) hue-rotate(349deg) brightness(96%) contrast(92%)'
                          : '',
                      }}
                    />
                  </div>
                )}

                {!props.isMySelf && isMobile && (
                  <>
                    <div
                      className="drop-heart drop-gift"
                      style={{
                        background: '#eb7d17',
                      }}
                      onClick={() => {
                        props.setOpenModalDonation(true);
                      }}
                    >
                      <Image src={GiftBox} preview={false} />
                    </div>

                    <DonationModal
                      isModalOpen={props.openModalDonation}
                      roomID={props.roomID}
                      currentUser={props.currentUser}
                      userStream={props.userStream}
                      onCloseModal={() => {
                        props.setOpenModalDonation(false);
                      }}
                    />
                  </>
                )}

                <div className="view-info-container">
                  <div className="view-info">
                    <p className="live-text">
                      <span />
                      LIVE
                    </p>

                    {props.isMySelf ? (
                      <p>{props.formatTime(props.time)}</p>
                    ) : (
                      <>
                        <p>
                          <Image
                            src={HeartIcon}
                            alt="heart-icon"
                            preview={false}
                            width={16}
                            height={13}
                          />

                          {props.totalOfLike}
                        </p>

                        <p>
                          <Image
                            src={ViewIcon}
                            alt="view-icon"
                            preview={false}
                            width={16}
                            height={11}
                          />

                          {props.totalOfViewer}
                        </p>
                      </>
                    )}
                  </div>

                  {props.isMySelf ? (
                    <div className="view-info self">
                      <p>
                        <Image
                          src={HeartIcon}
                          alt="heart-icon"
                          preview={false}
                          width={16}
                          height={13}
                        />

                        {props.totalOfLike}
                      </p>

                      <p>
                        <Image
                          src={ViewIcon}
                          alt="view-icon"
                          preview={false}
                          width={16}
                          height={11}
                        />

                        {props.totalOfViewer}
                      </p>
                    </div>
                  ) : (
                    <div className="settings-container">
                      {setting.hasAudio && (
                        <Tooltip
                          title={
                            !config.subscribedAudio
                              ? 'Unmute Audio'
                              : 'Mute Audio'
                          }
                          arrow
                        >
                          <Image
                            src={
                              !config.subscribedAudio
                                ? AudioOnIcon
                                : AudioOffIcon
                            }
                            alt="eye-icon"
                            preview={false}
                            width={24}
                            height={24}
                            onClick={e =>
                              handleChange(
                                props.isMySelf ? 'audio' : 'subscribedAudio',
                                e,
                              )
                            }
                          />
                        </Tooltip>
                      )}

                      {!isMobile && (
                        <>
                          {config?.theater ? (
                            <Image
                              src={TheateredIcon}
                              alt="eye-icon"
                              preview={false}
                              width={20}
                              height={18}
                              onClick={e => handleChange('theater', e)}
                            />
                          ) : (
                            <Image
                              src={TheaterIcon}
                              alt="eye-icon"
                              preview={false}
                              width={24}
                              height={16}
                              onClick={e => handleChange('theater', e)}
                            />
                          )}
                          <ExpandOutlined
                            onClick={e => {
                              if (!IS_IPAD) {
                                handleChange('full', e);
                              } else {
                                handleToggleIpadFullScreen();
                              }
                            }}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}

            {isMobile && (
              <>
                {props.localStreamConfig?.stream && (
                  <>
                    <DonateHistoryButton
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <img
                        src={TopDonation}
                        alt="top-donation"
                        style={{
                          filter: 'invert(1)',
                        }}
                      />
                    </DonateHistoryButton>

                    <HistoryDonationModal
                      roomID={props.roomID}
                      isModalOpen={openModal}
                      onCloseModal={() => {
                        setOpenModal(false);
                      }}
                    />
                  </>
                )}
                <ChatTabMobile
                  isMySelf={props.isMySelf}
                  messageLists={props.messageLists}
                  currentUser={props.currentUser}
                />
              </>
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default React.memo(StreamBar);
