import Slider from 'react-slick';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MetaButton } from 'app/components/Forms';
import { selectProfile } from '../slice/selectors';
import { useProfileSlice } from '../slice';

const AvatarsList = ({ avatars, avatarSelected, fetchPerson, id }) => {
  const dispatch = useDispatch();
  const { actions } = useProfileSlice();
  const { isLoading } = useSelector(selectProfile);

  const [avatarSelect, setAvataSelect] = useState<any>(null);
  const [avatarsList, setAvatarsList] = useState<any>([]);

  const changeAvatarItem = () => {
    const data = {
      avatar3d_url: avatarSelect?.glb_url,
    };

    dispatch(actions.changeAvatar({ data, callback: callbackChangeAvatar }));
  };

  function callbackChangeAvatar() {
    fetchPerson(id);
  }

  const handleShowItem = () => {
    if (avatars?.length > 5) {
      return 5;
    }

    if (avatars?.length <= 3) {
      return 1;
    }

    return 3;
  };

  const settings = {
    centerMode: true,
    centerPadding: avatars?.length > 5 ? '30px' : '160px',
    slidesToShow: handleShowItem(),
    infinite: true,
    adaptiveHeight: avatars?.length === 1,
    arrows: avatars?.length !== 1,
    beforeChange: (current, next) => {
      const dataSelect = avatarsList?.[next];
      setAvataSelect(dataSelect);
    },
    responsive: [
      {
        breakpoint: 1520,
        settings: {
          centerMode: true,
          centerPadding: '80px',
          slidesToShow: avatars?.length <= 3 ? 1 : 3,
        },
      },
      {
        breakpoint: 680,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (avatarSelected) {
      const arrTempt = [...avatars];

      arrTempt?.sort(function (x, y) {
        return x?.glb_url === avatarSelected
          ? -1
          : y?.glb_url === avatarSelected
          ? 1
          : 0;
      });

      setAvatarsList(arrTempt);
      setAvataSelect(arrTempt?.[0]);
    } else {
      setAvatarsList(avatars);
      setAvataSelect(avatars?.[0]);
    }
  }, []);

  return (
    <Wrapper>
      {avatarsList?.length > 0 && (
        <div className="slider-container">
          <Slider {...settings} className="slider-common">
            {avatarsList?.map((item, idx) => (
              <div className="slider-item-container" key={idx}>
                <p className="slider-item-name">{item?.name}</p>
                <div className="slider-item-box">
                  <img
                    src={item?.thumbnail_url}
                    alt=""
                    className="img-slider"
                  />
                </div>
              </div>
            ))}
          </Slider>
          <div className="btn-group">
            <MetaButton
              onClick={changeAvatarItem}
              type="primary"
              text="アバター選択"
              className="btn-edit"
              disabled={avatarSelected === avatarSelect?.glb_url || isLoading}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default AvatarsList;

export const Wrapper = styled.div`
  .slider-container {
    padding: 40px 0;
    background-color: #f3f4f6;
    border-radius: 7px;
  }

  .slider-common {
    width: 80%;
    margin: auto;
  }

  .slider-item-container {
    max-width: 260px;
    position: relative;

    .slider-item-name {
      font-size: 13px;
      position: absolute;
      z-index: 999;
      top: 12px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    .slider-item-box {
      margin: 30px 10px;
      padding: 2% 0;
      height: 300px;
    }

    .img-slider {
      width: 100%;
      height: 100%;

      border: 4px solid #858484;
      border-radius: 10px;
      opacity: 0.7;
    }
  }

  .slick-next,
  .slick-prev {
    &:before {
      color: #eb7d17;
    }
  }

  .slick-center {
    .slider-item-container {
      opacity: 1;
      transform: scale(1.08);
      font-weight: bold;
    }

    .img-slider {
      opacity: 1;
      background: linear-gradient(#d8d8d8, #d8d8d8) padding-box,
        linear-gradient(to right, #ff08c8, #ef6500) border-box;
      border: 4px solid transparent;
    }
  }

  .btn-group {
    display: flex;
    justify-content: center;

    .btn-edit {
      height: 41px;
    }
  }

  @media only screen and (max-width: 720px) {
    .slider-item-name {
      font-size: 12px !important;
    }
  }
`;
