var whep = null;
const configObsLive = setIsPlay => {
  const startPlay = async function (whepServer, whepUserSig) {
    if (whep) {
      whep.close();
    }
    whep = new SrsRtcWhipWhepAsync();
    whep.bearer = whepUserSig;
    document.getElementById('whepPlayer').srcObject = whep.stream;

    // For example: http://localhost:1985/rtc/v1/whep/?app=live&stream=livestream
    var url = whepServer;
    const session = await whep.play(url);
    setIsPlay(true);
  };

  const handleLeaveObs = () => {
    if (whep) {
      whep.close();
      setIsPlay(false);
    }
  };

  function SrsRtcWhipWhepAsync() {
    var self = {
      bearer: null, // The Bearer Token for WHIP.
    };

    // See https://datatracker.ietf.org/doc/draft-ietf-wish-whip/
    // @url The WebRTC url to play with, for example:
    //      http://localhost:1985/rtc/v1/whep/?app=live&stream=livestream
    self.play = async function (url) {
      self.pc.addTransceiver('audio', { direction: 'recvonly' });
      self.pc.addTransceiver('video', { direction: 'recvonly' });

      var offer = await self.pc.createOffer();
      await self.pc.setLocalDescription(offer);
      const answer = await new Promise(function (resolve, reject) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
          if (xhr.readyState !== xhr.DONE) return;
          if (xhr.status !== 200 && xhr.status !== 201) return reject(xhr);
          const data = xhr.responseText;
          return data.code ? reject(xhr) : resolve(data);
        };
        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-type', 'application/sdp');
        self.bearer &&
          xhr.setRequestHeader('Authorization', `Bearer ${self.bearer}`);
        xhr.send(offer.sdp);
      });
      await self.pc.setRemoteDescription(
        new RTCSessionDescription({ type: 'answer', sdp: answer }),
      );

      return self.__internal.parseId(url, offer.sdp, answer);
    };

    // Close the publisher.
    self.close = function () {
      self.pc && self.pc.close();
      self.pc = null;
    };

    // The callback when got local stream.
    // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addStream#Migrating_to_addTrack
    self.ontrack = function (event) {
      // Add track to stream of SDK.
      self.stream.addTrack(event.track);
    };

    self.pc = new RTCPeerConnection(null);

    // To keep api consistent between player and publisher.
    // @see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addStream#Migrating_to_addTrack
    // @see https://webrtc.org/getting-started/media-devices
    self.stream = new MediaStream();

    // Internal APIs.
    self.__internal = {
      parseId: (url, offer, answer) => {
        return { url, offer, answer };
      },
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/ontrack
    self.pc.ontrack = function (event) {
      if (self.ontrack) {
        self.ontrack(event);
      }
    };

    return self;
  }

  return {
    startPlay,
    handleLeaveObs,
  };
};

export default configObsLive;
