import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const defaultBar = {
  hasVideo: true,
  hasAudio: true,
  subscribedAudio: true,
  subscribedVideo: true,
  shareDesk: true,
  full: true,
  theater: false,
};

export const useStreamBar = (props: any) => {
  const isMobile = useMediaQuery({ maxWidth: '640px' });

  // console.log(isMobile);

  const [config, setConfig] = useState(() => ({ ...props.config }));

  const [setting] = useState(() => ({ ...defaultBar, ...props.setting }));

  let isRemoteAuxStream = false;
  let isLocalStream = false;
  let isRemoteMainStream = false;

  const initStreamType = () => {
    if (props.config.type === 'auxiliary' || /share/g.test(config.userID)) {
      isRemoteAuxStream = true;
      return;
    }
    if (props.config.type === 'local') {
      isLocalStream = true;
    }
    if (props.config.type === 'main') {
      isRemoteMainStream = true;
    }
  };

  initStreamType();

  const handleChange = useCallback(
    (name, e) => {
      e.preventDefault();
      !props.isMySelf &&
        props.onChange &&
        props.onChange({ name, stream: config.stream });
    },
    [config, props],
  );

  function handleToggleIpadFullScreen() {
    const elem = document.querySelector('#viewer');

    if (!elem) {
      return;
    }

    if (!document.fullscreenElement) {
      elem
        .requestFullscreen()
        .then(() => {
          //    console.log('request fullscreen success');
        })
        .catch((err: any) => {
          console.error(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
    } else {
      document.exitFullscreen();
    }
  }

  useEffect(() => {
    setConfig(prevConfig => ({ ...prevConfig, ...props.config }));
  }, [props.config]);

  useEffect(() => {
    if (isLocalStream) {
      document.onkeyup = event => {
        if (event.code === 'Space' && config.stream) {
          handleChange('audio', event);
        }
      };
    }

    return () => {
      document.onkeydown = null;
      document.onkeyup = null;
    };
  });

  return {
    isLocalStream,
    isRemoteMainStream,
    setting,
    config,
    handleChange,
    isMobile,
    handleToggleIpadFullScreen,
  };
};
