import React, { useEffect } from 'react';

import { PaymentSuccessIcon } from 'styles/image';
import { MetaButton } from 'app/components/Forms';
import { Container } from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH } from 'app/constants/path';
import { paymentTransaction } from 'app/apis/point';
import { toast } from 'react-toastify';

const PaymentSuccessful = () => {
  const navigate = useNavigate();
  // const [params]: any = useSearchParams();

  // const handlePaymentTransaction = async () => {
  //   try {
  //     const res: any = await paymentTransaction({
  //       outTradeNo: params.get('outTradeNo'),
  //       result: params.get('result'),
  //     });

  //     if (res?.statusCode === 201) {
  //       toast.success('お支払い完了');
  //     }
  //   } catch (error) {
  //     toast.error('Purchase point fail');
  //   }
  // };

  // useEffect(() => {
  //   if (params) {
  //     handlePaymentTransaction();
  //   }
  // }, [params]);

  return (
    <Container>
      <img src={PaymentSuccessIcon} alt="" style={{ width: '400px' }} />

      <h2
        style={{
          color: '#1F2937',
          fontSize: '34px',
          fontWeight: '700',
          marginBottom: '40px',
        }}
      >
        お支払い完了
      </h2>

      <MetaButton
        type="primary"
        text="確定​"
        style={{ height: '52px', fontSize: '18px', margin: '0px auto' }}
        onClick={() => navigate(PATH.PROFILE)}
      />
    </Container>
  );
};

export default PaymentSuccessful;
