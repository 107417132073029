import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import {
  postRoomInitialRequest,
  initialObsRequest,
  postRoomStartRequest,
  postRoomCloseRequest,
  getDetailRoomRequest,
} from 'app/apis/profile';
import { _log } from 'utils/_log';
import { useMqtt } from 'app/hooks';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { TypeObs } from 'utils/constants';

const useInitViewer = typeObs => {
  const [roomID, setRoomID] = useState<any>('');
  const [whepServer, setWhepServer] = useState('');
  const [whipServer, setWhipServer] = useState('');
  const [whepUserSig, setWhepUserSig] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isStartRoomSuccess, setIsStartRoomSuccess] = useState(false);
  const [totalOfLike, setTotalOfLike] = useState(0);
  const [totalOfViewer, setTotalOfViewer] = useState(0);
  const [isMobileLive, setIsMobileLive] = useState(false);
  const [isErrorRoom, setIsErrorRoom] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useMqtt({
    topicName: `room/info/${roomID}`,
    callback: onReceiveStreamInformation,
  });

  const initialRoom = async typeObs => {
    setIsLoading(true);
    try {
      const res: any = await postRoomInitialRequest(typeObs === TypeObs.PUBLIC);
      if (res?.statusCode === 201 && res?.data) {
        setRoomID(`${res?.data}`);
        initialObs(res?.data);
      }
      setIsLoading(false);
    } catch (err) {
      _log('initialRoom error: ', err, 'error');
      setIsLoading(false);
    }
  };

  const initialObs = async id => {
    try {
      const res: any = await initialObsRequest(id);
      if (res?.statusCode === 200 && res?.data) {
        setWhepServer(res?.data?.server?.replace('pub', 'sub'));
        setWhipServer(res?.data?.server);

        setWhepUserSig(res?.data?.bearerToken);
      }
    } catch (err) {
      _log('initial obs error: ', err, 'error');
    }
  };

  const startRoom = async roomID => {
    try {
      setIsLoading(true);
      const res: any = await postRoomStartRequest({
        room_id: roomID,
        width: (window as any).mobileCheck() ? 'mobileWidth' : 'desktopWidth',
        height: (window as any).mobileCheck()
          ? 'mobileHeight'
          : 'desktopHeight',
      });

      if (res?.statusCode === 201) {
        // await RTC.handleJoin();
        // await RTC.handlePublish(defaultMedia.facingMode);
        setIsLoading(false);
        setIsStartRoomSuccess(true);
      }
    } catch (err: any) {
      setIsLoading(false);
      if (err?.response.data.code === 19) {
        setIsErrorRoom(true);
        dispatch(
          actionsToast.openToast({
            message: err?.response?.data?.message,
            type: 'error',
          }),
        );
      }
    }
  };

  const stopRoom = async roomID => {
    setIsLoading(true);
    try {
      await postRoomCloseRequest({ room_id: roomID });
      setWhipServer('');
      setWhepUserSig('');

      navigate(0);
    } catch (error) {
      _log('stopRoom error: ', error, 'error');
      setIsLoading(false);
    }
  };

  function onReceiveStreamInformation(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      setTotalOfLike(dataParse?.current_like);
      setTotalOfViewer(dataParse?.current_viewer);
      // setTotalOfViewer(20 + Number(dataParse?.current_viewer));
    } catch (error) {
      toast.error('配信のリストが取得できません');
    }
  }

  useEffect(() => {
    if (typeObs) {
      initialRoom(typeObs);
    }
  }, [typeObs]);

  // real-time detail live stream
  useEffect(() => {
    if (roomID && roomID !== '') {
      try {
        getDetailRoomRequest({ id: roomID }).then(res => {
          const { data } = res;
          setTotalOfLike(data?.current_like);
          setTotalOfViewer(data?.current_viewer);
          // setTotalOfViewer(20 + Number(data?.current_viewer));

          if (data) {
            const { width, height } = data;

            if (width === 'mobileWidth' && height === 'mobileHeight') {
              setIsMobileLive(true);
            } else {
              setIsMobileLive(false);
            }
          }
        });
      } catch (error) {
        _log('useInit viewer error 2: ', error, 'error');
      }
    }
  }, [roomID]);

  return {
    isLoading,
    whepServer,
    whipServer,
    whepUserSig,
    roomID,
    totalOfLike,
    totalOfViewer,
    isErrorRoom,
    isStartRoomSuccess,
    startRoom,
    stopRoom,
  };
};

export default useInitViewer;
