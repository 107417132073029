import { Tabs } from 'antd';
import { listPackage } from 'app/apis/point';
import { useMqtt } from 'app/hooks';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { PopupClose } from 'styles/image';
import { breakpoints } from 'utils/media-breakpoint';
import PointHistory from './PointHistory';
import PointPurchase from './PointPurchase';

const GetPoint = ({ setShowPointPopup }: any) => {
  const [tabKey, setTabKey] = useState('1');
  const [pointPakageData, setPointPakageData] = useState<any>([]);

  useMqtt({
    topicName: 'point-packages/list',
    callback: onReceivePointPackagesList,
  });

  function onReceivePointPackagesList(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const data = JSON.parse(msg?.data);

      setPointPakageData(data);
    } catch (error) {}
  }

  const tabs: any = [
    {
      key: '1',
      name: 'ポイントパック',
    },
    {
      key: '2',
      name: 'ポイント履歴',
    },
  ];

  const handleChangeTab = key => {
    setTabKey(key);
  };

  useEffect(() => {
    const res: any = listPackage();
    res.then(res => {
      if (res?.statusCode === 200) {
        setPointPakageData(res.data ?? []);
      } else {
        toast.error('Get list package fail');
      }
    });
  }, []);

  return (
    <GetPointPopup breakpoints={breakpoints}>
      <div className="get-point-popup-container">
        <div className="get-point-popup-box">
          <div className="popup-header">
            <img src={PopupClose} onClick={() => setShowPointPopup(false)} />
            {/* <h2>Get a point</h2> */}
            <h2>ポイントショップ</h2>
          </div>
          <div className="popup-menu">
            <Tabs
              onChange={handleChangeTab}
              activeKey={tabKey}
              items={tabs?.map((t, i) => {
                return {
                  label: <span key={i}>{t?.name}</span>,
                  key: t?.key,
                };
              })}
            />
          </div>
          <div className="popup-content">
            {tabKey === '1' && (
              <PointPurchase
                setShowPointPopup={setShowPointPopup}
                pointPakageData={pointPakageData}
              />
            )}

            {tabKey === '2' && <PointHistory />}
          </div>
        </div>
      </div>
    </GetPointPopup>
  );
};

export const GetPointPopup = styled.div<any>`
  .get-point-popup-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000069;
    .get-point-popup-box {
      margin: 0 auto;
      width: 700px;
      min-height: 524px;
      background: #ffffff;
      border: 1px solid #eb7d17;
      border-radius: 8px;
      @media only screen and (max-width: ${props => props.breakpoints.mobile}) {
        width: 90%;
        min-height: 634px;
      }
      .popup-header {
        margin: 12px;
        text-align: center;
        position: relative;
        border-bottom: 1px solid #d1d5db;
        padding-bottom: 20px;
        img {
          position: absolute;
          right: 0px;
          cursor: pointer;
        }
      }

      .popup-menu {
        .ant-tabs {
          width: 100%;
          .ant-tabs-nav-operations,
          .ant-tabs-nav-operations-hidden {
            display: none !important;
          }
          .ant-tabs-nav::before {
            display: none;
          }
          .ant-tabs-nav {
            border: none;
            padding-bottom: 0px;
            margin-bottom: 0px;
          }
          .ant-tabs-tab:not(:first-child) {
            border-left: 1px solid #d1d5db;
          }
          .ant-tabs-tab {
            color: #d1d5db;
            font-size: 14px;
            font-weight: 700;
            justify-content: center;
            padding: 0 22px;
            width: 100%;
            /* border-right: 1px solid #d1d5db; */
            @media only screen and (max-width: 420px) {
              padding: 0 12px;
            }
            .ant-tabs-tab-btn:active,
            .ant-tabs-tab-btn:focus {
              color: #1f2937;
            }
          }
          .ant-tabs-tab + .ant-tabs-tab {
            margin: 0px;
          }
          .ant-tabs-nav-list {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #1f2937;
            }
            span {
              color: #1f2937;
              font-weight: 700;
            }
          }
          .ant-tabs-ink-bar-animated {
            display: none;
          }
        }
      }

      .popup-content {
        margin: 20px;
        margin-top: 12px;

        @media only screen and (max-width: ${props =>
            props.breakpoints.mobile}) {
          margin: 10px;
        }
      }
    }
  }
`;

export default GetPoint;
