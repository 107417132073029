import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions as actions } from '.';
import {
  getMyRoomsRequest,
  getRoomsRequest,
  postRoomCloseRequest,
  postRoomInitialRequest,
  postRoomPauseRequest,
  postRoomStartRequest,
} from 'app/apis/profile';

function* getRoomList() {
  try {
    const res = yield call(getRoomsRequest);
    yield put(actions.getRoomListSuccess(res));
  } catch (error: any) {
    yield put(actions.getRoomListFailed(error));
  }
}

function* getMyRoomList() {
  try {
    const res = yield call(getMyRoomsRequest);
    yield put(actions.getMyRoomListSuccess(res));
  } catch (error: any) {
    yield put(actions.getMyRoomListFailed(error));
  }
}

function* pauseRoom(action: any) {
  try {
    const res = yield call(postRoomPauseRequest, action.payload);
    yield put(actions.pauseRoomSuccess(res));
  } catch (error: any) {
    yield put(actions.pauseRoomFailed(error));
  }
}

function* stopRoom(action: any) {
  try {
    const res = yield call(postRoomCloseRequest, action.payload);
    yield put(actions.stopRoomSuccess(res));
  } catch (error: any) {
    yield put(actions.stopRoomFailed(error));
  }
}

function* startRoom(action: any) {
  try {
    const res = yield call(postRoomStartRequest, action.payload);
    yield put(actions.startRoomSuccess(res));
  } catch (error: any) {
    yield put(actions.startRoomFailed(error));
    yield call(postRoomCloseRequest, action.payload);
    yield call(postRoomInitialRequest);
    yield call(postRoomStartRequest, action.payload);
  }
}

export function* streamSaga() {
  yield takeLatest(actions.getRoomList.type, getRoomList);
  yield takeLatest(actions.getMyRoomList.type, getMyRoomList);
  yield takeLatest(actions.startRoom.type, startRoom);
  yield takeLatest(actions.pauseRoom.type, pauseRoom);
  yield takeLatest(actions.stopRoom.type, stopRoom);
}
