import React, { useEffect } from 'react';
import { Button, Form, Modal, Radio, Space } from 'antd';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { IS_IPAD } from 'utils/constants';

import { positiveIntegerRegex } from 'utils/helpers';
import { MetaInput } from 'app/components/Forms';

interface IPropModal {
  isOpen?: boolean;
  setIsOpen?: any;
  form?: any;
  onSubmitSetting?: any;
  setMessageLists?: any;
}

const SettingStreamModal = ({
  isOpen,
  setIsOpen,
  form,
  onSubmitSetting,
  setMessageLists,
}: IPropModal) => {
  const isTablet = useMediaQuery({ maxWidth: '1370px' });

  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onFinish = values => {
    Promise.resolve()
      .then(() => {
        setMessageLists([]);
        onSubmitSetting(values);
      })
      .then(() => {
        handleCancel();
      });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({
      quantity: '0',
    });
  }, []);

  return (
    <SettingModalCustom
      title="デバイス設定"
      className="setting-modal"
      open={isOpen}
      onOk={handleOk}
      footer={false}
      onCancel={handleCancel}
      width={400}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // layout="inline"
        initialValues={{
          camera: true,
          facingMode: 'user',
          microphone: true,
        }}
      >
        <Form.Item label="カメラ" name="camera">
          <Radio.Group>
            <Radio value={true}>オン</Radio>
            <Radio value={false}>オフ</Radio>
          </Radio.Group>
        </Form.Item>

        {isTablet && (
          <Space
            className="w-full"
            direction="vertical"
            size={12}
            style={{
              position: 'relative',
              marginBottom: '8px',
            }}
          >
            <Form.Item className="mb-0" label="カメラの向き" name="facingMode">
              <Radio.Group>
                <Radio value="user">正面</Radio>
                <Radio value="environment">背面</Radio>
              </Radio.Group>
            </Form.Item>

            <p
              className="mb-0"
              style={{
                color: 'red',
                fontSize: '12px',
                position: 'absolute',
                bottom: '8px',
              }}
            >
              配信中はカメラの設定を変更をすることはできません
            </p>
          </Space>
        )}

        <Form.Item label="マイク" name="microphone">
          <Radio.Group>
            <Radio value={true}>オン</Radio>
            <Radio value={false}>オフ</Radio>
          </Radio.Group>
        </Form.Item>

        <MetaInput
          name="quantity"
          placeholder="ポイントを入力してください"
          label={
            <p style={{ color: 'rgba(0, 0, 0, 0.88)' }}>
              視聴ポイント <b style={{ color: '#c0392b' }}>*</b>
            </p>
          }
          isNormalize
          form={form}
          type="text"
          maxLength={9}
          onKeyDown={e => (e.key == 'Enter' ? e.preventDefault() : '')}
          rules={[
            { required: true, message: 'ポイントを入力してください' },
            { max: 9, message: '文字数上限は９です。' },
            {
              pattern: positiveIntegerRegex,
              message: '０より数字を入力してください',
            },
          ]}
        />

        <Form.Item className="mb-0 w-full form-button">
          <Space
            className="w-full"
            direction="horizontal"
            size={12}
            align="end"
            style={{ justifyContent: 'flex-end' }}
          >
            <Button onClick={handleCancel}>キャンセル</Button>
            <Button type="primary" htmlType="submit">
              配信開始
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </SettingModalCustom>
  );
};

export default SettingStreamModal;

export const SettingModalCustom = styled(Modal)`
  .ant-modal-header {
    border-bottom: 1px solid #8080802e;
    padding-bottom: 12px;
    margin-bottom: 24px;
    .ant-modal-title {
      font-size: 20px;
    }
  }
  .ant-form {
    label,
    label span {
      font-size: 16px;
      font-weight: 600;
      @media only screen and (max-width: 576px) {
        font-size: 14px;
      }
    }

    label span {
      font-weight: 500;
    }
    .ant-form-item {
      margin-bottom: 24px;
      margin-inline-end: 0px;
      width: 100%;
    }
    .ant-form-item-row {
      gap: 12px;
      @media only screen and (max-width: 576px) {
        display: grid;
        grid-template-columns: 120px 1fr;
      }
      .ant-form-item-label {
        min-width: 130px;
        display: flex;
        @media only screen and (max-width: 576px) {
          min-width: 100px;
        }
      }

      .ant-radio-group {
        label {
          margin-right: 20px;
        }
        .ant-radio-wrapper {
          &:hover {
            .ant-radio-inner {
              border-color: #eb7d17;
            }
          }
          .ant-radio-checked {
            .ant-radio-inner {
              border-color: #eb7d17;
              background-color: #eb7d17;
            }
          }
          .ant-radio-checked::after {
            border-color: #eb7d17;
          }
        }
      }
    }

    .form-button {
      border-top: 1px solid #8080802e;
      padding-top: 12px;
      margin-bottom: 0px;
      .ant-form-item-row {
        @media only screen and (max-width: 576px) {
          display: flex;
        }
      }

      .ant-btn {
        font-size: 16px;
        padding: 0px 20px;
        height: 36px;
        &:hover,
        &:focus {
          border-color: #eb7d17;
          color: #eb7d17;
        }
      }

      .ant-btn-primary {
        background-color: #eb7d17;
        &:hover,
        &:focus {
          background-color: #eb7d17;
          opacity: 0.8;
          color: #fff;
        }
      }
    }
  }
`;
