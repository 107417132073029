import Axios from 'app/apis';
import SocketAxios from 'app/apis/ConfigSocketAxios';

const loginRequest = (data: any) => {
  return SocketAxios.post('/auth/login', data);
};

const loginOAuthRequest = () => {
  return SocketAxios.get('/auth/google');
};

const loginOAuthPostRequest = (data: any) => {
  return SocketAxios.post('/auth/google', data);
};

const registerRequest = (data: any) => {
  return Axios.post('/auth/register', data);
};

const registerVerifyRequest = (data: any) => {
  return Axios.post('/auth/verify', data);
};

const resendOtpRequest = (data: any) => {
  return Axios.post('/auth/send-otp', data);
};

const forgotPasswordRequest = data => {
  return Axios.post('/auth/forgot-password', data);
};

const resetPasswordRequest = data => {
  return SocketAxios.post('/auth/reset-password', data);
};

const refeshTokenRequest = data => {
  return Axios.post('/auth/token/refresh', data);
};

const logoutRequest = () => {
  return Axios.post('/auth/logout');
};

const userRequest = () => {
  return Axios.get('/users/me');
};

const getUserRoom = () => {
  return Axios.get('/users/user-room');
};

export {
  loginRequest,
  loginOAuthRequest,
  resendOtpRequest,
  logoutRequest,
  registerRequest,
  forgotPasswordRequest,
  resetPasswordRequest,
  registerVerifyRequest,
  refeshTokenRequest,
  userRequest,
  getUserRoom,
  loginOAuthPostRequest,
};
