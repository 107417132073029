import { useState } from 'react';
import Cookies from 'js-cookie';
import { deleteAllCookies } from 'utils/common';

export default function useToken() {
  const getToken = () => {
    return Cookies.get(`access_token_${process.env.REACT_APP_ENVIRONMENT}`);
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: string) => {
    Cookies.set(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, userToken);
    setToken(userToken);
  };

  const remove = () => {
    deleteAllCookies();
    Cookies.remove(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, {
      path: '',
      domain: process.env.REACT_APP_DOMAIN,
    });
    setToken('');
  };

  return {
    setToken: saveToken,
    token,
    remove,
  };
}
