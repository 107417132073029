import styled from 'styled-components';

// css
export const InformationBox = styled.div<any>`
  width: 100%;
  height: calc(
    100vh - 88.83px - 80px
  ); // fallback for browsers not supporting dvh units
  height: ${props =>
    `calc(${
      !props?.isObsLive ? '100dvh' : '82dvh'
    } - 88.83px - 80px)`}; // 60: header, 80: padding top + bottom
  display: grid;
  grid-template-columns: ${props => (!props?.isTheater ? `1fr` : `50% 1fr`)};
  gap: ${props => (!props?.isTheater ? `0px` : `30px`)};

  @media screen and (max-width: 1729px) {
    height: calc(
      100vh - 88.83px - 40px
    ); // fallback for browsers not supporting dvh units
    height: calc(
      100dvh - 88.83px - 40px
    ); // 60: header, 40: padding top + bottom
  }

  .ant-typography {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .ant-tabs {
    width: 100%;
    .ant-tabs-nav-operations,
    .ant-tabs-nav-operations-hidden {
      display: none !important;
    }
    .ant-tabs-nav::before {
      display: none;
    }

    .ant-tabs-tab:not(:first-child) {
      border-left: ${props => (props?.onlyChat ? 'none' : '1px solid #d1d5db')};
    }

    .ant-tabs-tab {
      color: #d1d5db;
      font-size: 14px;
      font-weight: 700;
      justify-content: center;
      padding: 0px;
      margin: 0;

      .ant-tabs-tab-btn:active {
        color: #1f2937;
      }

      .ant-tabs-tab-btn {
        span {
          padding-top: 5px;
        }
      }

      .ant-image {
        margin-right: 7px;
        position: relative;
        top: -2px;
      }

      &:hover {
        color: #d1d5db;
      }
      & + .ant-tabs-tab {
        margin: 0;
      }
    }

    .ant-tabs-nav-list {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #1f2937;
      }

      span {
        color: #1f2937;
        font-weight: 700;
      }
    }

    .ant-tabs-ink-bar-animated {
      display: none;
    }
  }

  .ant-tabs-content-holder {
    display: none;
  }

  .ant-avatar {
    background: #eb7d17;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-avatar-image {
    background: transparent;
  }

  .ant-badge {
    width: 100%;
    height: 48px;
    border-radius: 16px;
    @media only screen and (max-width: 1024px) {
      width: ${props => (props?.isTheater ? `100%` : `30px`)};
      height: ${props => (props?.isTheater ? `48px` : `30px`)};
    }
    .ant-avatar,
    .anticon {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }

    .ant-scroll-number {
      width: 12px;
      height: 12px;
      right: 5px;
      top: 5px;
      background: #2cc069;
    }
  }

  .chat-container {
    position: relative;
    border: 1px solid #9ca3af;
    padding: 10px 10px;
    background-color: #f3f4f6;
    border-radius: 10px;
    height: calc(100% - 38px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 54px;

    .scroll-down-icon {
      position: absolute;
      cursor: pointer;
      width: 100%;
      text-align: center;
      bottom: 20px;

      .anticon {
        width: max-content;
        height: max-content;
        font-size: 16px;
        background: rgb(235, 125, 23);
        color: rgb(255, 255, 255);
        border-radius: 100%;
        cursor: pointer;
        padding: 3px;
        transition: 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .top {
      scroll-behavior: smooth;
      overflow-y: scroll;
      overflow-x: hidden;
      /* height: 655px; */
      padding-right: 6px;

      ::-webkit-scrollbar-track {
        background-color: #f3f4f6;
      }

      ::-webkit-scrollbar {
        width: 2px;
        background-color: #d9d9d9;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border: 1px solid #d9d9d9;
      }
      @media only screen and (max-width: 1024px) {
        padding-right: ${props => (props?.isTheater ? `6px` : `0`)};
      }

      /* them  */
      .box-content {
        display: grid;
        grid-template-columns: 48px 1fr;
        gap: 14px;
        align-items: flex-end;
        max-width: 70%;
        margin-bottom: 10px;
        @media only screen and (max-width: 1024px) {
          grid-template-columns: ${props =>
            props?.isTheater ? `48px 1fr` : `30px 1fr`};
          gap: ${props => (props?.isTheater ? `14px` : `5px`)};
          max-width: ${props => (props?.isTheater ? `70%` : `100%`)};
        }

        .box-content-description {
          display: grid;
          gap: 5px;
          background: #fff;
          padding: 10px;
          border-radius: 16px 16px 16px 0px;

          h5 {
            font-size: 10px;
            color: #cf6707;
            font-weight: 700;
            margin-bottom: 0px;
          }

          div.ant-typography {
            margin-bottom: 0px;
          }

          .time-text {
            color: #9ca3af;
            font-size: 10px;
          }

          .reply-message {
            border-left: 4px solid #eb7d17;
            background-color: #ededed;
            padding: 8px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .message-detail {
            font-size: 12px;

            .btn-see-all {
              border-top: 1px solid #d1d5db;
              margin-top: 4px;
              padding-top: 4px;
              display: flex;
              width: 100%;
              justify-content: space-between;
              cursor: pointer;

              .anticon {
                color: #1f2937;
                font-size: 12px;
              }
            }
          }
        }

        .box-content-notification {
          background: #fce0c6;

          .message-notification-detail {
            color: var(--gray-colors-gray-1, #1f2937);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .notification-title {
            color: #c82a15;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 160% */
            margin-bottom: 0;
          }

          .notification-donater,
          .notification-host {
            color: var(--gray-colors-gray-1, #1f2937);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            text-decoration-line: underline;
          }

          .notification-gift-name {
            color: var(--Primary, #eb7d17);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }

      /* our  */
      .box-content.my-message {
        flex-direction: row-reverse;
        margin-left: auto;
        grid-template-columns: 1fr 48px;
        @media only screen and (max-width: 1024px) {
          grid-template-columns: ${props =>
            props?.isTheater ? `1fr 48px` : `1fr 30px`};
        }

        .box-content-description {
          background: #eb7d17;
          border-radius: 16px 16px 0px 16px;
          grid-column: 1;
          grid-row: 1;
          div.ant-typography,
          .time-text {
            color: #fff;
          }

          .time-text {
            text-align: right;
          }

          .reply-message {
            text-align: left;
            border-left: 4px solid #fff;
            background-color: #cf6707;
            padding: 8px;
            border-radius: 4px;
            h5 {
              color: #fff;
            }
          }

          .message-detail {
            color: #fff;

            .btn-see-all {
              border-color: #fff;

              .anticon {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .bottom {
      border-top: 1px solid #d1d5db;
      padding-top: 10px;

      .ant-input,
      textarea {
        border-right: none;
        border-color: #d1d5db;
        height: 44px !important;

        &:hover {
          border-color: #d1d5db;
        }

        &:focus {
          border-color: #d1d5db;
          box-shadow: none;
        }
      }

      textarea {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 25px;

        ::-webkit-scrollbar {
          width: 0px;
        }
      }

      .ant-input-group-addon {
        cursor: pointer;
        background-color: #fff;

        &:hover {
          opacity: 0.7;
        }
      }

      .ant-input-group-addon:first-child {
        border-radius: 10px 0px 0px 10px;
        width: 41px;
      }

      .ant-input-group-addon:last-child {
        border-radius: 0px 10px 10px 0px;
        border-left: none;
      }
    }
  }

  /* View tab  */
  .view-container {
    height: calc(100% - 38px);
    grid-template-rows: 1fr;

    .view-box {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 30px;
      padding: 20px;
      max-height: 700px;
      height: max-content;
      overflow-y: scroll;
      overflow-x: hidden;

      ::-webkit-scrollbar-track {
        background-color: #f3f4f6;
      }

      ::-webkit-scrollbar {
        width: 2px;
        background-color: #d9d9d9;
        position: absolute;
        right: 20px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border: 1px solid #d9d9d9;
      }

      .view-box-col {
        height: max-content;
      }

      .user-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #d1d5db;
        margin-bottom: 14px;
        padding-bottom: 14px;

        .user-index {
          color: var(--gray-colors-gray-1, #1f2937);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        .user-name {
          color: var(--gray-colors-gray-1, #1f2937);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.14px;
        }

        .user-point {
          color: var(--Primary, #eb7d17);
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: -0.14px;
        }

        .user-point-text {
          color: var(--gray-colors-gray-1, #1f2937);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
          letter-spacing: -0.12px;
        }

        &:hover .user-index {
          color: var(--Primary, #eb7d17);
        }
      }
    }
    .d-box {
      display: flex;
      gap: 14px;
      align-items: center;

      .ant-badge {
        width: 48px;
        height: 48px;
      }

      p:first-child {
        width: 20px;
      }

      p:last-child {
        font-weight: 700;
      }
    }

    .view-box-col:nth-last-child(-n + 2) {
      .d-box {
        border-bottom: none;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .ant-typography-expand {
    display: none;
  }

  .gift-container {
    display: flex;
    flex-direction: column;

    .ant-modal-wrap {
      @media only screen and (min-width: ${props =>
          props.breakpoints.desktop}) {
        position: absolute !important;
      }
    }

    .gift-type-box {
      position: relative;

      ul {
        display: -webkit-box;
        margin-top: 0;
        list-style-type: none;
        border: 1px solid #d1d5db;
        border-radius: 8px;
        padding-left: 0;
        overflow: auto;
        /* display: flex; */
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-between;
        padding-right: 80px;
        margin-bottom: 0;

        @media screen and (max-width: 1400px) {
          grid-template-columns: repeat(2, 1fr);
        }

        li {
          position: relative;
          text-align: center;
          padding: 2px 0;
          margin: 5px 0;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #d1d5db;
          width: 100%;
          font-weight: bold;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            width: 1px;
            height: 24px;
            border-right: 1px solid #d1d5db;
          }

          span {
            color: #d1d5db;
          }
        }

        li.active {
          span {
            padding: 5px 15px;
            border-radius: 8px;
            background: white;
            color: #1f2937;
          }
        }
      }

      .group_btn {
        position: absolute;
        top: 5px;
        right: 10px;

        button {
          border: 0;
          background-color: #fff;
          padding: 5px;
          cursor: pointer;

          &:disabled {
            img {
              filter: invert(1);
            }
          }
        }

        .btn-next {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        .btn-prev {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    .gift-list-box {
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      ul {
        margin-top: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding-left: 0;
        @media only screen and (max-width: 1400px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media only screen and (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
          row-gap: 0;
        }

        li.gift-item {
          margin: 10px 1px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          flex: auto;
          cursor: pointer;
          width: 127px;
          /* max-width: 127px; */
          height: 140px;
          width: 100%;
          @media only screen and (max-width: 1024px) {
            margin: 5px 0;
            /* width: 150px; */
            height: 163px;
          }

          .gift-image-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid #d1d5db;
            border-radius: 5px;
            height: 100%;

            img {
              margin: 5px 15px 15px 15px;
            }
          }

          .gift-selected {
            background: white;
            border: 1px solid #eb7d17;
          }

          .gift-name {
            color: var(--gray-colors-gray-1, #1f2937);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 166.667% */
          }

          .gift-point {
            color: var(--Primary, #eb7d17);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 12px; /* 75% */
          }

          .gift-point-text {
            color: var(--gray-colors-gray-6, #d1d5db);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }
    }
    .gift-send-box {
      border-top: 1px solid #d1d5db;
      margin-top: auto;

      .gift-send-button {
        width: 60%;
        height: 52px;
        border-radius: 10px;
        border: none;
        background: #eb7d17;
        color: #ffffff;
        margin-left: 20%;
        margin-top: 10px;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        @media only screen and (max-width: 1024px) {
          width: 100%;
          margin-left: 0;
        }
      }

      .gift-send-button.isDisabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
`;
