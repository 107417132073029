import { Image, Input } from 'antd';
import { actionsToast } from 'app/pages/ToastPage/slice';
import React, { useRef } from 'react';
import { SendIcon } from 'styles/image';

const ChatInput = ({
  dispatch,
  sendButton,
  inputMessage,
  setInputMessage,
  isDisabled,
  scrollBottom,
}) => {
  const chatInput = useRef<any>(null);

  const handleSaveData = value => {
    if (value?.length > 80) {
      dispatch(
        actionsToast.openToast({
          message: 'This message must be 80 characters or less.',
          type: 'error',
        }),
      );
    } else {
      sendButton?.current?.click();
      setInputMessage('');
      scrollBottom();
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.shiftKey) {
        setInputMessage(prevText => prevText + '\n');
      }
    }
  };

  return (
    <>
      <Input.TextArea
        ref={chatInput}
        disabled={isDisabled}
        value={inputMessage}
        placeholder="メッセージを送る ..."
        onChange={e => setInputMessage(e.target.value)}
        autoSize
        onKeyDown={handleKeyDown}
        maxLength={80}
        // onPressEnter={(e: any) => handleSaveData(e.target.value)}
        // addonBefore={
        //   <Upload {...props}>
        //     <PlusCircleFilled style={{ color: '#EB7D17', fontSize: 20 }} />
        //   </Upload>
        // }
      />
      <span
        style={{
          position: 'absolute',
          right: '22px',
          bottom: '20px',
        }}
      >
        <Image
          alt="send"
          preview={false}
          src={SendIcon}
          width={18}
          height={18}
          onClick={() => handleSaveData(chatInput?.current?.input?.value)}
          style={{
            filter: inputMessage?.length > 0 ? '' : 'brightness(0.5)',
          }}
        />
      </span>
    </>
  );
};

export default ChatInput;
