import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import Cookies from 'js-cookie';
import { AuthState, LoginRequestType, RegisterRequestType } from './types';

export const initialState: AuthState = {
  currentUser: null,
  isUpdatePurchasePoint: false,
  isLoading: false,
  isLoadingUser: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Login
    login(state, action: PayloadAction<LoginRequestType>) {
      state.isLoading = true;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      Cookies.set(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, action.payload?.data?.accessToken, {
        sameSite: 'lax',
        domain: process.env.REACT_APP_DOMAIN,
      });
    },
    loginFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Login OAuth
    loginOAuth(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    loginOAuthSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      Cookies.set(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, action.payload?.data?.accessToken, {
        sameSite: 'lax',
        domain: process.env.REACT_APP_DOMAIN,
      });
    },
    loginOAuthFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Register
    register(state, action: PayloadAction<RegisterRequestType>) {
      state.isLoading = true;
    },
    registerSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    registerFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Verification
    verify(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    verifySuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    verifyFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Verification
    resendCode(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    resendCodeSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    resendCodeFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Forgot Password
    forgotPassword(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    forgotPasswordSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    forgotPasswordFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Reset Password
    resetPassword(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    resetPasswordSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    resetPasswordFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // Login
    getUser(state, action: PayloadAction<any>) {
      state.isLoadingUser = true;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.isLoadingUser = false;
      state.currentUser = action.payload.data;
    },
    getUserFailed(state, action: PayloadAction<any>) {
      state.isLoadingUser = false;
    },

    // Update Purchase Point
    setIsUpdatePurchasePoint(state, action: PayloadAction<any>) {
      state.isUpdatePurchasePoint = action.payload;
    },
  },
});

export const { actions: authActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
