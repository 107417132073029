import React, { useEffect, useState, useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

type InputCodeProps = {
  autoFocus?: boolean;
  length?: number;
  onChange?: (data: string) => void;
  onCompleted?: (data: string) => void;
  placeholder?: string;
  value?: string;
};

type ContainerProps = {
  itemsCount: number;
};

type InputProps = {
  activeIndex: number;
};

export const MetaOtpInput = ({
  autoFocus = false,
  length = 6,
  onChange = () => {},
  onCompleted = () => {},
  placeholder = '_',
  value: pValue,
}: InputCodeProps) => {
  const emptyValue = new Array(length).fill('');

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [value, setValue] = useState<string[]>(
    pValue ? pValue.split('') : emptyValue,
  );

  const codeInputRef = React.createRef<HTMLInputElement>();
  const itemsRef = useMemo(
    () =>
      new Array(length).fill(null).map(() => React.createRef<HTMLDivElement>()),
    [length],
  );

  const isCodeRegex = new RegExp(`^[0-9]{${length}}$`);

  const getItem = (index: number) => itemsRef[index]?.current;
  const focusItem = (index: number): void => getItem(index)?.focus();
  const blurItem = (index: number): void => getItem(index)?.blur();

  const onItemFocus = (index: number) => () => {
    setActiveIndex(index);
    if (codeInputRef.current) codeInputRef.current.focus();
  };

  const onInputKeyUp = ({ key, keyCode }: React.KeyboardEvent) => {
    const newValue = [...value];
    const nextIndex = activeIndex + 1;
    const prevIndex = activeIndex - 1;
    const codeInput = codeInputRef.current;
    const currentItem = getItem(activeIndex);

    const isLast = nextIndex === length;
    if (keyCode === KEY_CODE.SPACE) {
      return;
    }
    const isDeleting =
      keyCode === KEY_CODE.DELETE || keyCode === KEY_CODE.BACKSPACE;

    if (isDeleting) {
      newValue[activeIndex] = '';
      setValue(newValue);

      if (activeIndex > 0) {
        setActiveIndex(prevIndex);
        focusItem(prevIndex);
      }

      return;
    }

    if (keyCode === KEY_CODE.ARROW_LEFT && activeIndex) {
      setActiveIndex(prevIndex);
      focusItem(prevIndex);
    }

    if (
      keyCode === KEY_CODE.ARROW_RIGHT &&
      newValue[nextIndex] !== '' &&
      activeIndex < length - 1
    ) {
      setActiveIndex(nextIndex);
      focusItem(nextIndex);
    }

    if (Number.isNaN(+key)) return;

    if (codeInput) codeInput.value = '';
    newValue[activeIndex] = key;
    if (newValue[activeIndex - 1] === '') return;

    setValue(newValue);

    if (!isLast) {
      setActiveIndex(nextIndex);
      focusItem(nextIndex);
      return;
    }

    if (isLast) {
      setActiveIndex(activeIndex);
      focusItem(activeIndex);
      return;
    }

    if (codeInput) codeInput.blur();
    if (currentItem) currentItem.blur();

    setActiveIndex(-1);
  };

  // handle mobile autocompletion
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: changeValue } = e.target;
    const isCode = isCodeRegex.test(changeValue);

    if (!isCode) return;

    setValue(changeValue.split(''));
    blurItem(activeIndex);
  };

  const onInputBlur = () => {
    if (activeIndex === -1) return;

    blurItem(activeIndex);
    setActiveIndex(-1);
  };

  // autoFocus
  useEffect(() => {
    if (autoFocus && itemsRef[0].current) {
      itemsRef[0].current.focus();
    }
  }, []);

  // handle pasting
  useEffect(() => {
    const codeInput = codeInputRef.current;
    if (!codeInput) return;

    const onPaste = (e: ClipboardEvent) => {
      e.preventDefault();

      const pastedString = e.clipboardData?.getData('text');
      if (!pastedString) return;

      const isNumber = !Number.isNaN(+pastedString);
      if (isNumber) setValue(pastedString.split('').slice(0, length));
    };

    codeInput.addEventListener('paste', onPaste);
    return () => codeInput.removeEventListener('paste', onPaste);
  }, []);

  useEffect(() => {
    const stringValue = value.join('');
    const isCompleted = stringValue.length === length;

    if (isCompleted && stringValue !== emptyValue.join(''))
      onCompleted(stringValue);
    onChange(stringValue);
  }, [value, length]);

  useEffect(() => {
    if (typeof pValue !== 'string') return;

    // avoid infinite loop
    if (pValue === '' && value.join('') === emptyValue.join('')) return;

    // keep internal and external states in sync
    if (pValue !== value.join('')) setValue(pValue.split(''));
  }, [pValue]);

  const renderItemText = (itemValue: string) => {
    if (itemValue === placeholder) return placeholder;
    return itemValue;
  };

  return (
    <Container itemsCount={length}>
      <GlobalStyle />
      <Input
        ref={codeInputRef}
        type="text"
        onChange={onInputChange}
        onKeyUp={onInputKeyUp}
        onBlur={onInputBlur}
        activeIndex={activeIndex}
        data-cy={`code-otc-input`}
      />

      {itemsRef.map((ref, i) => (
        <Item
          key={i}
          ref={ref}
          role="button"
          tabIndex={0}
          className={`${i === activeIndex ? 'is-active' : ''}`}
          onFocus={onItemFocus(i)}
          data-cy={`code-${i}-item`}
          data-text={placeholder}
        >
          {renderItemText(value[i])}
        </Item>
      ))}
    </Container>
  );
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --item_width: 75px;
    --item_height: 75px;
    --item_spacing: 20px;
  }
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  margin: 18px auto 0;
  position: relative;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const Input = styled.input<InputProps>`
  position: absolute;
  top: 0;
  left: ${({ activeIndex }) =>
    `calc(
      var(--item_width) * ${activeIndex}
      + var(--item_spacing) * ${activeIndex}
    )`};
  opacity: 0;
  width: 40px;
  height: 40px;
`;

export const Item = styled.div`
  border: 1px solid rgb(209, 213, 219);
  border-radius: 10px;
  font-size: 14px;
  color: rgb(31, 41, 55);
  width: 40px;
  height: 40px;
  line-height: var(--item_height);
  text-align: center;
  transition: box-shadow 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;

  &:focus-visible {
    outline: none;
  }
  &.is-active {
    border-color: #eb7d17 !important;
    box-shadow: 0px 0px 14px 0px #ff00001a;
  }
  &:empty:not(:focus):before {
    color: #bfbfbf !important;
    content: attr(data-text);
  }
`;

export const KEY_CODE = {
  BACKSPACE: 8,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  DELETE: 46,
  SPACE: 32,
};
