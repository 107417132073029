import mqtt, { MqttClient } from 'mqtt';
import { useEffect } from 'react';

export const useMqtt = ({
  topicName,
  dependencies = [],
  callback,
}: {
  topicName: string;
  dependencies?: any;
  callback: (message: Buffer) => void;
}) => {
  useEffect(() => {
    const client: MqttClient = mqtt.connect(
      String(process.env.REACT_APP_MQTT_URL),
      {
        username: String(process.env.REACT_APP_MQTT_USERNAME),
        password: String(process.env.REACT_APP_MQTT_PASSWORD),
      },
    );

    // Handle connection event
    client.on('connect', () => {
      // Subscribe to a topic
      client.subscribe(topicName);
    });

    // Handle message event
    client.on('message', (topic, message) => {
      callback(message);
    });

    // Cleanup on unmount
    return () => {
      // Disconnect MQTT client
      client.end();
    };
  }, [topicName, ...dependencies]);
};
