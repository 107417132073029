import { useMqtt } from 'app/hooks';
import { toast } from 'react-toastify';
import { useProfileSlice } from 'app/pages/Profile/slice';
import { selectProfile } from 'app/pages/Profile/slice/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'utils/media-breakpoint';
import { getTotalDonationRequest } from 'app/apis/profile';

type Props = {
  userId?: string | number | null;
  userStreamTotalFollowers?: number | null;
};

export const useInformationDetail = ({
  userId,
  userStreamTotalFollowers,
}: Props) => {
  const dispatch = useDispatch();

  const { currentPerson } = useSelector(selectProfile);

  const { actions } = useProfileSlice();

  const isDesktop = useMediaQuery({ minWidth: breakpoints.desktop });

  const [isFollow, setIsFollow] = useState(currentPerson?.followed);

  const [isOpen, setIsOpen] = useState(false);

  const [totalDonation, setTotalDonation] = useState(0);

  const [currentTotalFollowers, setCurrentTotalFollowers] = useState<
    number | null | undefined
  >(userStreamTotalFollowers);

  useMqtt({
    topicName: `user-info/${userId}`,
    callback: onReceiveUserInfo,
  });

  function onReceiveUserInfo(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const data = JSON.parse(msg.data);

      setCurrentTotalFollowers(data?.total_followers);
    } catch (error) {
      console.error('An error occurred in onReceiveUserInfo:', error);
    }
  }

  async function handleFollowed(isFollowed) {
    try {
      if (isFollowed) {
        dispatch(actions.unFollowed({ target: currentPerson?.id }));
      } else {
        dispatch(actions.followed({ target: currentPerson?.id }));
      }

      setTimeout(() => {
        setIsFollow(!isFollowed);
        dispatch(actions.getPerson({ id: currentPerson?.id }));
      }, 800);
    } catch (error) {
      console.error('An error occurred in handleFollowed:', error);
    }
  }

  const getTotalDonation = async roomId => {
    try {
      const res = await getTotalDonationRequest({ id: roomId });
      if (res) {
        setTotalDonation(res.data);
      }
    } catch (error) {
      toast.error('get total donation error');
    }
  };

  useEffect(() => {
    dispatch(actions.getLabel({}));
  }, []);

  return {
    isOpen,
    setIsOpen,
    isDesktop,
    isFollow,
    handleFollowed,
    currentPerson,
    currentTotalFollowers,
    getTotalDonation,
    totalDonation,
  };
};
