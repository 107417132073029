import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel, Form, Image, Radio } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

import useToken from 'app/hooks/useToken';
import { PATH } from 'app/constants/path';
import { MetaTitle } from 'app/components';
import LoginForm from 'app/pages/AuthPage/components/LoginForm';
import RegisterForm from 'app/pages/AuthPage/components/RegisterForm';
import { useAuthSlice } from 'app/pages/AuthPage/slice';
import {
  JyannaIcon,
  LoginBanner1,
  LoginBanner2,
  LoginBanner3,
  LoginBannerMobile1,
  LoginBannerMobile2,
  LoginBannerMobile3,
} from 'styles/image';
import { selectAuth } from '../slice/selectors';
import { useMediaQuery } from 'react-responsive';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { loginOAuthPostRequest, loginOAuthRequest } from 'app/apis/auth';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { MetaButton } from 'app/components/Forms';
import { useGoogleLogout } from 'react-google-login';

export const SignInUpPage = memo(() => {
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { token } = useToken();
  const [type, setType] = useState('unity');
  const [tokenResponse, setTokenResponse] = useState<any>(null);

  const { actions } = useAuthSlice();
  const { isLoading } = useSelector(selectAuth);

  const { pathname } = location;

  const [key, setKey] = useState('login');

  const ref = useRef<HTMLDivElement | null>(null);

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '',
    onLogoutSuccess: () => console.log('Log out successfully'),
  });

  const onChange = e => {
    const { value } = e.target;

    if (value === 'login') {
      navigate(PATH.LOGIN);
    } else {
      navigate(PATH.REGISTER);
    }

    form.resetFields();
    setKey(value);
  };

  const loginWithGoogle = (type, tokenResponse) => {
    const { email, name } = tokenResponse?.profileObj;
    const values = {
      idToken: tokenResponse?.tokenId,
      username: name,
      email: email,
    };

    dispatch(
      actions.loginOAuth({
        values: values,
        type: type,
        searchParam: searchParams.get('username') || '',
      }),
    );
  };

  const onFinish = useCallback(
    (values: any, type, tokenResponse) => {
      if (tokenResponse) {
        // Login with Google
        loginWithGoogle(type, tokenResponse);
      } else {
        if (pathname === PATH.REGISTER) {
          delete values.confirmPassword;
          dispatch(
            actions.register({
              email: values?.email,
              password: values?.password,
              username: values?.username,
            }),
          );
        } else {
          dispatch(
            actions.login({
              values: {
                email: values?.email,
                password: values?.password,
              },
              type: type,
              searchParam: searchParams.get('username') || '',
            }),
          );
        }
      }

      setTokenResponse(null);
      setSearchParams('');

      form.resetFields();
      dispatch(actionsToast.closeToast({}));
    },
    [actions, dispatch, pathname, type],
  );

  const PopupRender = ({ tokenResponse }) => (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <CloseOutlined
        onClick={() => {
          dispatch(actionsToast.closeToast({}));
          signOut();
        }}
        style={{
          position: 'absolute',
          top: '-55px',
          right: '-28px',
          fontSize: '20px',
          color: '#EB7D17',
        }}
      />
      <MetaButton
        type="primary"
        text="メタ空間へログイン​"
        className="btn-primary-blue"
        style={{
          marginTop: '0px',
          height: '52px',
          maxWidth: '328px',
          width: '100%',
        }}
        onClick={() => {
          setType('unity');
          onFinish(form.getFieldsValue(true), 'unity', tokenResponse);
        }}
      />

      <MetaButton
        type="primary"
        style={{
          marginTop: '0px',
          height: '52px',
          maxWidth: '328px',
          width: '100%',
        }}
        text="スパチャLiveへログイン​"
        onClick={() => {
          setType('live');
          onFinish(form.getFieldsValue(true), 'live', tokenResponse);
        }}
      />

      <MetaButton
        type="primary"
        text="ジャンナプライム"
        className="btn-primary-blue"
        style={{
          marginTop: '0px',
          height: '52px',
          maxWidth: '328px',
          width: '100%',
        }}
        onClick={() => {
          window.open('https://prime.jyanna.jp');
        }}
      />

      <MetaButton
        className="btn-primary-lightgrey"
        type="primary"
        style={{
          height: '52px',
          maxWidth: '328px',
          width: '100%',
          marginTop: '30px',
        }}
        text="キャンセル​"
        onClick={() => {
          dispatch(actionsToast.closeToast({}));
          signOut();
        }}
      />
    </div>
  );

  const onLoginOAuth = tokenResponse => {
    setTokenResponse(tokenResponse);

    dispatch(
      actionsToast.openToast({
        titleCustom: ' ',
        message: ` `,
        type: 'customChild',
        children: <PopupRender tokenResponse={tokenResponse} />,
      }),
    );
  };

  useEffect(() => {
    form.setFieldsValue({ username: '', password: '' });
  }, [form]);

  useEffect(() => {
    if (pathname === PATH.LOGIN) {
      setKey('login');
    } else {
      setKey('register');
    }
  }, [pathname]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTop =
        ref.current.clientHeight - ref.current.scrollHeight;
    }
  }, [pathname]);

  useEffect(() => {
    if (Cookies.get(`access_token_${process.env.REACT_APP_ENVIRONMENT}`)) {
      navigate(PATH.HOME);
    }
  }, []);

  return (
    <Wrapper ref={ref}>
      <MetaHelmet
        title={
          pathname === PATH.LOGIN
            ? 'JYANNA WORLDログインページ'
            : 'JYANNA WORLD'
        }
      />

      <Box className="form-content">
        {!isMobile && (
          <Image
            className="header-logo"
            src={JyannaIcon}
            preview={false}
            alt="logo"
            width={170}
          />
        )}

        <MetaTitle
          text="Welcome Back!"
          subText="Welcome back, please enter your detail!"
        />
        <TabBox>
          <Radio.Group onChange={onChange} value={key}>
            <Radio.Button value="login">ログイン</Radio.Button>
            <Radio.Button value="register">新規登録</Radio.Button>
          </Radio.Group>
        </TabBox>

        {key === 'login' ? (
          <LoginForm
            dispatch={dispatch}
            form={form}
            onFinish={onFinish}
            setType={setType}
            onLoginOAuth={onLoginOAuth}
            PopupRender={PopupRender}
          />
        ) : (
          <RegisterForm
            onLoginOAuth={onLoginOAuth}
            form={form}
            onFinish={onFinish}
            isLoading={isLoading}
          />
        )}
      </Box>
      <CarouseBox>
        <Carousel autoplay effect="fade" speed={2000} infinite>
          <Image
            src={isMobile ? LoginBannerMobile1 : LoginBanner1}
            alt="login_banner_1"
            preview={false}
          />
          <Image
            src={isMobile ? LoginBannerMobile2 : LoginBanner2}
            alt="login_banner_2"
            preview={false}
          />
          <Image
            src={isMobile ? LoginBannerMobile3 : LoginBanner3}
            alt="login_banner_3"
            preview={false}
          />
        </Carousel>
      </CarouseBox>
    </Wrapper>
  );
});

// css
const Wrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  display: grid;
  grid-template-columns: 35% calc(100% - 35%);
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
  @media only screen and (max-width: 1440px) {
    grid-template-columns: 468px calc(100% - 468px);
  }
  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .form-content {
    max-width: 328px;
    width: 100%;
    margin: auto;
    justify-content: flex-start;
    margin-top: 55px;
    height: max-content;
    @media only screen and (max-width: 1440px) {
      margin: auto;
    }
    @media only screen and (max-width: 703px) {
      max-width: 500px;
      background: #fff;
      padding: 31px;
      border-radius: 20px;
    }
  }

  .form-login {
    width: 100%;
  }

  .forgot-pass {
    a {
      position: absolute;
      right: 0;
      bottom: -25px;
      color: #9ca3af;
      font-size: 12px;
      font-style: italic;
      text-decoration: underline;
      &:hover {
        color: #eb7d17;
      }
    }
  }

  .ant-btn-primary {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
  .ant-form-item-explain-error {
    width: fit-content;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const TabBox = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  width: 100%;

  .ant-radio-group {
    background-color: #f3f4f6;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .ant-radio-button-wrapper {
    height: 48px;
    padding: 4px;
    background-color: #f3f4f6;
    span {
      display: flex;
      align-items: center;
      height: 100%;
      color: #9ca3af;
      font-size: 18px;
      font-weight: 700;
      justify-content: center;
      border-radius: 8px;
    }
  }
  .ant-radio-button-wrapper-checked {
    span {
      color: #1f2937;
    }
    span:nth-child(2) {
      background-color: #fff;
    }
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper:first-child {
    border-right: 0px;
    border-start-start-radius: 8px;
    border-end-start-radius: 8px;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #d1d5db !important;
  }
`;

const CarouseBox = styled.div`
  @media only screen and (max-width: 1024px) {
    .ant-image {
      img {
        object-fit: cover;
      }
    }
  }
  @media only screen and (max-width: 703px) {
    height: 280px;
    width: 100%;
  }

  div {
    height: 100% !important;
  }
  .ant-carousel {
    height: 100dvh !important;

    @media only screen and (max-width: 703px) {
      height: 100% !important;
    }
  }
  .ant-carousel:focus-visible {
    outline: none;
  }
  .ant-carousel,
  .ant-carousel .slick-slider,
  .slick-list,
  .slick-track {
    height: 100dvh;
  }
  .slick-dots {
    align-items: center;
    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-inline: 10px;
      button {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    li.slick-active {
      width: 18px;
      height: 18px;
    }
  }
`;
