import { createGlobalStyle } from 'styled-components';
import 'styles/font/Helvetica Neu Bold.ttf';
import 'styles/font/NotoSans-Black.ttf';
import 'styles/font/NotoSans-BlackItalic.ttf';
import 'styles/font/NotoSans-Bold.ttf';
import 'styles/font/NotoSans-BoldItalic.ttf';
import 'styles/font/NotoSans-ExtraBold.ttf';
import 'styles/font/NotoSans-ExtraBoldItalic.ttf';
import 'styles/font/NotoSans-ExtraLight.ttf';
import 'styles/font/NotoSans-ExtraLightItalic.ttf';
import 'styles/font/NotoSans-Italic.ttf';
import 'styles/font/NotoSans-Light.ttf';
import 'styles/font/NotoSans-LightItalic.ttf';
import 'styles/font/NotoSans-Medium.ttf';
import 'styles/font/NotoSans-MediumItalic.ttf';
import 'styles/font/NotoSans-Regular.ttf';
import 'styles/font/NotoSans-SemiBold.ttf';
import 'styles/font/NotoSans-SemiBoldItalic.ttf';
import 'styles/font/NotoSans-Thin.ttf';
import 'styles/font/NotoSans-ThinItalic.ttf';

export const GlobalStyle = createGlobalStyle<{
  isLandScaped: boolean;
}>`
  html,
  body {
    height: 100%;
    width: 100%;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    scroll-behavior: smooth;
  }

  body {
    min-height: ${props => (props.isLandScaped ? 'auto' : '540px')};
  }

  body[orient='portrait'] {
    min-height: ${props => (props.isLandScaped ? 'auto' : '540px')};
  }

  body[orient='landscape'] {
    min-height: ${props => (props.isLandScaped ? 'auto' : '400px')};
  }
  
  :root {
    --color-primary: #C6A785;
    --color-secondary: #F4887B;
    --color-warning: #E10000;
    --color-black: #1F2937;
    --color-focus: #FFF2F1;
    --color-white: #FFFFFF;
    --color-gray-1: #707070;
    --color-gray-2: #919191;
    --color-gray-3: #D8D8D8;
    --color-gray-4: #EDEDED;
    --color-gray-5: #F8F8F8;
    --color-gray-6: #6E6969;
    --color-gray-7: #BEBEBE;
    --color-btn-hover: #957C63;
    --color-orange: #EC895E;
    --color-green: #89CB72;
  }

  body, p, h1, h2, h3, h4 , h5, label, span, a,div {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0px;
  }

  a{
    cursor: pointer;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  input, select, button {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: inherit;
  }


  .mb-0{
    margin-bottom: 0px;
  }

  .w-full{
   width: 100%;
  }

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid #ffffff;
    background-clip: padding-box;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
  }

  ::-webkit-scrollbar-thumb:hover {
    background:  rgba(0, 0, 0, 0.4);
  }

  .trtc_autoplay_mask{
    display: none !important;
  }

  .modal-rotate-screen-wrapper {
    backdrop-filter: blur(20px);
  }

  .container-custom{
    padding-left: 360px;
    padding-right: 360px;
    padding-bottom: 40px;
    @media only screen and (max-width: 1440px) {
      padding-left: 120px;
      padding-right: 120px;
    }
    @media only screen and (max-width: 1024px) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @media only screen and (max-width: 640px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .ellipsis-1 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .ellipsis-2 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .ellipsis-5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .ellipsis-7 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }

  .loading-data-wrapper {
    z-index: 1;
    height: 100vh; // fallback for browsers not supporting dvh units
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9999;
    position: fixed;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
  }

  

  ::placeholder {
    opacity: 1 !important;
  }

  .ant-form-item-explain-error {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #e10000;
    font-size: 14px;
    width: fit-content;
  }


  .ant-form-item-required {
    &::before {
      color: #e10000 !important;
      font-weight: bold !important;
      font-size: 15px;
      margin-inline-end: 4px;
      content: "*";
      font-family: 'Noto Sans JP', serif !important;
    }

    &::after {
      font-weight: bold !important;
      font-size: 15px;
      content: ":";
    }
  }
  .ant-input-disabled {
    color: var(--color-gray-2) !important;
    background: var(--color-gray-4) !important;
  }
  .ant-tag {
    border-radius: 10px;
    padding: 0px 15px;
    margin-inline-end: 0px;
    min-width: 81px;
    text-align: center;
  }
 
  .ant-input-number-input {
    padding: 0;
  }

  .ant-spin-nested-loading >div>.ant-spin{
    max-height: 100%;
  }

  .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
    display: none;
  }

  .ant-dropdown-menu {
    margin-left: auto !important;

    .ant-dropdown-menu-item {
      text-align: center;

      .button-purchase {
        font-weight: bold;
        color: #EB7D17;
        font-size: 12px;
      }

      .header-point-info {
        display: flex;
        justify-content: space-between;
        .point-text {
          font-size: 22px;
          color: #D1D5DB;
        }
  
        .point-value {
          font-size: 22px;
          color: #EB7D17;
          font-weight: bold;
          padding-left: 10px;
          text-align: right;
        }
  
        .money {
          font-size: 16px;
          color: #9CA3AF;
          text-align: right;
        }
      }
    }
  }

  .nodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
    @media only screen and (max-width: 703px) {
        text-align: center;
        margin-top: 12px !important;
        .ant-image {
          width: 80% !important;
          height: auto !important;
          img {
            width: 100% ;
            height: 100% !important;
          }
        }
        p {
          font-size: 20px !important;
        }
      }
  }

  .ant-popconfirm-buttons .ant-btn-primary{
    background-color: #EB7D17;
  }

  .btn-text.ant-btn {
    padding: 0px;
    width: 100%;
    text-align: left;
    &:hover {
      background: transparent;
    }
  }

  .popup-confirm-gift-are-wrapper {
    & > div[role='dialog'] {
      max-width: 100%;
    }
  }
`;
