import { Modal } from 'antd';
import styled from 'styled-components';

// css
export const Wrapper = styled.div<{
  isTheater: boolean;
  isFull: boolean;
  isVerticalScreen: boolean;
  isLandScaped: boolean;
}>`
  padding: 25px 113px;
  overflow-x: hidden;
  display: ${props => (props?.isLandScaped ? 'flex' : 'grid')};
  grid-template-columns: ${props => (!props.isTheater ? '1fr 565px' : '1fr')};
  justify-content: space-between;
  gap: 30px;
  height: ${props => (props?.isLandScaped ? '100vh' : '')};
  @media only screen and (max-width: 1729px) {
    padding: 20px;
    grid-template-columns: ${props => (props?.isTheater ? `1fr` : `66% 1fr`)};
    justify-content: center;
  }
  @media only screen and (max-width: 1024px) {
    padding: 10px;
    gap: 10px;
  }
  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    padding: 5px;
    /* padding-bottom: 23px; */
  }

  .block-left {
    width: 100%;

    @media only screen and (max-width: 1024px) {
      height: 100%;
    }
  }

  .obs-video {
    width: 100%;
    height: ${props => (props?.isLandScaped ? '100%' : '')};
    margin-bottom: 20px;
    border-radius: 30px;
    border: 1px solid #eb7d17;
    box-shadow: 0px 0px 20px 0px #eb311752;
    padding: ${props => (!props.isTheater ? '10px' : '0')};

    @media only screen and (max-width: 703px) {
      height: 100%;
      border-radius: 20px;
      padding: 6px;
    }

    video,
    canvas {
      width: 100%;
      height: 100%;
      border-radius: ${props =>
        props.isVerticalScreen ||
        props.isTheater ||
        props.isFull ||
        props.isLandScaped
          ? '0'
          : '20px'};
      background: ${props =>
        props.isVerticalScreen ? '#000 !important' : '#ffffff !important'};
      box-shadow: 0px 0px 24px 0px #00000026;

      @media only screen and (max-width: 703px) {
        border-radius: ${props => (props.isVerticalScreen ? '20px' : '0')};
        background: #000 !important;
      }
    }

    canvas {
      display: flex;
      width: ${props => (props.isVerticalScreen ? 'auto !important' : '')};
      border-radius: ${props => (props.isLandScaped ? '20px' : '0')};
      margin: auto !important;
    }
  }

  #viewer {
    position: relative;
    max-width: ${props => (props?.isTheater ? `70%` : ``)};
    height: 100%;
    aspect-ratio: ${props => (props?.isLandScaped ? '' : '16/9')};
    margin: auto;
    display: flex;
    justify-content: center;
    background: ${props => (props?.isLandScaped ? '#000' : '')};
    border-radius: ${props => (props?.isLandScaped ? '20px' : '')};

    @media only screen and (max-width: 703px) {
      width: 100%;
      background: #000 !important;
      border-radius: 20px;
    }
  }

  .video-container {
    position: absolute;
    top: 50% !important;
    width: ${props => (props?.isLandScaped ? 'auto' : '100%')};
    height: ${props => (props?.isLandScaped ? '100%' : 'auto')};
    transform: translateY(-50%) !important;
    margin: auto;
  }

  .ant-message {
    position: absolute;
    left: 0 !important;
    transform: translateX(0) !important;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: start;
    z-index: 1;

    .ant-message-notice,
    .ant-message-notice-wrapper {
      text-align: left !important;

      transition: all 0.3s;
    }

    .ant-message-notice {
      transition: all 0.3s;
    }

    .ant-message-notice:last-child,
    .ant-message-notice-wrapper:last-child {
      .ant-message-notice-content {
        background-color: #4f4f4f73;
      }

      .donate-message-heading {
        color: #eb7d17;
      }

      .donate-message-content {
        color: var(--gray-colors-white, #fff);
      }
    }

    .anticon.anticon-check-circle {
      display: none !important;
    }

    .ant-message-notice-content {
      padding: 10px 20px;
      border-radius: 0px 16px 16px 16px;
      background-color: #4f4f4f73;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
      transition: all 0.2s;
    }

    .donate-message-box {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .donate-message-gif-image {
      width: 35px;
      height: 35px;
    }

    .donate-message {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .donate-message-heading {
      color: #eb7d17;
      font-family: Helvetica Neue;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 160% */

      margin-top: 0;
      margin-bottom: 0;

      transition: all 0.2s;
    }

    .donate-message-content {
      color: #fff;
      font-family: Helvetica Neue;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 85.714% */

      transition: all 0.2s;
    }
  }

  .drop-heart {
    position: absolute;
    z-index: 1;
    bottom: 58px;
    right: 15px;
    background-color: #eb7d17;
    width: max-content;
    padding: 10px;
    border-radius: 7px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    @media only screen and (max-width: 703px) {
      bottom: 110px;
    }

    &:hover {
      opacity: 0.8;
      transition: 0.3s;
    }

    .ant-image {
      display: flex;
    }
  }

  .drop-gift {
    @media only screen and (max-width: 703px) {
      bottom: 58px;
    }
  }

  .settings-container {
    display: flex;
    gap: 20px;
    align-items: center;
    position: absolute;
    z-index: 1;
    right: 18px;
    bottom: 2px;

    .ant-image {
      display: flex;
      cursor: pointer;
    }
  }

  .anticon {
    font-size: 24px;
    height: 16px;
    color: #fff;
    cursor: pointer;
  }

  .view-info-container {
    width: 100%;
    position: absolute;
    bottom: 13px;
    left: 0;
    padding-inline: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
  }

  .view-info {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .live-text {
      align-items: center;
      padding: 2px 10px;
      background: #eb7d17;
      color: #fff;
      border-radius: 16px;
      font-size: 12px;
      font-weight: 600;

      span {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #fff;
      }
    }

    .ant-image {
      display: flex;
    }
  }

  .view-info.self {
    gap: 21px;
  }

  .shadow-orange-top {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 75%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: linear-gradient(
      to bottom,
      rgb(146, 79, 18) 0%,
      rgba(235, 125, 23, 0) 12%,
      rgba(235, 125, 23, 0) 100%
    );

    @media only screen and (max-width: 1729px) {
      top: 0px;
    }
  }

  .user-infor {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 10px;

    h2 {
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      margin: 0;
    }

    .ant-space-item p {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .shadow-orange {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 46px;
    border-bottom-left-radius: ${props =>
      props.isTheater || props.isFull ? '0' : '20px'};
    border-bottom-right-radius: ${props =>
      props.isTheater || props.isFull ? '0' : '20px'};
    background: linear-gradient(180deg, rgba(235, 125, 23, 0) 0%, #924f12 100%);

    @media only screen and (max-width: 1729px) {
      bottom: 0px;
    }
  }

  .bottom {
    position: relative;

    textarea {
      border-color: #d1d5db;
      height: 44px !important;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 25px;
    }

    span {
      right: 10px !important;
      bottom: 50% !important;
      transform: translateY(50%) !important;
    }
  }

  .chat-container-mobile-box {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin-bottom: 60px;
    margin-left: 15px;
    z-index: 999;
    width: 74vw;
    .chat-container-mobile {
      max-height: 200px;
      height: 100%;
      padding-right: 5px;
      scroll-behavior: smooth;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      -webkit-transform: translateZ(0);
      -webkit-overflow-scrolling: touch;
      ::-webkit-scrollbar {
        width: 2px;
      }
      .top {
        /* max-height: 200px;
        padding-right: 5px; */
        /* -webkit-overflow-scrolling: touch; */
        /* -webkit-transform: translateZ(0); */
      }
      @media only screen and (max-width: 703px) {
      }

      @keyframes fadeOut {
        0% {
          opacity: 1;
        } /* Thời điểm bắt đầu: độ mờ là 1 */
        100% {
          opacity: 0;
        } /* Thời điểm kết thúc: độ mờ là 0 */
      }

      .is-show {
        animation: fadeOut 1s ease-in-out forwards;
      }

      .box-content {
        margin-bottom: 4px;
        background-color: #4f4f4f2e;
        border-radius: 16px 16px 16px 0px;
        padding: 5px 10px;

        .box-content-name {
          display: flex;
          justify-content: space-between;
          color: #fff;

          .time-text {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            color: #fff;
          }

          h5,
          span {
            margin-bottom: 0;
          }
        }

        .message-detail {
          div {
            font-size: 12px;
            color: #fff;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }

      /* our  */
      .box-content.my-message {
        background-color: #4f4f4f2e;
        border-radius: 16px 16px 16px 0px;
        padding: 5px 10px;

        .box-content-name {
          display: flex;
          justify-content: space-between;
          color: #fff;

          .time-text {
            font-size: 10px;
            font-weight: 400;
            line-height: 16px;
            color: #fff;
          }
        }

        .message-detail {
          div {
            font-size: 12px;
            color: #fff;
            margin-bottom: 0;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
    }
  }
`;

export const ErrorClientBannedModalContainer = styled(Modal)`
  .ant-modal-close {
    display: none;
  }
  &.ant-modal {
    width: 580px !important;
  }
  .modal-title {
    color: #1f2937;
    text-align: center;
    font-size: 34px;
    font-weight: 700;
  }
  .ant-modal-content {
    padding: 0 20px;
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto !important;
    border-radius: 20px;
    @media only screen and (max-width: 703px) {
      padding: 60px 31px;
    }
  }
  .ant-modal-header {
    margin: 0;
  }
  .ant-modal-footer {
    width: 100%;
    margin-top: 0px;
  }
  .ant-modal-body {
    text-align: center;
    width: 100%;
  }
`;

export const ErrorIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;
