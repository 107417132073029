import React, { useState, useEffect } from 'react';

function UserList(props) {
  const [localStreamConfig, setLocalStreamConfig] = useState(() => ({
    ...props.localStreamConfig,
  }));
  const [remoteStreamConfigList, setRemoteStreamConfigList] = useState(
    () => props.remoteStreamConfigList,
  );

  useEffect(() => {
    const { localStreamConfig, remoteStreamConfigList } = props;
    setLocalStreamConfig(localStreamConfig);
    setRemoteStreamConfigList(
      remoteStreamConfigList &&
        remoteStreamConfigList?.filter(
          remoteStreamConfig => remoteStreamConfig.streamType !== 'auxiliary',
        ),
    );
  }, [props]);

  return (
    <ul>
      {localStreamConfig && <li>{localStreamConfig?.userID}</li>}

      {remoteStreamConfigList &&
        remoteStreamConfigList?.length > 0 &&
        remoteStreamConfigList?.map((remoteStreamConfig, idx) => (
          <li key={idx}>{remoteStreamConfig?.userID}</li>
        ))}
    </ul>
  );
}

export default UserList;
