import React, { useEffect, useState } from 'react';
import { GiftConfirm, NextIcon, PrevIcon } from 'styles/image';
import { useDonationTab } from './hooks';
import GetPoint from 'app/pages/Point/GetPoint';
import styled from 'styled-components';
import { Modal } from 'antd';
import { useMediaQuery } from 'react-responsive';

const DonationTab = ({
  giftTypeData,
  giftData,
  currentUser,
  userStream,
  roomID,
  onCloseModal,
  isDisabled,
}: any) => {
  const {
    currentElements,
    giftCategoryId,
    handleChangeGiftType,
    getPreviousTab,
    getNextTab,
    currentTab,
    chunkSize,
    listGif,
    handleSelectGift,
    giftSelectedData,
    openPopupConfirmSendGift,
    isPopupGiftConfirmShow,
    isPopupBuyPointsShow,
    showPointPopup,
    closePopupConfirmSendGift,
    handleSendGift,
    onClosePopupConfirmBuyPoints,
    onConfirmBuyPoints,
    setShowPointPopup,
  } = useDonationTab({
    currentUser,
    giftData,
    userStream,
    roomID,
    onCloseModal,
    giftTypeData,
  });
  const [listGiftFiltered, setListGiftFiltered] = useState<any[]>([]);
  const isMobile = useMediaQuery({ maxWidth: '640px' });

  useEffect(() => {
    if (listGif && listGif.length > 0) {
      const filtered = listGif.filter(item => item.isPublic === true);

      setListGiftFiltered(filtered);
    } else {
      setListGiftFiltered([]);
    }
  }, [listGif]);

  return (
    <div className="chat-container gift-container" id="gift-container">
      <div className="gift-type-box">
        <ul>
          {currentElements?.map(t => {
            return (
              <li
                key={t.name}
                className={giftCategoryId == t.id ? 'active' : ''}
                onClick={() => handleChangeGiftType(t.id)}
              >
                <span>{t?.name}</span>
              </li>
            );
          })}
        </ul>

        <div className="group_btn">
          <button
            className="btn-next"
            onClick={getPreviousTab}
            disabled={currentTab === 0}
          >
            <img src={PrevIcon} alt="" />
          </button>

          <button
            className="btn-prev"
            onClick={getNextTab}
            disabled={currentTab >= giftTypeData?.length / chunkSize - 1}
          >
            <img src={NextIcon} alt="" />
          </button>
        </div>
      </div>

      <div className="gift-list-box">
        <ul>
          {listGiftFiltered?.map((item, index) => (
            <li
              className="gift-item"
              key={item.name + index}
              onClick={() => !isDisabled && handleSelectGift(item)}
            >
              <div
                className={`gift-image-box ${
                  giftSelectedData.id == item.id && 'gift-selected'
                }`}
              >
                <img
                  src={item.icon_url || item.image_url}
                  alt=""
                  width={60}
                  height={60}
                />

                <p className="gift-point">{item.price}</p>
                <p className="gift-point-text">
                  {item.price > 1 ? 'Points' : 'Point'}
                </p>
              </div>

              <p className="gift-name ellipsis-1">{item.name}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* <div className="gift-send-box">
        <button
          className={`gift-send-button ${
            (isDisabled || !giftSelectedData?.id) && 'isDisabled'
          }`}
          onClick={openPopupConfirmSendGift}
          disabled={isDisabled || !giftSelectedData?.id}
        >
          Send
        </button>
      </div> */}

      {/* Popup confirm send gift */}
      <ModalWrapper
        open={isPopupGiftConfirmShow}
        onCancel={closePopupConfirmSendGift}
        footer={null}
        getContainer={
          document.getElementById(
            isMobile ? 'viewer' : 'gift-container',
          ) as HTMLElement
        }
        wrapClassName="popup-confirm-gift-are-wrapper"
      >
        <div className="popup-confirm-gift-are">
          <div className="popup-header">
            <img src={GiftConfirm} alt="" />
          </div>

          <div className="popup-content">
            <h2>Send Gift</h2>

            <span style={{ color: '#9CA3AF' }}>
              Are you sure you want to send this gift?
            </span>
          </div>

          <div className="popup-footer">
            <button
              className="gift-confirm-button"
              onClick={closePopupConfirmSendGift}
            >
              No
            </button>

            <button
              className="gift-confirm-button yes-button"
              onClick={handleSendGift}
            >
              Yes
            </button>
          </div>
        </div>
      </ModalWrapper>

      {/* Popup confirm buy points */}
      <ModalWrapper
        open={isPopupBuyPointsShow}
        onCancel={onClosePopupConfirmBuyPoints}
        footer={null}
      >
        <div className="popup-confirm-gift-are popup-buy-points-content-wrapper">
          <div className="popup-content" style={{ gap: 20 }}>
            <h2>Not enough point</h2>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span style={{ fontSize: 16, color: '#9CA3AF' }}>
                Not enough points to send gift.
              </span>
              <span style={{ fontSize: 16, color: '#9CA3AF' }}>
                Do you want to purchase point ?
              </span>
            </div>
          </div>

          <div className="popup-footer" style={{ marginTop: 20 }}>
            <button
              className="gift-confirm-button"
              onClick={onClosePopupConfirmBuyPoints}
            >
              Cancel
            </button>

            <button
              className="gift-confirm-button yes-button"
              onClick={onConfirmBuyPoints}
              style={{
                padding: '0 20px',
              }}
            >
              Purchase point
            </button>
          </div>
        </div>
      </ModalWrapper>

      {/* Popup buy points */}
      {showPointPopup && <GetPoint setShowPointPopup={setShowPointPopup} />}
    </div>
  );
};

export default DonationTab;

const ModalWrapper = styled(Modal)`
  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    height: 280px;
    padding: 0;
    border-radius: 15px;
  }

  .ant-modal-body {
    height: 100%;
  }

  .popup-confirm-gift-are {
    height: 100%;
    background: white;
    border-radius: 15px;
    border: 1px solid #eb7d;

    .popup-content {
      text-align: center;
    }

    .popup-header {
      margin: 20px 0;
      text-align: center;
    }

    .popup-footer {
      margin-top: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .gift-confirm-button {
        margin: 10px;
        width: 120px;
        height: 52px;
        border-radius: 7px;
        border: none;
        font-weight: bold;
        cursor: pointer;
      }

      .yes-button {
        background: #eb7d17;
        color: #ffffff;
      }
    }
  }

  .popup-buy-points-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    .popup-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .gift-confirm-button.yes-button {
      width: fit-content;
    }
  }
`;
