import { takeLatest } from 'redux-saga/effects';
import { actionsToast as actions } from '.';

function* open(action: any) {}

function* close(action: any) {}

export function* toastSaga() {
  yield takeLatest(actions.openToast.type, open);
  yield takeLatest(actions.closeToast.type, close);
}
