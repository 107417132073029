import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Dropdown,
  Image,
  Input,
  Menu,
  Pagination,
  Space,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { EyeView, LiveIcon, NoData } from 'styles/image';
import { getFirstLetter } from 'utils/helpers';
import useInit, { SORT } from './hooks/useInit';
import { SteamListModal, CustomButton } from './styles';

export const ModalStreamList = ({
  addEventListener,
  removeEventListener,
  isShowModalStreamList,
  sendMessage,
}: any) => {
  const {
    setIsLoading,
    setValues,
    fetchStreamList,
    values,
    isModalOpen,
    handleCancel,
    handleOk,
    onChange,
    onPressEnter,
    streamLists,
    onStreamRedirect,
    onRedirectPageLive,
    streamTotal,
    PAGE_SIZE,
    currentPage,
    onChangePage,
    setIsModalOpen,
  } = useInit({
    addEventListener,
    removeEventListener,
    sendMessage,
  });

  const MenuOption = ({ data }) => {
    return (
      <Menu>
        {data.map(item => (
          <Menu.Item key={item.key}>
            <Button
              type="text"
              className="btn-text"
              onClick={e => {
                setIsLoading(true);
                setValues({
                  ...values,
                  order_by: item.value,
                });
                fetchStreamList({ ...values, order_by: item.value });
              }}
            >
              {item.label}
            </Button>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  useEffect(() => {
    isShowModalStreamList && setIsModalOpen(isShowModalStreamList);
  }, [isShowModalStreamList]);

  return (
    <SteamListModal
      title="スパチャLive配信中アーティスト一覧"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={820}
      footer={null}
    >
      <CustomButton type="primary" onClick={onRedirectPageLive}>
        LIVE
      </CustomButton>
      <div className="search-container">
        <Input
          placeholder="Search"
          onChange={onChange}
          maxLength={20}
          onPressEnter={onPressEnter}
          suffix={<SearchOutlined />}
        />

        <div className="search-content">
          <span style={{ color: '#9CA3AF' }}>フィルター: </span>
          <Dropdown
            overlay={
              <MenuOption
                data={[
                  { key: '0', label: '全て', value: '' },
                  {
                    key: '1',
                    label: '視聴者の多い順',
                    value: SORT.BY.VIEWER,
                  },
                  {
                    key: '2',
                    label: 'フォロワーの多い順',
                    value: SORT.BY.FOLLOWER,
                  },
                ]}
              />
            }
            trigger={['click']}
          >
            <Space
              style={{
                color: '#000',
                justifyContent: 'space-between',
                cursor: 'pointer',
              }}
            >
              {values.order_by === SORT.BY.FOLLOWER
                ? 'フォロワーの多い順'
                : values.order_by === SORT.BY.VIEWER
                ? '視聴者の多い順'
                : '全て'}
              <DownOutlined
                className="f10"
                style={{
                  paddingBottom: '4px',
                }}
              />
            </Space>
          </Dropdown>
        </div>
      </div>

      {streamLists?.length > 0 ? (
        <>
          <div className="user-stream-list__bg">
            <div className="user-stream-list">
              {streamLists?.map((s, i) => (
                <div
                  key={i}
                  className="item-user"
                  onClick={() => onStreamRedirect(s)}
                >
                  <div style={{ position: 'relative' }}>
                    <Avatar
                      shape="square"
                      style={{
                        width: '100%',
                        height: '140px',
                      }}
                      src={s?.owner?.avatar_url}
                    >
                      {getFirstLetter(s?.owner?.username || '')}
                    </Avatar>
                    <div className="live-text">
                      <span className="dot" />
                      <div className="live-icon">
                        <Image src={LiveIcon} alt="live-icon" preview={false} />
                      </div>
                    </div>
                    <div className="view-info">
                      <p>
                        <Image
                          src={EyeView}
                          alt="heart-icon"
                          preview={false}
                          style={{ marginTop: '-5px' }}
                        />
                        {s?.current_viewer || 0}
                      </p>
                    </div>
                  </div>
                  <Typography.Title
                    level={5}
                    className="ellipsis-1"
                    style={{
                      fontSize: '14px',
                      marginBottom: '0px',
                      fontWeight: '700',
                    }}
                  >
                    {s?.owner?.username}
                  </Typography.Title>
                  <Typography.Text
                    style={{
                      fontSize: '14px',
                      marginBottom: '0px',
                      color: '#9CA3AF',
                    }}
                  >
                    {s?.owner?.total_followers || 0}
                    <span style={{ marginLeft: 3 }}>
                      {s?.owner?.total_followers > 1 ? 'Followers' : 'Follower'}
                    </span>
                  </Typography.Text>
                </div>
              ))}
            </div>
          </div>
          {streamTotal > PAGE_SIZE && (
            <div className="pagination">
              <Pagination
                defaultCurrent={1}
                showSizeChanger={false}
                current={currentPage}
                total={streamTotal}
                pageSize={PAGE_SIZE}
                onChange={onChangePage}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className="nodata">
          <Image
            width={335}
            height={247}
            preview={false}
            src={NoData}
            style={{
              objectFit: 'cover',
            }}
            alt=""
          />

          <p
            style={{
              color: '#D1D5DB',
              fontSize: '24px',
              fontWeight: 'bold',
              marginTop: '15px',
            }}
          >
            Don’t have any livestream now!
          </p>
        </div>
      )}
    </SteamListModal>
  );
};
