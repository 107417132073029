import React, { useState, useEffect } from 'react';
import { MetaSelect } from '../Forms';

import DynamicDeviceData from 'app/components/DeviceSelect/DeviceData';
import { renderOptionDevice } from 'utils/helpers';

export default function DeviceSelect({
  deviceType,
  onChange,
  placeHolder,
  name,
}) {
  const [deviceList, setDeviceList] = useState<any>([]);
  const [activeDevice, setActiveDevice] = useState<any>({});
  const [activeDeviceId, setActiveDeviceId] = useState<any>('');

  const updateDeviceList = (list: any = []) => {
    setDeviceList(prevList => {
      if (prevList.length === 0) {
        list[0] && setActiveDevice(list[0]);
        list[0] && list[0].deviceId && setActiveDeviceId(list[0].deviceId);
      }
      return list;
    });
  };

  useEffect(() => {
    if (activeDevice && JSON.stringify(activeDevice) !== '{}') {
      onChange && onChange(activeDevice.deviceId);
    }
  }, [activeDevice]);

  const handleChange = (value: any) => {
    const deviceID = value;
    const activeDevice = deviceList.find(
      (item: any) => item.deviceId === deviceID,
    );
    setActiveDevice(activeDevice);
    setActiveDeviceId(deviceID);
  };

  return (
    <div>
      <DynamicDeviceData
        deviceType={deviceType}
        updateDeviceList={updateDeviceList}
        updateActiveDeviceId={null}
      />

      {/* {activeDevice?.deviceId && (
        <MetaSelect
          name={name}
          placeholder={placeHolder}
          options={renderOptionDevice(deviceList)}
          value={activeDevice}
          defaultValue={activeDevice}
          onChange={handleChange}
        />
      )} */}
    </div>
  );
}
