import { useState } from 'react';
import { toast } from 'react-toastify';

import { historyPoint } from 'app/apis/point';

export const usePointHistory = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pointHistory, setPointHistory] = useState<any>([]);

  const fetchPointHistory = async () => {
    setIsLoading(true);
    try {
      const rePointHistory: any = await historyPoint();

      if (rePointHistory) {
        setPointHistory(rePointHistory?.data?.rows);
      }

      setIsLoading(false);
    } catch (err: any) {
      setPointHistory([]);
      setIsLoading(false);
      toast.error('Get history fail');
    }
  };

  return {
    isLoading,
    pointHistory,
    fetchPointHistory,
  };
};
