import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Avatar, Badge, Typography } from 'antd';

import { usePrevious } from 'app/hooks/usePrevious';
import { PusherRobot } from 'styles/image';

import { _log } from 'utils/_log';

const ChatTab = ({ messageLists, isMySelf, giftList, roomID }) => {
  const [lastMessage, setLastMessage] = useState(
    messageLists?.[messageLists?.length - 1],
  );

  const prevMessage = usePrevious<string>(lastMessage);

  useEffect(() => {
    setLastMessage(messageLists?.[messageLists?.length - 1]);
  }, [JSON.parse(JSON.stringify(messageLists))]);

  useEffect(() => {
    const element: any = document.getElementById('chat');

    if (prevMessage !== lastMessage) {
      element.scrollTo(0, element.scrollHeight);
    }
  }, [prevMessage, lastMessage]);

  return (
    <div
      className="chat-container"
      style={{
        gridTemplateRows: isMySelf && '1fr',
      }}
    >
      <div id="chat" className="top">
        {roomID && (
          <>
            {messageLists &&
              messageLists.length > 0 &&
              messageLists
                ?.sort((a, b) => a.time - b.time)
                ?.map((list, i) => {
                  if (
                    list?.type === 'notice' &&
                    list?.content &&
                    list?.content?.indexOf('has send a') > -1
                  ) {
                    const index = list?.content?.indexOf(' has send a ');
                    const index2 = list?.content?.lastIndexOf(' to ');
                    const donateSender = list?.content?.slice(0, index);
                    const giftInfo = giftList?.find(
                      g => g?.name === list?.content?.slice(index + 12, index2),
                    );

                    return (
                      <div className="box-content" key={list?.type + i}>
                        <Badge dot={false}>
                          <Avatar shape="square" src={PusherRobot} />
                        </Badge>

                        <div className="box-content-description box-content-notification">
                          <Typography.Title
                            level={4}
                            className="notification-title"
                          >
                            お知らせ
                          </Typography.Title>

                          <Typography.Paragraph className="message-detail message-notification-detail">
                            <p
                              style={{
                                marginBottom: '0px',
                              }}
                            >
                              {`${donateSender} さんが  ${list?.content?.slice(
                                index + 12,
                                index2,
                              )} (${giftInfo?.price || 0}p) を贈りました`}{' '}
                              <img
                                src={giftInfo?.image_url}
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  objectFit: 'contain',
                                }}
                              />
                            </p>
                          </Typography.Paragraph>

                          <Typography.Text className="time-text">
                            {moment.unix(list?.time).format('HH:mm')}
                          </Typography.Text>
                        </div>
                      </div>
                    );
                  }
                })}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatTab;

// import { Avatar } from 'antd';
// import React from 'react';
// import { useHistoryDonation } from './hooks';

// const ViewTab = ({ roomID }: { roomID: string }) => {
//   const { donateHistoryData } = useHistoryDonation({ roomID });

//   return (
//     <div className="chat-container view-container">
//       <div className="view-box">
//         {donateHistoryData?.map((item, i) => (
//           <div key={i} className="view-box-col">
//             <div className="user-item">
//               <div className="d-box">
//                 <p className="user-index">{i < 9 ? '0' + (i + 1) : i + 1}</p>

//                 <Avatar
//                   src={
//                     item.sender.avatar_url !== null && item.sender.avatar_url
//                   }
//                   shape="square"
//                   style={{ minWidth: '48px', minHeight: '48px' }}
//                 >
//                   {item.sender.avatar_url === null &&
//                     item.sender.username.charAt(0).toLocaleUpperCase()}
//                 </Avatar>

//                 <p className="user-name">{item.sender.username}</p>
//               </div>
//               <div
//                 style={{
//                   display: 'flex',
//                   alignItems: 'center',
//                   gap: '10px',
//                 }}
//               >
//                 <p className="user-point">{item.total}</p>
//                 <p className="user-point-text">
//                   {item.total > 1 ? 'Points' : 'Point'}
//                 </p>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ViewTab;
