import {
  avatarsListRequest,
  changeAvatarRequest,
  followRequest,
  followerListRequest,
  followingListRequest,
  getLabelRequest,
  personRequest,
  unFollowRequest,
  updateUserRequest,
} from 'app/apis/profile';
import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions as actions } from '.';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';

function* getPerson(action: any) {
  try {
    const res = yield call(personRequest, action.payload);
    yield put(actions.getPersonSuccess(res));
  } catch (error: any) {
    yield put(actions.getPersonFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* followed(action: any) {
  try {
    const res = yield call(followRequest, action.payload);
    yield put(actions.followedSuccess(res));
  } catch (error: any) {
    yield put(actions.followedFailed(error.response.data.message));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* unFollowed(action: any) {
  try {
    const res = yield call(unFollowRequest, action.payload);
    yield put(actions.unFollowedSuccess(res));
  } catch (error: any) {
    yield put(actions.unFollowedFailed(error.response.data.message));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* getFollowerList(action: any) {
  try {
    const res = yield call(followerListRequest, action.payload);
    yield put(actions.getFollowerListSuccess(res));
  } catch (error: any) {
    yield put(actions.getFollowerListFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* getFollowingList(action: any) {
  try {
    const res = yield call(followingListRequest, action.payload);
    yield put(actions.getFollowingListSuccess(res));
  } catch (error: any) {
    yield put(actions.getFollowingListFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* getLabel(action: any) {
  try {
    const res = yield call(getLabelRequest);
    yield put(actions.getLabelSuccess(res));
  } catch (error: any) {
    yield put(actions.getLabelFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* updateUser(action: any) {
  try {
    const res = yield call(updateUserRequest, action.payload);
    yield put(actions.updateUserSuccess(res));
    toast.success('Update successfully');
  } catch (error: any) {
    yield put(actions.updateUserFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* getAvatarsList() {
  try {
    const res = yield call(avatarsListRequest);
    yield put(actions.getAvatarsListSuccess(res));
  } catch (error: any) {
    yield put(actions.getAvatarsListFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

function* changeAvatar({
  payload: { data, callback },
}: PayloadAction<{ data: any; callback: () => void }>) {
  try {
    const res = yield call(changeAvatarRequest, data);
    yield put(actions.changeAvatarSuccess(res));

    toast.success('Change avatar successfully');
    callback?.();
  } catch (error: any) {
    yield put(actions.changeAvatarFailed(error));
    yield put(
      actionsToast.openToast({
        message: error.response.data.message,
        type: 'error',
      }),
    );
  }
}

export function* authSaga() {
  yield takeLatest(actions.getPerson.type, getPerson);
  yield takeLatest(actions.followed.type, followed);
  yield takeLatest(actions.unFollowed.type, unFollowed);
  yield takeLatest(actions.getFollowerList.type, getFollowerList);
  yield takeLatest(actions.getFollowingList.type, getFollowingList);
  yield takeLatest(actions.updateUser.type, updateUser);
  yield takeLatest(actions.getLabel.type, getLabel);
  yield takeLatest(actions.getAvatarsList.type, getAvatarsList);
  yield takeLatest(actions.changeAvatar.type, changeAvatar);
}
