import Stream from 'app/components/Stream';
import React from 'react';
import { breakpoints } from 'utils/media-breakpoint';
import ChatInput from '../ChatGroup/ChatInput';
import InformationDetail from '../InformationDetail';
import { useLiveScreen } from './hooks';
import { InformationBox } from './styles';
import { useChatGroup } from '../ChatGroup/hooks';
import { ViewerModal } from './components/ViewerModal';

const LiveScreen = ({
  localStreamConfig,
  RTC,
  remoteStreamConfigList,
  handleLocalChange,
  handleRemoteChange,
  isTheater,
  userStream,
  currentUser,
  handleJoin,
  handleLeave,
  isMySelf,
  full,
  setFull,
  totalOfLike,
  totalOfViewer,
  handleLike,
  liked,
  setIdVideo,
  roomID,
  isDisabled,
  messageLists,
  dispatch,
  sendButton,
  inputMessage,
  setInputMessage,
  isClientBannedError,
  isMultipleRoomsError,
  isMobileLive,
  chatRTC,
  setDefaultMedia,
  setMessageLists,
}: any) => {
  const {
    isMobile,
    openModalDonation,
    time,
    formatTime,
    setOpenModalDonation,
    audioRef,
    effectSoundUrl,
  } = useLiveScreen({
    localStreamConfig,
    roomID,
  });

  const { isOpenViewerModal, setIsOpenViewerModal } = useChatGroup();

  return (
    <InformationBox
      isTheater={isTheater}
      isMySelf={isMySelf}
      isFull={full}
      isMobileLive={isMobileLive}
      breakpoints={breakpoints}
    >
      <div className="live-contain">
        <div className="live-contain-screen" id="live-screen">
          {remoteStreamConfigList?.length > 0 &&
            remoteStreamConfigList?.map(remoteStreamConfig => {
              if (remoteStreamConfig.hasAudio || remoteStreamConfig.hasVideo) {
                return (
                  <Stream
                    key={`${remoteStreamConfig.stream.getUserId()}_${remoteStreamConfig.stream.getType()}`}
                    stream={remoteStreamConfig.stream}
                    config={remoteStreamConfig}
                    isMySelf={isMySelf}
                    init={dom => RTC.playStream(remoteStreamConfig.stream, dom)}
                    onChange={e => handleRemoteChange(e)}
                    full={full}
                    setFull={setFull}
                    setIdVideo={setIdVideo}
                    isTheater={isTheater}
                    userStream={userStream}
                    setOpenModalDonation={setOpenModalDonation}
                    openModalDonation={openModalDonation}
                    roomID={roomID}
                    currentUser={currentUser}
                    localStreamConfig={localStreamConfig}
                    remoteStreamConfigList={remoteStreamConfigList}
                    handleLike={handleLike}
                    liked={liked}
                    formatTime={formatTime}
                    time={time}
                    totalOfLike={totalOfLike}
                    totalOfViewer={totalOfViewer}
                    messageLists={messageLists}
                    isClientBannedError={isClientBannedError}
                    isMultipleRoomsError={isMultipleRoomsError}
                    isMobileLive={isMobileLive}
                  />
                );
              }
            })}

          {localStreamConfig &&
            (localStreamConfig.hasAudio || localStreamConfig.hasVideo) && (
              <Stream
                stream={localStreamConfig.stream}
                config={localStreamConfig}
                init={dom => RTC.playStream(localStreamConfig.stream, dom)}
                onChange={e => handleLocalChange(e)}
                isMySelf={isMySelf}
                setIdVideo={setIdVideo}
                isTheater={isTheater}
                userStream={userStream}
                setOpenModalDonation={setOpenModalDonation}
                openModalDonation={openModalDonation}
                roomID={roomID}
                currentUser={currentUser}
                localStreamConfig={localStreamConfig}
                remoteStreamConfigList={remoteStreamConfigList}
                handleLike={handleLike}
                liked={liked}
                formatTime={formatTime}
                time={time}
                totalOfLike={totalOfLike}
                totalOfViewer={totalOfViewer}
                messageLists={messageLists}
                isClientBannedError={isClientBannedError}
                isMultipleRoomsError={isMultipleRoomsError}
                isMobileLive={isMobileLive}
              />
            )}
        </div>
      </div>

      {isMobile && (
        <div className="bottom">
          <ChatInput
            dispatch={dispatch}
            sendButton={sendButton}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            isDisabled={isDisabled}
            scrollBottom={() => {
              setTimeout(() => {
                const element: any = document.getElementById('chat');
                element.scrollTo(0, element.scrollHeight + 1000);
              }, 700);
            }}
          />
        </div>
      )}

      {isMobile
        ? isMySelf && (
            <InformationDetail
              localStreamConfig={localStreamConfig}
              isTheater={isTheater}
              userStream={userStream}
              onChange={handleLocalChange}
              handleJoin={handleJoin}
              handleLeave={handleLeave}
              isMySelf={isMySelf}
              setIsOpenViewerModal={setIsOpenViewerModal}
              setDefaultMedia={setDefaultMedia}
              setMessageLists={setMessageLists}
              roomID={roomID}
            />
          )
        : !isTheater && (
            <InformationDetail
              localStreamConfig={localStreamConfig}
              isTheater={isTheater}
              userStream={userStream}
              onChange={handleLocalChange}
              handleJoin={handleJoin}
              handleLeave={handleLeave}
              isMySelf={isMySelf}
              setIsOpenViewerModal={setIsOpenViewerModal}
              setDefaultMedia={setDefaultMedia}
              setMessageLists={setMessageLists}
              roomID={roomID}
            />
          )}

      {isOpenViewerModal && (
        <ViewerModal
          data={chatRTC?.memberList || []}
          currentUser={currentUser}
          isOpen={isOpenViewerModal}
          setIsOpen={setIsOpenViewerModal}
        />
      )}

      {effectSoundUrl && (
        <audio
          controls
          ref={audioRef}
          preload="none"
          style={{
            display: 'none',
          }}
        >
          <source src={effectSoundUrl} type="audio/mpeg" />
        </audio>
      )}
    </InformationBox>
  );
};

export default LiveScreen;
