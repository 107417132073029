import { Form } from 'antd';
import { MetaButton, MetaInput } from 'app/components/Forms';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import GoogleLogin from 'react-google-login';

import {
  IS_ANDROID,
  IS_CHROME,
  IS_IPAD,
  IS_IPHONE,
  IS_SAFARI,
} from 'utils/constants';
import {
  regexEmail,
  regexIcon,
  regexPassword,
  regexSpecialCharacter,
  regexUsername,
} from 'utils/helpers';
import { GoogleIcon } from 'styles/image';

const RegisterForm = ({ onLoginOAuth, form, onFinish, isLoading }) => {
  const [readOnly, setReadOnly] = useState(true);

  function getDevice() {
    if (IS_IPAD) {
      return true;
    }
    if (IS_IPHONE) {
      return true;
    }
    if (IS_ANDROID) {
      return true;
    }
    if (IS_IPAD && IS_CHROME) {
      return true;
    }
    if (IS_IPAD && IS_SAFARI) {
      return true;
    }
    return false;
  }

  return (
    <FormContainer
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="form-login"
      autoComplete="off"
    >
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}
        buttonText="Login"
        onSuccess={tokenResponse => {
          onLoginOAuth(tokenResponse);
        }}
        cookiePolicy={'single_host_origin'}
        render={renderProps => {
          return (
            <MetaButton
              type="primary"
              htmlType="submit"
              className="btn-primary-white"
              style={{ marginTop: '0px', gap: '15px', borderRadius: '35px' }}
              text="Googleでログイン"
              onClick={renderProps.onClick}
              icon={
                <img
                  style={{ width: '20px', height: '20px' }}
                  src={GoogleIcon}
                  alt="..."
                />
              }
            />
          );
        }}
      />

      <div
        style={{
          fontSize: '14px',
          lineHeight: 1.5,
          margin: 0,
        }}
      >
        <p
          className="mb-0"
          style={{
            margin: 0,
            fontSize: '14px',
          }}
        >
          ※Googleアカウントをお持ちの場合、
        </p>
        <p
          className="mb-0"
          style={{
            margin: 0,
            fontSize: '14px',
          }}
        >
          会員登録なしでログインしていただけます。
        </p>
      </div>

      <MetaInput
        form={form}
        name="username"
        label="ユーザー名"
        subLabel="５文字以上で作成してください"
        isNormalize
        isRequired
        rules={[
          { required: true, message: 'ユーザー名を入力してください' },
          {
            pattern: regexUsername,
            message: '5～20文字以内で入力してください',
          },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
          {
            pattern: regexSpecialCharacter,
            message: '無効な文字です',
          },
        ]}
        readOnly={!getDevice() ? readOnly : false}
        autoComplete="disabled"
        onFocus={() => setReadOnly(false)}
        onBlur={() => setReadOnly(true)}
      />

      <MetaInput
        form={form}
        label="パスワード"
        subLabel="8～32文字以内、大文字、小文字、数字、記号を含めてください"
        type="password"
        name="password"
        autoComplete="off"
        placeholder="パスワード"
        className="input-password"
        isRequired
        rules={[
          { required: true, message: 'パスワードを入力してください' },
          {
            pattern: regexPassword,
            message:
              '8～32文字以内、大文字、小文字、数字、記号を含めてください',
          },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
        ]}
      />

      <MetaInput
        form={form}
        label="パスワードを再入力"
        type="password"
        name="confirmPassword"
        placeholder="パスワードを再入力"
        className="input-password"
        autoComplete="off"
        isRequired
        rules={[
          { required: true, message: 'パスワードを再入力してください' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('パスワードが一致しません'));
            },
          }),
        ]}
      />

      <MetaInput
        name="email"
        label="メールアドレス"
        placeholder="メールアドレスを入力してください"
        autoComplete="off"
        isNormalize
        isRequired
        rules={[
          { required: true, message: 'メールアドレスを入力してください' },
          {
            pattern: regexEmail,
            message: '無効なメールアドレスです',
          },
          {
            pattern: regexIcon,
            message: '無効な文字です',
          },
        ]}
        form={form}
      />

      <p
        className="mb-0"
        style={{
          color: '#EB7D17',
          fontWeight: '600',
          fontSize: '14px',
        }}
      >
        * 空白が無いようにして下さい
      </p>

      <Form.Item>
        <MetaButton
          type="primary"
          htmlType="submit"
          text="登録する"
          is_loading={isLoading}
          style={{ marginBottom: '10px' }}
        />
      </Form.Item>
    </FormContainer>
  );
};

export default RegisterForm;

const FormContainer = styled(Form)<any>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    margin-bottom: 0;
  }

  .ant-btn-primary {
    margin-top: 10px;
  }
`;
