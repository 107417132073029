import React, { useState, useEffect, useRef } from 'react';
import { setFullscreen, exitFullscreen } from 'utils/utils';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import StreamBar from './StreamBar';
import { breakpoints } from 'utils/media-breakpoint';
import { _log } from 'utils/_log';

const fullscreenchangeList = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'MSFullscreenChange',
];
const fullscreenerrorList = [
  'fullscreenerror',
  'webkitfullscreenerror',
  'mozfullscreenerror',
  'MSFullscreenError',
];

const Stream = props => {
  const [statusInit, setStatusInit] = useState(false);
  const { full, setFull, setIdVideo } = props;
  const [config, setConfig] = useState(() => ({ ...props.config }));
  const refItem = useRef<any>();

  useEffect(() => {
    fullscreenchangeList.forEach(item => {
      document.addEventListener(item, () => {
        if (document.fullscreenElement) {
          setFull(true);
        } else {
          setFull(false);
        }
      });
    });
    fullscreenerrorList.forEach(item => {
      document.addEventListener(item, () => {
        toast.error('フルスクリーンの設定がエラーです');
      });
    });
    return () => {
      [...fullscreenchangeList, ...fullscreenerrorList].forEach(item => {
        document.removeEventListener(item, () => {});
      });
    };
  }, []);

  const handleState = (handle, value) => {
    handle(prevValue => ({ ...prevValue, ...value }));
  };

  const handleChange = e => {
    if (e.name === 'full') {
      if (!full) {
        setFullscreen(refItem.current);
      } else {
        exitFullscreen();
      }
      return;
    }
    if (e.name === 'picture') {
      try {
        if ('pictureInPictureEnabled' in document) {
          const isInPicture = document.pictureInPictureElement;
          if (!isInPicture) {
            refItem.current.childNodes[1].childNodes[1].requestPictureInPicture();
          } else {
            document.exitPictureInPicture();
          }
        } else {
          toast.error(
            'ブラウザはピクチャーインピクチャーをサポートしていません',
          );
        }
      } catch (error) {
        _log('Stream error: ', error, 'error');
      }
      return;
    }
    props.onChange && props.onChange(e);
  };

  const handleResume = () => {
    props.onChange &&
      props.onChange({
        name: 'resumeFlag',
        stream: config.stream,
      });
  };

  useEffect(() => {
    if (props.init && !statusInit) {
      props.init(refItem.current);
      const current = {
        userID: props.stream.getUserId(),
        type: props.stream.getType(),
      };
      handleState(setConfig, current);
      setStatusInit(true);
    }
    handleState(setConfig, { ...props.config });
  }, [props.type, props.init, props.stream, props, statusInit]);

  useEffect(() => {
    // const videoTag = refItem.current.childNodes[0]?.outerHTML;
    const divId = refItem.current.childNodes[1]?.id;
    const audioId = refItem.current.childNodes[1]?.childNodes[0]?.id;
    const videoId = refItem.current.childNodes[1]?.childNodes[1]?.id;

    setIdVideo &&
      setIdVideo({
        divId,
        audioId,
        videoId,
      });
  }, []);

  return (
    <VideoContain
      className={`${props.className}`}
      isMySelf={props.isMySelf}
      isTheater={props.isTheater}
      isFull={full}
      isMobileLive={props.isMobileLive}
      breakpoints={breakpoints}
    >
      <div
        id="viewer"
        ref={refItem}
        style={{
          width: '100%',
          height: `${props.isTheater ? 'unset' : '100%'}`,
          aspectRatio: `${props.isTheater ? '16/9' : 'unset'}`,
          position: 'relative',
        }}
      >
        <StreamBar
          config={config}
          onChange={handleChange}
          setting={props.setting}
          isMySelf={props.isMySelf}
          full={full}
          setFull={setFull}
          isTheater={props.isTheater}
          localStreamConfig={props.localStreamConfig}
          remoteStreamConfigList={props.remoteStreamConfigList}
          userStream={props.userStream}
          handleLike={props.handleLike}
          liked={props.liked}
          setOpenModalDonation={props.setOpenModalDonation}
          openModalDonation={props.openModalDonation}
          roomID={props.roomID}
          currentUser={props.currentUser}
          formatTime={props.formatTime}
          time={props.time}
          totalOfLike={props.totalOfLike}
          totalOfViewer={props.totalOfViewer}
          messageLists={props.messageLists}
          isClientBannedError={props.isClientBannedError}
          isMultipleRoomsError={props.isMultipleRoomsError}
        />
      </div>
      {config.resumeFlag && <div onClick={handleResume}>play icon</div>}
    </VideoContain>
  );
};

export default React.memo(Stream);

const VideoContain = styled.div<any>`
  width: 100%;
  height: 100%;

  > div > div:first-child {
    width: 100%;
    height: 100%;
    /* background-color: transparent !important; */
    background-color: #000000 !important;

    border-radius: ${props => (props.isFull ? '0' : '20px')};

    @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
      border-radius: ${props =>
        props.isTheater || props?.isFull
          ? '0'
          : props?.isMySelf
          ? '0'
          : '20px'};
    }
  }

  > div > div:nth-child(2) {
    /* width: 100%; */
    overflow: hidden;
    width: ${props => (props.isMobileLive ? 'unset' : '100%')} !important ;
    height: ${props =>
      props.isMySelf
        ? '100%'
        : props.isMobileLive
        ? '100%'
        : 'unset'} !important;
    aspect-ratio: ${props =>
      props.isMySelf
        ? 'unset'
        : props.isMobileLive
        ? '12/16'
        : '16/9'} !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent !important;

    @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
      width: ${props => (props.isMobileLive ? 'unset' : '100%')} !important ;
      height: ${props =>
        props.isMySelf || props.isFull
          ? '100%'
          : props.isTheater
          ? props.isMobileLive
            ? '100%'
            : 'unset'
          : '100%'} !important;
      aspect-ratio: ${props =>
        props.isMySelf
          ? 'unset'
          : props.isMobileLive
          ? '12/16'
          : '16/9'} !important;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${props => (props.isMobileLive ? '25px' : 0)};

    @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
      border-radius: ${props =>
        props.isTheater || props?.isFull || props.isMobileLive
          ? '0'
          : props?.isMySelf
          ? '0'
          : '20px'};
    }
  }
`;
