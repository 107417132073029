import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import history from 'utils/history';
import { refeshTokenRequest } from './auth';
import { PATH } from 'app/constants/path';
import { deleteAllCookies } from 'utils/common';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SOCKET_API_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config: any) {
    if (Cookies.get(`access_token_${process.env.REACT_APP_ENVIRONMENT}`)) {
      config.headers = {
        Authorization: `Bearer ${Cookies.get(`access_token_${process.env.REACT_APP_ENVIRONMENT}`)}`,
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    switch (error.response.status) {
      case 404:
        // history.push(PATH.ERROR_404);
        break;
      case 500:
        // history.push(PATH.ERROR_500);
        break;
      case 503:
        // history.push(PATH.ERROR_503);
        break;
      case 401:
        deleteAllCookies();
        Cookies.remove(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, {
          path: '',
          domain: process.env.REACT_APP_DOMAIN,
        });
        history.push(PATH.LOGIN);
        // history.go(0);
        break;
      case 403:
        deleteAllCookies();
        Cookies.remove(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, {
          path: '',
          domain: process.env.REACT_APP_DOMAIN,
        });
        history.push(PATH.LOGIN);
        // history.go(0);
        break;
    }
    return Promise.reject(error);
  },
);

//refresh token
const refreshToken = async (error: any) => {
  const originalRequest = error.config;
  originalRequest._retry = true;
  const refresh = Cookies.get('refresh_token');
  if (!refresh) {
    Cookies.remove(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, {
      path: '',
      domain: process.env.REACT_APP_DOMAIN,
    });
    history.push(PATH.LOGIN);
  } else {
    try {
      const data: any = await refeshTokenRequest({ refresh });
      Cookies.set(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, data.access, {
        sameSite: 'lax',
        domain: process.env.REACT_APP_DOMAIN,
      });
      Cookies.set('refresh_token', data.refresh);
      originalRequest.headers = {
        Authorization: 'Bearer ' + data.accessToken,
      };
      return axios(originalRequest);
    } catch (err) {
      console.error(err);
      Cookies.remove(`access_token_${process.env.REACT_APP_ENVIRONMENT}`, {
        path: '',
        domain: process.env.REACT_APP_DOMAIN,
      });
      Cookies.remove('refresh_token');
      history.push(PATH.LOGIN);
      return;
    }
  }
};

export default instance;
