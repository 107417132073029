import { message } from 'antd';
import { useMqtt } from 'app/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { _log } from 'utils/_log';
import { ImagePosition } from '../components/BigDonateNotification';

type Props = {
  localStreamConfig: any;
  roomID: string;
};

export const useLiveScreen = ({ localStreamConfig, roomID }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: '640px' });

  const audioRef = useRef<HTMLAudioElement>(null);

  const donateEffectTime = 3000;

  const dataTransition = 500;

  useMqtt({
    topicName: `room/donate/${roomID}`,
    callback: onReceiveDonate,
  });

  const [donateMessageApi, contextDonateHolder] = message.useMessage({
    getContainer() {
      return document.getElementById('viewer') as HTMLElement;
    },
    maxCount: 3,
  });

  const imageDonateNotificationRef = useRef<HTMLImageElement>(null);

  const confettiEffectRef = useRef<HTMLCanvasElement>(null);

  const [openModal, setOpenModal] = useState(false);

  const [openModalDonation, setOpenModalDonation] = useState(false);

  const [time, setTime] = useState(0);

  const [isRunning, setIsRunning] = useState(false);

  const [imagePosition, setImagePosition] = useState<ImagePosition | null>(
    null,
  );

  const [mqttReceiveCount, setMqttReceiveCount] = useState<number>(0);

  const [hasAdvantedDonateEffect, setHasAdvantedDonateEffect] =
    useState<boolean>(false);

  const [effectSoundUrl, setEffectSoundUrl] = useState<string | undefined>(
    undefined,
  );

  /**
   * @method onReceiveDonate
   * @description receive donate from mqtt
   * @param message : Buffer
   */
  function onReceiveDonate(message: Buffer) {
    try {
      const msg = JSON.parse(message.toString());

      const { data } = msg;

      const dataParse = JSON.parse(data);

      setEffectSoundUrl(dataParse.gift.sound_url);

      onDonateReceived({
        userName: dataParse.sender.username,
        gitftName: dataParse.gift.name,
        imageUrl: dataParse.gift.image_url,
        imagePosition: dataParse.gift.effect_position as ImagePosition,
        hasEffect: dataParse.gift.has_effect,
      });
    } catch (error) {
      _log('get donate error: ', error, 'error');
      toast.error('Get donate fail');
    }
  }

  /**
   * @method onDonateReceived
   * @description show donate message when receive donate
   * @param userName : string
   * @param gitftName : string
   * @param imageUrl : string
   * @param imagePosition : ImagePosition
   * @param hasEffect : string
   */
  function onDonateReceived({
    userName,
    gitftName,
    imageUrl,
    imagePosition = 'CENTER',
    hasEffect,
  }: {
    userName: string;
    gitftName: string;
    imageUrl: string;
    imagePosition: ImagePosition;
    hasEffect: boolean;
  }) {
    // Open message
    donateMessageApi.success({
      content: (
        <div className="donate-message-box">
          <img src={imageUrl} alt="gif" className="donate-message-gif-image" />
          <div className="donate-message">
            <h6 className="donate-message-heading">{userName}</h6>
            <p className="donate-message-content">{gitftName}</p>
          </div>
        </div>
      ),
    });

    setMqttReceiveCount(prev => prev + 1);

    if (hasEffect) {
      setHasAdvantedDonateEffect(true);
    } else {
      setHasAdvantedDonateEffect(false);
    }

    if (imageDonateNotificationRef && imageDonateNotificationRef.current) {
      imageDonateNotificationRef.current.src = imageUrl;

      setImagePosition(imagePosition);

      onShowDonateEffect({ shouldShowEffect: true });
    }
  }

  /**
   * @method formatTime
   * @description format time
   * @param time : number
   * @returns
   */
  function formatTime(time: number) {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, '0');

    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, '0');

    const seconds = (time % 60).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }

  /**
   * @method onShowDonateEffect
   * @description show donate effect when user donate
   * @param shouldShowEffect : boolean
   * @returns
   */
  function onShowDonateEffect({
    shouldShowEffect,
  }: {
    shouldShowEffect: boolean;
  }) {
    // if not have ref, return
    if (!imageDonateNotificationRef.current) return;

    if (shouldShowEffect) {
      if (imageDonateNotificationRef.current.classList.contains('hide')) {
        imageDonateNotificationRef.current.classList.remove('hide');
      }

      imageDonateNotificationRef.current.classList.add('show');
    } else {
      imageDonateNotificationRef.current.classList.remove('show');

      imageDonateNotificationRef.current.classList.add('hide');
    }

    setTimeout(() => {
      if (!imageDonateNotificationRef.current) {
        return;
      }

      imageDonateNotificationRef.current.classList.remove('hide');
    }, dataTransition);
  }

  /**
   * @method onShowAdvantedDonateEffect
   * @description show advanted donate effect when user donate by effect setting
   * @param shouldShowEffect : boolean
   * @returns
   */
  function onShowAdvantedDonateEffect({
    shouldShowEffect,
  }: {
    shouldShowEffect: boolean;
  }) {
    // if not have ref, return
    if (!confettiEffectRef.current) return;

    if (shouldShowEffect) {
      confettiEffectRef.current.classList.add('show');
    } else {
      confettiEffectRef.current.classList.remove('show');

      confettiEffectRef.current.classList.add('hide');
    }

    setTimeout(() => {
      if (!confettiEffectRef.current) {
        return;
      }

      confettiEffectRef.current.classList.remove('hide');
    }, dataTransition);
  }

  /**
   * @method onShowAdvantedDonateAudio
   * @description show audio advanted donate effect when user donate by effect setting
   * @param shouldShowEffect : boolean
   * @returns
   */
  function onShowAdvantedDonateAudio({
    shouldShowEffect,
  }: {
    shouldShowEffect: boolean;
  }) {
    // if not have ref, return
    if (!audioRef.current) return;

    let playPromise: Promise<any> | undefined;

    if (shouldShowEffect) {
      if (audioRef.current.paused) {
        playPromise = audioRef.current.play();
      }
    } else {
      if (playPromise !== undefined) {
        playPromise.then(() => {
          if (!audioRef.current) return;

          audioRef.current.pause();

          audioRef.current.currentTime = 0;
        });
      }

      setTimeout(() => {
        setEffectSoundUrl(undefined);
      }, 500);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMqttReceiveCount(0);
    }, donateEffectTime);

    return () => {
      clearTimeout(timeout);
    };
  }, [mqttReceiveCount]);

  useEffect(() => {
    if (hasAdvantedDonateEffect && mqttReceiveCount > 0) {
      onShowAdvantedDonateAudio({ shouldShowEffect: true });

      onShowAdvantedDonateEffect({ shouldShowEffect: true });
    } else if (!hasAdvantedDonateEffect || mqttReceiveCount === 0) {
      onShowAdvantedDonateEffect({ shouldShowEffect: false });

      onShowAdvantedDonateAudio({ shouldShowEffect: false });
    }
  }, [hasAdvantedDonateEffect, mqttReceiveCount]);

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;

    if (mqttReceiveCount === 0) {
      onShowDonateEffect({ shouldShowEffect: false });

      timeout = setTimeout(() => {
        if (!imageDonateNotificationRef.current) {
          return;
        }

        imageDonateNotificationRef.current.src = '';
      }, dataTransition);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [mqttReceiveCount]);

  useEffect(() => {
    let intervalId: string | number | NodeJS.Timeout | undefined;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTime(prevTime => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  useEffect(() => {
    if (localStreamConfig) {
      setIsRunning(true);
    } else {
      setIsRunning(false);

      setTime(0);
    }
  }, [localStreamConfig]);

  return {
    isMobile,
    donateMessage: contextDonateHolder,
    imageDonateNotificationRef,
    confettiEffectRef,
    imagePosition,
    openModal,
    openModalDonation,
    time,
    formatTime,
    setOpenModal,
    setOpenModalDonation,
    audioRef,
    effectSoundUrl,
  };
};
