import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { streamSaga } from './saga';
import { StreamState } from './types';

export const initialState: StreamState = {
  isLoading: false,
  localStreamConfig: null,
  remoteStreamConfigList: null,
  RTC: null,
  isJoined: false,
  isPublished: false,
  streamConfig: null,
  roomLists: null,
  myRoom: null,
  generateRoom: '',
  likeLists: [],
};

const slice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    setLocalStreamConfig(state, action: PayloadAction<any>) {
      state.localStreamConfig = action.payload;
    },
    setRemoteStreamConfigList(state, action: PayloadAction<any>) {
      state.remoteStreamConfigList = action.payload;
    },
    setRTC(state, action: PayloadAction<any>) {
      state.RTC = action.payload;
    },
    setIsJoined(state, action: PayloadAction<any>) {
      state.isJoined = action.payload;
    },
    setIsPublished(state, action: PayloadAction<any>) {
      state.isPublished = action.payload;
    },
    setStreamConfig(state, action: PayloadAction<any>) {
      state.streamConfig = action.payload;
    },

    // get room
    getRoomList(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getRoomListSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.roomLists = action.payload.data;
    },
    getRoomListFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // get my room
    getMyRoomList(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getMyRoomListSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.myRoomLists = action.payload.data;
    },
    getMyRoomListFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // generate roomId
    // generateRoom(state, action: PayloadAction<any>) {
    //   state.isLoading = true;
    // },
    // generateRoomSuccess(state, action: PayloadAction<any>) {
    //   state.isLoading = false;
    //   state.generateRoom = action.payload.data;
    // },
    // generateRoomFailed(state, action: PayloadAction<any>) {
    //   state.isLoading = false;
    // },

    // start room
    startRoom(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    startRoomSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    startRoomFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // pause room
    pauseRoom(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    pauseRoomSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    pauseRoomFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },

    // stop room
    stopRoom(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    stopRoomSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    stopRoomFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
  },
});

export const { actions: authActions } = slice;

export const useStreamSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: streamSaga });
  return { actions: slice.actions };
};
