import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;

  position: fixed;
  height: 100dvh;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
`;

export const Button = styled.button`
  color: #ffffff;
  background-color: #eb7d17;
  outline: none;
  border: none;
  padding: 14px 18px;
  border-radius: 30px;
`;
