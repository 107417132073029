import styled from 'styled-components';
import { breakpoints } from 'utils/media-breakpoint';

// css
export const Wrapper = styled.div<any>`
  padding: ${props => (props?.isMySelf ? '40px 20px' : '40px 113px')};
  overflow-x: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  /* display: grid; */
  /* grid-template-columns: ${props =>
    props?.isTheater ? `1fr` : props.isMySelf ? '1254px 1fr' : `980px 1fr`};
  justify-content: space-between;
  gap: 30px; */

  @media only screen and (max-width: 1729px) {
    padding: 20px;
    /* grid-template-columns: 1fr;
    justify-content: center; */
  }
  @media only screen and (max-width: 1024px) {
    padding: 10px;
  }
  @media only screen and (max-width: 640.9998px) {
    padding: 5px;
    /* padding-bottom: 23px; */
  }

  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
`;

export const LivestreamContainer = styled.div<{
  breakpoints: typeof breakpoints;
}>`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;

  @media only screen and (min-width: ${props => props.breakpoints.tablet}) {
    grid-template-columns: calc(100% - 360px - 10px) 360px;
    gap: 10px;
  }

  @media only screen and (min-width: ${props =>
      props.breakpoints.largeScreen}) {
    grid-template-columns: calc(100% - 506px - 10px) 506px;
  }
`;
